import React, { Component } from 'react';
import VBDropdown from "@virkesborsen/vb-js-commons/lib/components/new/vbDropdown";
import Card from 'react-bootstrap/Card';
import * as Opt from '../editor/Helpers';
import Cookies from 'js-cookie';
import ColorSelector from '../editor/fields/ColorSelector';


  
class EarningsComparison extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedMuni: {"label": 'hela Sverige', "value": null},
            selectedArrangement: null,
            virkeskopare: 169,
            percentage: 29,
            selectedPerson: null,
        };
    }

    handleMuniChange = (selectedOption) => {
        this.setState({ selectedMuni: selectedOption}, () => {
            this.handleSubmit(); //call handleSubmit when munis selection changes
        });
    };

    handleArrangementChange = (selectedOption) => {
        this.setState({ selectedArrangement: selectedOption }, () => {
            this.handleSubmit(); //call handleSubmit when arrangement selection changes
        });
    };

    handleSubmit = () => {
        if (!this.state.selectedMuni?.value || !this.state.selectedArrangement?.value) {
            return; // Return early if either muni or arrangement is not selected
        }
        const headers = {
            'Content-Type': 'application/json',
            'X-CSRFToken': Cookies.get('csrftoken')
        }
        let resp_buyers = fetch("/salja-virke-api/buyers/?municipality=" + this.state.selectedMuni.value, {
            method: 'GET',
            credentials: 'same-origin',
            headers: headers
        })
        .then((resp => resp.json()))
        .then((json) => {this.setState({"virkeskopare": json.count})});

        let resp_percentage = fetch(`/salja-virke-api/percentage/?municipality=${this.state.selectedMuni.value}&arrangement=${this.state.selectedArrangement.value}`, {
            method: 'GET',
            credentials: 'same-origin',
            headers: headers
        })
        .then((resp => resp.json()))
        .then((json) => {this.setState({"percentage": json.percentage})})
    };

    selectPerson(name) {
        /* Information for ContactPerson component */
        let person = 0;
        if (!name.includes('Joel')) {
            person = Math.floor(Math.random() * this.props.PERSONS.length);
        }
        
        return this.props.PERSONS[person]
    }

    handleSelectedPersonCookie(selectedPersonCookie) {
        let selectedPerson = JSON.parse(selectedPersonCookie);
        this.setState({selectedPerson: selectedPerson });
    }

    getNewSelectedPersonCookie(name) {
        Cookies.set('selected_person', this.selectPerson(name))
        const spc = Cookies.get('selected_person');
        this.handleSelectedPersonCookie(spc);
    }

    componentDidMount() {
        const selectedPersonCookie = Cookies.get('selected_person');
        try {
            var decodedString = atob(selectedPersonCookie);
            let selectedPerson = JSON.parse(decodedString);
            this.getNewSelectedPersonCookie(selectedPerson.name);
        } catch {
            /* In puck editor or draft you get a new cookie each reload to easy test new persons */ 
            if (selectedPersonCookie && !(window.location.pathname.includes('editor') || window.location.pathname.includes('draft'))) {
                this.handleSelectedPersonCookie(selectedPersonCookie);
              } else {
                  this.getNewSelectedPersonCookie('');
              }
        }        
      }

    render() {
        return (
            <section className={`${window.location.pathname.includes('editor') && (["dt", "mob"].filter((i) => i === this.props.visibility).length) ? 'opacity-50' : this.props.visibility == "dt" ? 'd-none d-lg-block' : this.props.visibility == "mob" ? 'd-lg-none' : ''} bg-${this.props.backgroundColor}`}>
          <div className='container'>
            <div className="row flex-md-row">           
              <div className={`bg-${this.props.backgroundColor} col-12 col-lg-7 pe-lg-5`}>
                <div className="">
                    <div className='white'>
                    <h2 className={`${this.props.headerColor} mb-2 n-fs-3`}>{this.props.header}</h2>
                    </div>
                    <div className='row pe-0'>
                        <div className='col-12 col-md-6'>
                            <VBDropdown
                                placeholder="Hela Sverige"
                                label={<label className={`${this.props.labelColor} n-fs-6 mb-1 mt-3`}>Välj kommun:</label>}
                                options={Window.react_data.lists.munis} //list of munis here
                                autoClose={true}
                                onChange={this.handleMuniChange}
                                value={this.state.selectedMuni}
                            />
                        </div>
                        <div className='col-12 col-md-6'>
                            <VBDropdown
                                label={<label className={`${this.props.labelColor} n-fs-6 mb-1 mt-3`}>Välj åtgärd:</label>}
                                placeholder="Alla typer"
                                options={Window.react_data.lists.arrangements} //list of types here
                                autoClose={true}
                                onChange={this.handleArrangementChange}
                                value={this.state.selectedArrangement}
                            />
                        </div>
                    </div>
                    <div className='mt-5'>
                        <p className={`${this.props.labelColor} mb-0 n-fs-6`}>Resultat:</p> 
                    </div>
                    <div className='row pe-0'>
                        <div className='col-12 col-md-6 mt-1'>
                            <Card className='border rounded h-100'>
                                <Card.Body className='text-align-center'>
                                <p className='n-fs-6 text-center'>
                                    Antal köpare {this.props.selectedMuni ? `i ${this.state.selectedMuni.label}` : 'hela Sverige'} på Treebula{' '}
                                        <br />
                                        <div className='fw-bold n-fs-3 mt-3'>{this.state.virkeskopare} Virkesköpare</div>
                                    </p>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className='col-12 col-md-6 mt-1'>
                            <Card className='border rounded h-100'>
                                <Card.Body className='text-align-center'>
                                    <p className='n-fs-6 text-center'>Skogsägare på Treebula tjänar</p> 
                                    <p className='n-fs-3 fw-bold text-center pt-2'>{this.state.percentage}% mer</p>
                                </Card.Body>
                            </Card>
                        </div>
                    </div>
                    <p className={`${this.props.labelColor} mt-2 fst-italic n-fs-7`} >{`${this.props.legalText}`}</p>
                </div>
              </div>
              <div className='col-12 col-lg-5'>
                    <div className=''>
                    <Card className={`p-3 shadow border-0 bg-${this.props.contactCardBackgroundColor}`}>
                    <div className='container-fluid'>
                        <div className='row'>
                        <div className={`text-center text-md-start p-0 ${this.props.contactTextColor}`}>
                            <h2 className='mb-2 n-fs-3'>{this.props.contactHeader}</h2>
                            <p className='n-fs-6'>{this.props.contactText}</p>
                        </div>

                        {this.state.selectedPerson && (
                        <>
                            <div className='col-12 col-md-4 mt-2 d-flex justify-content-center flex-grow-0 p-0'>
                                <Card.Img className='img-fluid rounded' style={{ maxWidth:"160px", maxHeight:"160px", objectFit:"contain" }} variant='top' src={`${Window.static_path + this.state.selectedPerson.image}`} alt={this.state.selectedPerson.name} />
                            </div>
                            <div className='col-12 col-md-7 p-0 ms-2'>
                            <p className={`n-fs-4 mt-3 mt-lg-2 mt-md-2 mb-2 text-center text-md-start ${this.props.contactTextColor}`}>{this.state.selectedPerson.name}</p>
                            <p className={`n-fs-5 text-center text-md-start ${this.props.contactTextColor}`}>{this.state.selectedPerson.title}</p>
                            <h6 className={`n-fs-5 mt-3 text-center text-md-start ${this.props.contactTextColor}`}>Kontakuppgifter:</h6>
                            <h6 className='n-fs-6 text-center text-md-start'>
                                <a className={`${this.props.linkColor} hover:white`} href={`tel:${this.state.selectedPerson.mobile}`}>{this.state.selectedPerson.mobile}</a>
                            </h6>
                            <h6 className='n-fs-7 text-center text-md-start'>
                                <a className={`${this.props.linkColor} hover:white`} href={`mailto:${this.state.selectedPerson.email}`}>{this.state.selectedPerson.email}</a>
                            </h6>
                            </div>
                        </>
                        )}

                        {/* Button to typeform */}
                        {this.props.showTypeFormButton == "yes" && (
                            <div className='mt-2 p-0 d-flex justify-content-center'>
                            <a href={`${this.props.typeformLink}`} target="_blank" className="n-btn btn-charcoal btn-outline">Boka en genomgång</a>
                            </div>
                        )}

                        </div>
                    </div>
                    </Card>
                </div>
              </div>
            </div>
          </div>
        </section>
            
        );
    }
}

EarningsComparison.defaultProps = {
    PERSONS: [
        {
            name: "Joel Bråtner",
            title: "Försäljningschef",
            mobile: "073-051 86 53",
            email: "joel.bratner@treebula.se",
            image: "img/illustration/image-joel-new.jpg"
        },
        {
            name: "Emma Anderson",
            title: "Rådgivare",
            mobile: "073-021 44 01",
            email: "emma.andersson@treebula.se",
            image: "img/illustration/image-emma-andersson.jpg"
        },
        {
            name: "Fredrik Niemelä",
            title: "Rådgivare",
            mobile: "073-358 83 74",
            email: "fredrik.niemela@treebula.se",
            image: "img/illustration/image-fredrik-niemela.jpg"
        },
        {
            name: "Anton Lindholm",
            title: "Rådgivare",
            mobile: "070 232 35 84",
            email: "anton.lindholm@treebula.se",
            image: "img/illustration/image-anton-lindholm.jpg"
        }
 /*        ,
        {
            name: "Martina Stenberg",
            title: "Kundservice",
            mobile: "08-33 99 44",
            email: "info@treebula.se",
            image: "img/illustration/image-martina-stenberg.jpg"
        } */
    ],
    header: "Se hur mycket mer du kan tjäna med Treebula i din kommun",
    visibility: "",
    backgroundColor: 'neutral-stone-500',
    headerColor: 'neutral-charcoal-500',
    labelColor: 'neutral-charcoal-500',
    showTypeFormButton: "yes",
    contactHeader: "Vi är här för att hjälpa dig!",
    contactText: "Ring oss så berättar vi mer än gärna hur vi kan hjälpa dig när du tänker sälja ditt virke.",
    legalText: 'Då många skogsägare redan fått ett pris innan de kommer till Treebula kan vi visa denna statistik',
    contactCardBackgroundColor: 'neutral-cloud-300', 
    contactTextColor: 'neutral-charcoal-500', 
    contactTextCardColor: 'neutral-charcoal-500',
    typeformLink: 'https://virkesborsen.typeform.com/to/MwS6W2',
    linkColor: 'supp-spruce-500',
  
  }
  
  EarningsComparison.fields = {
    backgroundColor: {
      type: "custom",
      render: (props) => <ColorSelector {...props} />
    },
    headerColor: {
        type: "custom",
        render: (props) => <ColorSelector {...props} />
      },
    labelColor: {
    type: "custom",
    render: (props) => <ColorSelector {...props} />
    },
    contactCardBackgroundColor: {
        type: "custom",
        render: (props) => <ColorSelector {...props} />
    },
    visibility: {
      type: "select",
      options: Opt.visibilityOptions
    },
    header: { type: "text" },
    showTypeFormButton: {
        type: "select",
        options: Opt.yesNo
      },
    contactHeader: { type: "text" },
    contactText: { type: "text" },
    legalText: { type: "text" },
    contactTextColor: {
        type: "custom",
        render: (props) => <ColorSelector {...props} />
      },
    typeformLink: { type: "text" },
    linkColor: {
    type: "custom",
    render: (props) => <ColorSelector {...props} />
    },
  }

export default EarningsComparison;
