import Modal from 'react-bootstrap/Modal';
import Cookies from 'js-cookie';
import React from 'react';
import VBSearchInput from '@virkesborsen/vb-js-commons/lib/components/new/vbSearchInput';

class MediaSelector extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            showMedia: false,
            showImageSize: false,
            selectedImage: null,
            files: [],
            pages: 1,
            page_nr: 1,
            image_search:'',
            type: this.props.type ? this.props.type : 'img',
            default_src: this.props.defaultProps
        }
    }

    componentDidMount(){
        this.getFiles();
    }

    getFiles(){
        fetch(`/cms-api/cmsfile/?pagenr=${this.state.page_nr}&search=${this.state.image_search}&type=${this.state.type}`).then(resp => resp.json()).then((json) => {
            this.setState({files:json.files, pages:json.pages})
        })
    }

    checkFileSize(files) {
        if (files.length > 0) {
            /* Todo: possible to upload several files? */
            console.log(files[0].size);
            if (files[0].size > 1000000) {
                this.setState({ showImageSize: true, selectedImage: files[0] })
            } else {
                this.uploadFile(files[0]);
            }
        } else {
            console.log('No file')
        }
    }
    uploadFile(file){
        const formData = new FormData();
        formData.append("file", file);
        fetch(
            "/cms-api/cmsfile/", {
                method: "POST",
                headers: {
                    'X-CSRFToken': Cookies.get('csrftoken')
                },
                body:formData
            }
        ).then(resp => resp.json()).then((json) => {
            this.state.files.push(json.file_url);
            this.setState({files:this.state.files});
        })
    }
    render(){
        return (
        <>
            <Modal show={this.state.showImageSize} onHide={() => this.setState({ showImageSize: false })} size="lg" contentClassName="bg-supp-spruce p-4" centered>
                <Modal.Header className="align-items-start border-0" closeButton>
                <div className="row">
                    <div className="col-12">
                    <h5 className="white mb-0">{`Large Image Size ${this.state.selectedImage ? Number(this.state.selectedImage.size / 1000000).toFixed(2): 'X'} MB detected!`}</h5>
                    </div>
                </div>
                </Modal.Header>
                <Modal.Body className="white p-2">
                <div className="container">
                <div className="row">
                    <div className="col-12">
                        <p>{this.state.selectedImage?.size > 3000000 ? `Large Images decrease site performance and must be compressed before upload! Contact Tech Support.`: 'This image size is a bit large and should be compressed to not decrease site performance, are you sure you want to upload this file anyway?'}</p>
                    </div>
                </div>
                <div className="row justify-content-end gap-2">
                    <div className="col-4 col-lg-2">
                    <button className="n-btn btn-outline" onClick={ () => {this.setState({ showImageSize: false, showMedia: false })}}>Stäng</button>
                    </div>
                    
                    {this.state.selectedImage?.size < 3000000 ?
                    <div className="col-5 col-lg-3">
                    <button className="n-btn btn-core" onClick={ () => {this.setState({ showImageSize: false }, () => {this.uploadFile(this.state.selectedImage)})}}>Fortsätt</button>
                    </div>
                    :null}
                    
                </div>
                </div>
                </Modal.Body>
            </Modal>
            <Modal show={this.state.showMedia} onHide={() => this.setState({ showMedia: false })} size="xl" contentClassName="bg-white p-2" centered>
                <Modal.Header className="align-items-start border-0" closeButton>
                    <div className="row col-12">
                        <div className="col-8">
                            <h5 className="neutral-pebble mb-0">{`Click the ${this.state.type == "img" ? "image": "icon"} you want to use:`}</h5>
                        </div>
                        <div className="col-2">
                            <input type="file" name="file" id="file" className="d-none" onChange={(e) => {this.checkFileSize(e.target.files)}} />
                            <label className="n-btn btn-core btn-xs text-nowrap" for="file">Upload file</label>                           
                        </div>
                        <div className="col-2">
                            <button className="n-btn btn-alert btn-xs text-nowrap" onClick={()=>{this.props.onChange(this.state.default_src); this.setState({showMedia: false})}}>Remove</button>                           
                        </div>
                        
                    </div>
                </Modal.Header>
                <Modal.Body className="neutral-pebble py-0">
                    <div className="container">
                    {((this.state.files).length > 0 || this.state.image_search != '') && <div className='mb-2 row d-flex justify-content-end'>
                        <div className='col-12 col-md-4'>
                            <VBSearchInput
                                inputStyle={{minHeight: 0, borderStyle:"groove"}} 
                                ref={this.search_image}
                                placeholder={`Search for ${this.state.type == "img" ? "image": "icon"}`}
                                onChange={(val) => { this.setState({image_search:val},()=>{this.getFiles()})}}
                                />
                            </div>
                        </div>}
                    
                            <div className='row'>
                                {this.state.files.map((itm) => {
                                    let fileNameArr = itm.split('/')
                                    let fileName = fileNameArr[fileNameArr.length-1]
                                    return <div className="col-6 col-lg-2">
                                        <div className='mb-2'>
                                        <div className="border border-1 border-neutral-pebble rounded p-2" onClick={() => { this.props.onChange(itm); this.setState({ showMedia: false }) }}>
                                        <img className="img-fluid bg-white" style={{height:"100px", objectFit:"contain"}} src={itm} />
                                        <div className="n-fs-8 neutral-pebble">{fileName.length > 20 ? `${fileName.slice(0,17)}...${fileName.slice(-3)}` : fileName}</div>
                                        </div>
                                        </div>
                                        </div>;
                                })}
                            </div>
                            <div className="row d-flex justify-content-end gap-2">
                                {this.state.page_nr > 1 ? <button className="n-btn btn-sm btn-primary col-4 col-md-2" onClick={() => {
                                    this.setState({ page_nr: this.state.page_nr - 1 }, () => {
                                        this.getFiles()
                                    });
                                }}>Previous Page</button> : ''}
                                {this.state.page_nr != this.state.pages ? <button className="n-btn btn-sm btn-outline col-4 col-md-2" onClick={() => {
                                    this.setState({ page_nr: this.state.page_nr + 1 }, () => {
                                        this.getFiles()
                                    });
                                }}>Next Page</button> : ''}
                            </div>
                
                        <div className="neutral-pebble">{this.state.page_nr} / {this.state.pages}</div>
                    </div>
                </Modal.Body>
            </Modal>
            <div className="col-12 n-fs-6 fw-bold mb-2">
            {this.props.name ? this.props.name : ''}
            </div>
            <button className="_Button_1brfa_1 _Button--secondary_1brfa_53 _Button--medium_1brfa_22" onClick={() => {this.getFiles();this.setState({showMedia: true})}}>{`Select ${this.state.type == "img" ? "image": "icon"}`}</button>
            
        </>)
    }
}

export default MediaSelector;
