import React from 'react';
import VBInput from "@virkesborsen/vb-js-commons/lib/components/new/vbInput";
import VBTextarea from "@virkesborsen/vb-js-commons/lib/components/new/vbTextarea";
import VBPhoneInput from "@virkesborsen/vb-js-commons/lib/components/new/vbPhoneInput";
import VBMultiOption from "@virkesborsen/vb-js-commons/lib/components/new/vbMultiOption";
import VBDropdown from '@virkesborsen/vb-js-commons/lib/components/new/vbDropdown';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { validatePNR } from '../api.js';
import Modal from 'react-bootstrap/Modal';
import Cookies from 'js-cookie';

// The state requiredFields is for specific fields you want to show on screen example if you want to show only these:
// requiredFields: ["first_name", "company_name","password"] otherwise the standard list with all fields is shown
class ProfileEditPage extends React.Component {
    constructor() {
        super();
        this.state = {
            errors: {},
            formData: {},
            hasRecurring: Window.react_data.existing_data.has_recurring,
            endSubscriptionFormData: {},
            endSubMessage: '',
            successEndSubModal: false,
            endPrimakundModal: false,
            successModal: false,
            requiredFields: Window.react_data.lists.requiredFields
        }
    }

    handleSubmit(e) {
        e.preventDefault();

        let data = this.state.formData
        let existingData = Window.react_data.existing_data
        let validation = {};

        if (this.state.requiredFields) {
            this.state.requiredFields.forEach((required) => {
                if (required == "first_name" && !data.first_name && !Window.react_data.existing_data.first_name) {
                    validation.first_name = "Förnamn är obligatoriskt."
                }
                if (required == "last_name" && !data.last_name && !Window.react_data.existing_data.last_name) {
                    validation.last_name = "Efternamn är obligatoriskt."
                }
                if (required == "personal_number" && !data.personal_number && !Window.react_data.existing_data.personal_number) {
                    validation.personal_number = "Personnummer du angav är inte korrekt"
                }
                if (required == "mobile_phone_number" && (!data.mobile_phone_number || data.mobile_phone_number.length < 1) && !Window.react_data.existing_data.mobile_phone_number) {
                    validation.mobile_phone_number = "Telefonnumret du angav är inte korrekt";
                }
                if (required == "muni" && (!data.muni || data.muni.length < 1) && !Window.react_data.lists.munis) {
                    validation.muni = "Du måste välja minst en kommun";
                }
                if (required == "Address" && (!data.address) && !Window.react_data.existing_data.address) {
                    validation.address = "Adress är obligatoriskt."
                }
                if (required == "postal_code" && !data.postal_code && !Window.react_data.existing_data.postal_code) {
                    validation.postal_code = "Postnummer är obligatoriskt."
                }
                if (required == "city" && !data.city && !Window.react_data.existing_data.city) {
                    validation.city = "Stad/Ort är obligatoriskt."
                }
                if (required == "company_name" && !data.company_name && !Window.react_data.existing_data.company_name) {
                    validation.company_name = "Företagsnamn är obligatoriskt"
                }
                if (required == "organisation_number" && !data.organisation_number && !Window.react_data.existing_data.organisation_number) {
                    validation.organisation_number = "Organisationsnummer är obligatoriskt."
                }
            })
        }

        if (data.personal_number && !validatePNR(data.personal_number.replace('-', ''))) {
            validation.personal_number = "Personnummer du angav är inte korrekt"
        } else if (!data.personal_number && existingData.personal_number && !validatePNR(existingData.personal_number.replace('-', ''))) {
            validation.personal_number = "Personnummer du angav är inte korrekt"
        }

        if (data.mobile_phone_number && !isValidPhoneNumber(data.mobile_phone_number)) {
            validation.mobile_phone_number = "Telefonnumret du angav är inte korrekt";
        }

        if (Object.keys(validation).length !== 0) {
            window.scrollTo({ top: 0, behavior: 'smooth' })
            this.setState({ errors: validation })
        } else {
            if (Object.keys(data).length !== 0) {
                let target = "/user-api/update/"

                fetch(target, {
                    method: "POST",
                    body: JSON.stringify({ "data": data }),
                    headers: {
                        'Content-Type': 'application/json',
                        'X-CSRFToken': Cookies.get('csrftoken')
                    },
                }).then(res => {
                    if (res.ok) {
                        if (Window.react_data.next_path) {
                            window.location.href = Window.react_data.next_path
                        } else {
                            this.setState({ successModal: true })
                        }
                    } else {
                        alert('Oops! Något gick fel...')
                    }
                });
            }
        }
    }

    handleSubmitEndPrimakund(e) {
        e.preventDefault()
        
        this.setState({ endPrimakundModal: false })
        let target = "/primakund/end-subscription/"

        fetch(target, {
            method: "POST",
            body: JSON.stringify({ "endSubscription": this.state.endSubscriptionFormData }),
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': Cookies.get('csrftoken')
            },
        }).then(res => {
            if (res.ok) {
                res.json().then(json => {
                    this.setState({ hasRecurring: false, endSubMessage: json.message, successEndSubModal: true })
                });
            } else {
                alert('Oops! Något gick fel...')
            }
        });
    }

    render() {
        let formattedEndDate = '';

        if (Window.react_data && Window.react_data.existing_data && Window.react_data.existing_data.date) {
            formattedEndDate = new Date(Window.react_data.existing_data.date).toLocaleString('sv-SE', { day: 'numeric', month: 'long', year: 'numeric' });
        }
        return (<div className="container-fluid p-0">
            <div className="bg-white">

                {this.state.requiredFields && <div className="bg-supp-forest-green">
                    <div className="container py-3">
                        <h2 className="white n-fs-4 mb-0">Vi behöver mer information från dig för att du ska kunna fortsätta</h2>
                    </div>
                </div>}

                <section className="container pt-2">
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-6 px-lg-5">
                        {/* {Window.react_data.existing_data.subscription && <>
                                <h2 className="n-fs-4">Dina prenumerationer</h2>
                                <h5 className="n-fs-5 mt-4">
                                    Du är Pluskund
                                    {this.state.hasRecurring ? 
                                        <div>
                                            <b> Förnyas {formattedEndDate}</b>{' '}
                                            <span className="neutral-charcoal mb-0" role="button" onClick={() => this.setState({ endPrimakundModal: true })}>
                                                Avsluta Pluskund
                                            </span>{' '}
                                            eller{' '}
                                            <a href="/primakund/?subscription_length=year&instant_generate=1#klarna_container" className="neutral-charcoal">
                                                Byt till års Pluskund
                                            </a>
                                        </div>
                                     : <b> Avslutas {formattedEndDate}</b> }
                                </h5>
                            </>} */}
                            <form onSubmit={(e) => { this.handleSubmit(e) }} className="my-4">
                                <h2 className="mt-4 n-fs-5">Kontaktinformation</h2>

                                <div>
                                    {(!this.state.requiredFields || this.state.requiredFields.includes("first_name")) && <VBInput
                                        type="text"
                                        label={<label className='subtitle pb-1'>Förnamn</label>}
                                        initial={Window.react_data?.existing_data?.first_name}
                                        error={this.state.errors.first_name}
                                        onFocus={() => this.setState({errors:{...this.state.errors,first_name:''}})}
                                        onChange={(val) => { this.setState({ formData: { ...this.state.formData, first_name: val } }) }} />}

                                    {(!this.state.requiredFields || this.state.requiredFields.includes("last_name")) && <VBInput
                                        type="text"
                                        label={<label className='subtitle pb-1 mt-3'>Efternamn</label>}
                                        initial={Window.react_data?.existing_data?.last_name}
                                        error={this.state.errors.last_name}
                                        onFocus={() => this.setState({errors:{...this.state.errors,last_name:''}})}
                                        onChange={(val) => {this.setState({ formData: { ...this.state.formData, last_name: val } }) }} />}

                                    {((!this.state.requiredFields || this.state.requiredFields.includes("personal_number")) && !Window.react_data?.existing_data?.bankid_enabled) && <VBInput
                                        type="text"
                                        label={<label className='subtitle pb-1 mt-3'>Personnummer (ÅÅÅÅMMDD-XXXX)</label>}
                                        initial={Window.react_data?.existing_data?.personal_number}
                                        error={this.state.errors.personal_number}
                                        onFocus={() => this.setState({errors:{...this.state.errors,personal_number:''}})}
                                        onChange={(val) => { val=val.replace(/-/g,"");this.setState({ formData: { ...this.state.formData, personal_number: val } }) }} />}

                                    {Window.react_data?.existing_data?.bankid_enabled && !this.state.requiredFields && <>
                                        <label className='subtitle pb-1 mt-3'>Personnummer</label>
                                        <h5 className='fw-medium ms-1 fs-6'>{Window.react_data?.existing_data?.personal_number}</h5></>}
                                    
                                    {!this.state.requiredFields && <>
                                        <label className='subtitle pb-1 mt-3'>E-postadress</label>
                                        <h5 className='fw-medium ms-1 fs-6'>{Window.react_data?.existing_data?.email}</h5>
                                    </>}

                                    {(!this.state.requiredFields || this.state.requiredFields.includes("mobile_phone_number")) && <VBPhoneInput
                                        label={<label className='subtitle pb-1 mt-3'>Mobilnummer</label>}
                                        initial={Window.react_data?.existing_data?.mobile_phone_number}
                                        error={this.state.errors.mobile_phone_number}
                                        onFocus={() => this.setState({errors:{...this.state.errors,mobile_phone_number:''}})}
                                        onChange={(val) => { this.setState({ formData: { ...this.state.formData, mobile_phone_number: val }, errors:{...this.state.errors, mobile_phone_number:''} }); }} />}

                                    {(!this.state.requiredFields || this.state.requiredFields.includes("muni")) && <>
                                        <h2 className="mt-5 n-fs-5">Dina aktiva kommuner</h2>
                                            <VBMultiOption
                                            label={<label className='subtitle pb-1 mt-1'>Kommun</label>}
                                            options={Window.react_data?.lists?.munis ? Window.react_data.lists.munis : []}
                                            initial={Window.react_data?.existing_data?.muni}
                                            error={this.state.errors.muni}
                                            onFocus={() => this.setState({errors:{...this.state.errors,muni:''}})}
                                            onChange={(val) => { this.setState({ formData: { ...this.state.formData, muni: val.map(itm => parseInt(itm.value)) } }); }} />
                                    </>}

                                    {(!this.state.requiredFields || this.state.requiredFields.includes('address') || this.state.requiredFields.includes('postal_code') || this.state.requiredFields.includes('city')) &&
                                        <h2 className="mt-5 n-fs-5">Din hemadress eller företagsadress</h2>}

                                    {(!this.state.requiredFields || this.state.requiredFields.includes("address")) && <VBInput
                                        type="text"
                                        label={<label className='subtitle pb-1 mt-1'>Adress</label>}
                                        initial={Window.react_data?.existing_data?.address}
                                        error={this.state.errors.address}
                                        onFocus={() => this.setState({errors:{...this.state.errors,address:''}})}
                                        onChange={(val) => { this.setState({ formData: { ...this.state.formData, address: val } }) }} />}

                                    {(!this.state.requiredFields || this.state.requiredFields.includes("postal_code")) && <VBInput
                                        type="number"
                                        label={<label className='subtitle pb-1 mt-3'>Postnummer</label>}
                                        initial={Window.react_data?.existing_data?.postal_code}
                                        error={this.state.errors.postal_code}
                                        onFocus={() => this.setState({errors:{...this.state.errors,postal_code:''}})}
                                        onChange={(val) => { this.setState({ formData: { ...this.state.formData, postal_code: val } }) }} />}

                                    {(!this.state.requiredFields || this.state.requiredFields.includes("city")) && <VBInput
                                        type="text"
                                        label={<label className='subtitle pb-1 mt-3'>Stad/ Ort</label>}
                                        initial={Window.react_data?.existing_data?.city}
                                        error={this.state.errors.city}
                                        onFocus={() => this.setState({errors:{...this.state.errors,city:''}})}
                                        onChange={(val) => { this.setState({ formData: { ...this.state.formData, city: val } }) }} />}

                                    {(!this.state.requiredFields || this.state.requiredFields.includes('company_name') || this.state.requiredFields.includes("organisation_number") || this.state.requiredFields.includes("vat_number")) &&
                                        <h2 className='mt-5 n-fs-5'>Om du är ett företag ange</h2>}

                                    {(!this.state.requiredFields || this.state.requiredFields.includes("company_name")) && <VBInput
                                        type="text"
                                        label={<label className='subtitle pb-1 mt-1'>Företagsnamn</label>}
                                        initial={Window.react_data?.existing_data?.company_name}
                                        error={this.state.errors.company_name}
                                        onFocus={() => this.setState({errors:{...this.state.errors,company_name:''}})}
                                        onChange={(val) => { this.setState({ formData: { ...this.state.formData, company_name: val } }) }} />}

                                    {(!this.state.requiredFields || this.state.requiredFields.includes("organisation_number")) && <VBInput
                                        type="text"
                                        label={<label className='subtitle pb-1 mt-3'>Organisationsnummer (XXXXXX-XXXX)</label>}
                                        initial={Window.react_data?.existing_data?.organisation_number}
                                        error={this.state.errors.organisation_number}
                                        onFocus={() => this.setState({errors:{...this.state.errors,organisation_number:''}})}
                                        onChange={(val) => { this.setState({ formData: { ...this.state.formData, organisation_number: val } }) }} />}

                                    {(!this.state.requiredFields || this.state.requiredFields.includes("vat_number")) && <VBInput
                                        type="text"
                                        label={<label className='subtitle pb-1 mt-3'>VAT-nummer</label>}
                                        initial={Window.react_data?.existing_data?.vat_number}
                                        error={this.state.errors.vat_number}
                                        onFocus={() => this.setState({errors:{...this.state.errors,vat_number:''}})}
                                        onChange={(val) => { this.setState({ formData: { ...this.state.formData, vat_number: val } }) }} />}

                                    <div className="row mt-3">
                                        <div className={`col-12 d-flex ${this.state.requiredFields ? 'justify-content-end' : 'justify-content-between'}`}>
                                            {!this.state.requiredFields && <a href="/forgot-password/" className="supp-pine fw-medium n-fs-6" target="_blank">Byt lösenord?</a>}

                                            <button type="submit" className="n-btn btn-forest-green">{Window.react_data.next_path ? 'Fortsätt' : 'Spara uppgifter'}</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
            </div>
            
            <Modal show={this.state.endPrimakundModal} onHide={() => this.setState({ endPrimakundModal: false })} size="lg" contentClassName="bg-primary-dark p-2" centered>
                <Modal.Header className="align-items-start border-0" closeVariant="white" closeButton>
                    <h2 className="primary-main mb-0">Avsluta prima-prenumeration</h2>
                </Modal.Header>
                <form onSubmit={(e) => { this.handleSubmitEndPrimakund(e) }}>
                    <Modal.Body className="py-0">
                    
                        <p className="fw-bold white">
                            Är du <span className="fw-bold">säker</span> på att du vill <span className=" fw-bold">avsluta</span> din prima-prenumeration?
                            Efter du avslutat är du fortfarande Pluskund till och med <span className="fw-bold">{formattedEndDate}</span>.
                        </p>

                        <p className="mb-0 white">Vad ger du tjänsten för betyg?</p>
                        <VBDropdown
                            options={Window.react_data.lists.scale_grade}
                            placeholder="Klicka här för att svara"
                            onChange={(val) => { this.setState({ endSubscriptionFormData: { ...this.state.endSubscriptionFormData, scaleGrade: val } }) }} />

                        <p className="mb-0 mt-2 white">Varför valde du att avsluta din prenumeration?</p>
                        <VBDropdown
                            placeholder="Klicka här för att svara"
                            options={Window.react_data.lists.end_reason}
                            onChange={(val) => { this.setState({ endSubscriptionFormData: { ...this.state.endSubscriptionFormData, endingReason: val } }) }} />

                        <p className="mb-0 mt-2 white">Skulle du rekommendera Pluskund?</p>
                        <VBDropdown
                            placeholder="Klicka här för att svara"
                            options={Window.react_data.lists.recommendation}
                            onChange={(val) => { this.setState({ endSubscriptionFormData: { ...this.state.endSubscriptionFormData, recommendation: val } }) }} />

                        <p className="mb-0 mt-2 white">Övriga kommentarer</p>
                        <VBTextarea
                            type="text"
                            rows={4}
                            onChange={(val) => { this.setState({ endSubscriptionFormData: { ...this.state.endSubscriptionFormData, body: val } }) }} />
                    </Modal.Body>
                    <Modal.Footer className="justify-content-between border-0">
                        <button type="button" className="btn btn-primary border-white white hover-border-primary-main hover-primary-dark" onClick={() => this.setState({ endPrimakundModal: false })}>Stäng</button>
                        <button className='btn btn-secondary' type='submit'>Avsluta</button>
                    </Modal.Footer>
                </form>
            </Modal>

            <Modal show={this.state.successModal} onHide={() => { this.setState({ successModal: false, formData: {} }) }} size="md" contentClassName='bg-primary-dark p-2' centered>
                <Modal.Header className="align-items-start border-0" closeVariant="white" closeButton>
                    <h2 className="primary-main mb-0">LYCKADES</h2>
                </Modal.Header>
                <Modal.Body className="py-0">
                    <p className="white">Din information har sparats.</p>
                </Modal.Body>
                <Modal.Footer className="justify-content-end border-0">
                    <button className="btn btn-secondary btn-sm" onClick={() => this.setState({ successModal: false, formData: {} })}>Stäng</button>
                </Modal.Footer>
            </Modal>

            <Modal show={this.state.successEndSubModal} onHide={() => { this.setState({ successEndSubModal: false }) }} size="md" contentClassName='bg-primary-dark p-2' centered>
                <Modal.Header className="align-items-start border-0" closeVariant="white" closeButton>
                    <h2 className="primary-main mb-0">LYCKADES</h2>
                </Modal.Header>
                <Modal.Body className="py-0">
                    <p className="white">{this.state.endSubMessage}</p>
                </Modal.Body>
                <Modal.Footer className="justify-content-end border-0">
                    <button className="btn btn-secondary btn-sm" onClick={() => this.setState({ successEndSubModal: false })}>Stäng</button>
                </Modal.Footer>
            </Modal>
        </div>)
    }
}
export default ProfileEditPage;