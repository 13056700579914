import React from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';

import { Navigation } from '../context';
import { createAd } from '../../../api';

import VBDropdown from '@virkesborsen/vb-js-commons/lib/components/new/vbDropdown';

import ArbonicsModal from './ArbonicsModal';
import Cookies from 'js-cookie';

export default function MainNav() {
    const navContext = React.useContext(Navigation);

    const [offcanvas, setOffcanvas] = React.useState(false);
    const [createAdModal, setCreateAdModal]  = React.useState({show: false});
    const [arbonicsModalVisible, setArbonicsModalVisible] = React.useState(window.location.href.includes("#co2-interest"));

    const setHistory = (id) => {
        if(history.state?.comp != id) {
            history.pushState({comp: id}, '', '')
        }
    }

    const menuButton = (id, label, icon) => {
        return <div id={'menu' + id} role='button' onClick={() => {navContext.setView({comp: id}); setOffcanvas(false); setHistory(id)}} className={`d-flex align-items-center ${navContext.view?.comp == id ? 'bg-supp-forest-green white' : 'supp-forest-green hover-bg-supp-forest-green-100'} rounded mt-2 ps-2 py-2`}>
            <span className="d-flex align-items-center">
                <img src={`${Window.static_path}${icon}`} className={`${navContext.view?.comp == id ? 'white-filter' : 'supp-forest-green-filter'} `} height='25px' width='25px' />
                <span className="ms-2">{label}</span>
            </span>
        </div>
    }

    const menuLink = (url, label, icon) => {
        return <a href={url} className="d-flex align-items-center supp-forest-green hover-bg-supp-forest-green-100 rounded mt-2 ps-2 py-2">
            <img src={`${Window.static_path}${icon}`} className="supp-forest-green-filter" height="25px" width="25px" />
            <span className="ms-2">{label}</span>
        </a>
    }

    const sellerModal = () => {
        return <p role='button' onClick={() => {setCreateAdModal({show: true}); mixpanel.track("salja_virke_fe", { "source": "min-skog_main_nav" });}} className="d-flex align-items-center supp-forest-green bg-primary-core hover-bg-primary-core-600 rounded mt-2 ps-2 py-2 mb-0" style={{}}>
            <img src={`${Window.static_path}img/icons/tree-money.svg`} className="supp-forest-green-filter" height="25px" width="25px" />
            <span className="ms-2">Sälj virke</span>
        </p>
    }

    const menuItems = () => {
        return <div className="d-flex flex-column justify-content-between h-100 py-3 py-lg-4 n-fs-6">
            <div className="d-flex flex-column align-items-center text-center w-100">
                <a className='mb-4' href="/">
                    <img src={`${Window.static_path}img/treebula/logo.svg`}  className="d-block supp-forest-green-filter" height="30" />
                </a>
        
                <div className='w-100 mb-4'>
                    {menuButton('karta', 'Karta', 'img/icons/karta.svg')}
                    {menuButton('overblick', 'Skogsöverblick', 'img/icons/overview.svg')}
                    {menuButton('risker', 'Risker', 'img/icons/risks.svg')}
                </div>
                
                <div className='w-100 mb-4'>
                    <p className='supp-forest-green text-start n-fs-7 ms-2 mb-0'>Tjänster</p>

                    {!Window.user_data.roles.includes('seller') ? 
                        <>
                            {menuLink('/auctions/', 'Köp virke', 'img/icons/tree-money.svg')}
                            {menuLink('/mina-annonser/', 'Mina annonser', 'img/icons/item-list.svg')}
                            {sellerModal()}
                        </>
                    :   <>
                            {menuLink('/mina-annonser/', 'Alla mina annonser', 'img/icons/item-list.svg')}
                            {menuLink('/virkespriser/', 'Jämför virkespriser', 'img/icons/labels-trade.svg')}
                            {sellerModal()}
                        </>
                    }
                </div>
            </div>

            <div className="mb-0 bg-neutral-cloud-100 p-3 rounded-3">
                <p className="n-fs-7 fw-medium">
                    Är du intresserad av att tjäna kolkrediter?
                </p>
                <p className="n-fs-7">
                    Läs mer och skicka in din ansökan till oss.
                </p>
                {/* {Cookies.get("arbonicsModalSent") ? 
                <p className="bg-neutral-cloud-500 rounded-2 p-2 supp-forest-green n-fs-7 fw-medium d-flex align-items-center justify-content-center">Du har anmält ditt intresse! <img className="supp-forest-green-filter" style={{width:"16px", height:"16px"}} src={`${Window.static_path}img/icons/tick3.svg`} /></p>: */}
                <button onClick={() => {setArbonicsModalVisible(true)}} className="btn btn-outline btn-sm py-1 n-xs-fs-7">Anmäl intresse</button>
                {/* } */}
                </div>

            <div className='mb-0'>
                {menuLink('/my-account/edit/', 'Inställningar', 'img/icons/cog.svg')}
                {Window.user_data.isAdmin && menuLink('/vb-admin/', 'Admin', 'img/icons/User-line.svg')}
                {menuLink('/logout/', 'Logga Ut', 'img/icons/logout.svg')}
            </div>
        </div>
    }

    const mobileQuickItem = (id, label, icon) => {
        return <div id={'quickmenu' + id} className={`col-3 d-flex flex-column align-items-center justify-content-center h-100 text-center ${navContext.view?.comp == id ? 'bg-supp-forest-green' : ''}`} onClick={() => {navContext.setView({comp: id}); setHistory(id)}}>
            <img src={`${Window.static_path}${icon}`} className={`${navContext.view?.comp == id ? 'white-filter' : 'neutral-charcoal-filter'}`} height='25px' width='25px' />
            <span className={`${navContext.view?.comp == id ? 'white' : 'neutral-charcoal'}`} style={{fontSize: '.7em'}}>{label}</span>
        </div>
    }

    const handleCreateAd = () => {
        if(createAdModal.done) {
            setCreateAdModal({show: false})
        } else {
            mixpanel.track('Clicked salj virke min skog');
            if(!createAdModal.type) {
                setCreateAdModal({...createAdModal, error: 'Du måste ange en åtgärd'})
            } else {
                setCreateAdModal({...createAdModal, loading: true})
                if(createAdModal.type == 'grot') {
                    setCreateAdModal({show: false})
                    window.open("/biobransle/", "_blank")
                } else {
                    createAd({arrangement: createAdModal.type}).then(resp => {
                        if(resp.ok) {
                            setCreateAdModal({...createAdModal, loading: false, done: true, title: <h2 className="mb-0 n-h5">Tack</h2>, message: <p className='subtitle'>Tack för ditt intresse! Vi hör av oss till dig inom en arbetsdag.</p>})
                        } else {
                            setCreateAdModal({...createAdModal, loading: false, done: true, title: <h2 className="mb-0 n-h5">Oops, något gick fel.</h2>, message: <p className='subtitle'>Kontakta oss på <a className='fw-bold' href='mailto:info@treebula.se'>info@treebula.se</a> eller <a className='fw-bold' href='tel:00468339944'>08-33 99 44</a> så hjälper våra rådgivare dig att lösa detta så snabbt som möjligt.</p>})
                        }
                    })
                }
            }
        }
    }
    
    return <>
        <div className='d-flex align-items-center d-lg-none w-100 fixed-bottom bg-neutral-cloud p-0' style={{height: '80px', bottom: '-1px'}}>
            {mobileQuickItem('karta', 'Karta', 'img/icons/karta.svg')}
            {mobileQuickItem('overblick', 'Överblick', 'img/icons/overview.svg')}
            {/*mobileQuickItem('mina-annonser', 'Annonser', 'img/icons/item-list.svg')*/}
            <div className='col-3 d-flex flex-column align-items-center text-center' onClick={() => {setCreateAdModal({show: true});mixpanel.track("salja_virke_fe", { "source": "min-skog_main_nav" })}}>
                <img src={`${Window.static_path}img/icons/tree.svg`} height='25px' width='25px' />
                <span className={`neutral-pebble`} style={{fontSize: '.7em'}}>Sälj Virke</span>
            </div>
            <div className='col-3 d-flex flex-column align-items-center text-center' onClick={() => setOffcanvas(true)}>
                <img src={`${Window.static_path}img/icons/hamburger.svg`} height='25px' width='25px' />
                <span className={`neutral-pebble`} style={{fontSize: '.7em'}}>Meny</span>
            </div>
        </div>

        <div className="d-none d-lg-block bg-neutral-cloud h-100 px-4" style={{width: "270px",overflowY: "auto"}}>
            {menuItems()}
        </div>

        <Offcanvas className="d-lg-none bg-neutral-cloud border-0 px-4 w-100" style={{overflowY: "auto"}} show={offcanvas} onHide={() => setOffcanvas(false)} backdrop={false} placement='start' closeButton>
            <img src={`${Window.static_path}img/icons/cross.svg`} onClick={() => setOffcanvas(false)} className='neutral-charcoal-filter position-absolute end-0 me-4 mt-4' height='30px' width='30px' />
            {menuItems()}
        </Offcanvas>

        {/* CREATE AD Modal */}
        <Modal show={createAdModal.show} onHide={() => setCreateAdModal({show: false})} size="lg" contentClassName="bg-neutral-cloud neutral-charcoal p-2" centered>
            <Modal.Header className="align-items-start border-0" closeButton>
                <h2 className="mb-0 n-h5">{createAdModal.title ? createAdModal.title : 'Är det dags att sälja virke?'}</h2>
            </Modal.Header>
            <Modal.Body className="py-0">
                {createAdModal.message ? createAdModal.message :
                <>
                    <p className='subtitle'>Att sälja virke på Treebula är helt gratis och du har helt fri prövningsrätt. Det vill säga är du inte nöjd med anbuden kan du bara tacka nej. Tusentals skogsägare använder Treebula när de ska ska sälja virke.</p>

                    <p className='subtitle'>Vi berättar gärna mer om hur det funkar, ange bara vad det är du ska göra nedan så ringer vi upp dig och berättar mer. Du binder dig inte till något utan får bara mer information om hur du säljer virke på Treebula.</p>

                    <a href='/referenser/' className='d-block subtitle text-decoration-underline mb-3'>Läs en artikel om en av våra kunder här</a>

                    <VBDropdown
                        inputStyle={{minHeight: 0}} 
                        label={<label>Vilken åtgärd är det dags för?</label>}
                        onChange={(val) => setCreateAdModal({...createAdModal, type: val.value})}
                        error={createAdModal.error}
                        options={[{label: "Slutavverkning", value: "slutavverkning"}, {label: "Gallring", value: "gallring"}, {label: "Biobränsle eller GROT", value: "grot"}]}
                        inputMode='none' />
                </>}
            </Modal.Body>
            <Modal.Footer className={`${!createAdModal.done ? 'justify-content-between' : 'justify-content-end'} border-0`}>
                {!createAdModal.done && <button type="button" className="n-btn btn-forest-green btn-sm" onClick={() => setCreateAdModal({show: false})}>Stäng</button>}
                <button type="button" className="n-btn btn-core btn-sm" onClick={() => !createAdModal.loading && handleCreateAd()}>{createAdModal.loading ? <Spinner animation="border" size="sm" /> : createAdModal.done ? 'Okej' : 'Skapa annons'}</button>
            </Modal.Footer>
        </Modal>

        <ArbonicsModal visible={arbonicsModalVisible} hide={() => {setArbonicsModalVisible(false)}} />
    </>
}