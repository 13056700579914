import { apiFetch } from '../client';

export function getPropStatus(id) {
    return apiFetch('/map-api/get-prop-status/', {
        method: 'POST',
        body: JSON.stringify({ref: id})
    })
}

export function getProp(id) {
    return apiFetch('/map-api/prop/', {
        method: 'POST',
        body: JSON.stringify({lmID: id})
    })
}

export function generateProp(data) {
    return apiFetch('/map-api/generate-property/', {
        method: 'POST',
        body: JSON.stringify(data)
    })
}

export function fetchPropertyData(ref, type) {
    return apiFetch('/map-api/fetch-prop-data/', {
        method: 'POST',
        body: JSON.stringify({ref: ref, type: type})
    })
}

export function unlockProperty(lmID) {
    return apiFetch('/map-api/unlock-property/', {
        method: 'POST',
        body: JSON.stringify({lmID: lmID})
    })
}

export function generateForestandProperty(data) {
    return apiFetch('/map-api/import-forestand-to-sbp/', {
        headers: {},
        method: 'POST',
        body: data
    })
}

export function getSBPPdf(charts, activeProps) {
    return apiFetch('/map-api/get-sbp-pdf/', {
        method: 'POST',
        body: JSON.stringify({charts: charts, active_props: activeProps})
    })
}

export function searchProps(text) {
    return apiFetch('/map-api/props/', {
        method: 'POST',
        body:JSON.stringify({text: text})
    })
}

export function updateSectionsProcentage(id, name, value, aggregate,propType) {
    return apiFetch('/map-api/user-update-sections-procentage/', {
        method: 'POST',
        body: JSON.stringify({propID: id, field_name: name, field_value: value, aggregate: aggregate,propType:propType})
    })
}

export function deleteProperty(propID, propType) {
    return apiFetch('/map-api/user-delete-property/', {
        method: 'POST',
        body: JSON.stringify({propID: propID, propType: propType})
    })
}