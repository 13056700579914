import React from 'react';
import Cookies from 'js-cookie';

import VBPhoneInput from '@virkesborsen/vb-js-commons/lib/components/new/vbPhoneInput';
import VBMultiOption from '@virkesborsen/vb-js-commons/lib/components/new/vbMultiOption';
import Modal from 'react-bootstrap/Modal';
import styled from "styled-components";
import { validateForm} from '../vb-django/api';

// Set z-index for .jbyKgy dropdown options to ensure visibility in karta and overblick when PropModal is open.
const ComplementModal= styled(Modal)`
   z-index:1056;
`
class ComplementInfo extends React.Component {
    constructor(props){
        super();
        this.state={
            errors: {},
            munis: [],
            roles: [],
            phoneno: "",
            complementInfoModal: props.show_modal,
            formData: {}
        }
        this.rolesRef = React.createRef();
        this.munisRef = React.createRef();
        this.phoneInputRef = React.createRef();

    }

    validateFormFields(data){
    
        let checkList = [];
        if ((Window.user_data.regions).length < 1 && !data.munis ){
            checkList.push('muni')
        }
        if ((Window.user_data.roles).length < 1 && !data.roles){
            checkList.push('roles')
        }
        if (!Window.user_data.mobilePhoneNumber ){
            checkList.push('mobile_phone_number')
        }
    
        let validation = validateForm(data, checkList, true)
        
        //double '!!' to convert a value to a boolean: true/false
        let has_error = !!Object.values(validation).find(val => val)

        return {has_error, validation};
           
    }

    submit() {
        const headers = {
            'Content-Type': 'application/json',
            'X-CSRFToken': Cookies.get('csrftoken')
        }
        let data = this.state.formData
        let { has_error, validation } = this.validateFormFields(data)

        if (has_error) {
            this.setState({ errors: validation });
            return;
        } else {

            fetch("/user_complement_info_landing/", {
                method: 'POST',
                headers: headers,
                body: JSON.stringify({ "data": data }),
            }).then(resp => resp.json()).then((json) => {
                if (json.errors) {
                    let validation_phone = {}
                    if (json.errors.mobile_phone_number) {
                        validation_phone.mobile_phone_number = json.errors.mobile_phone_number
                        this.setState({ errors: validation_phone })
                    } else {
                        this.setState({ errors: json.errors })
                        return;
                    }
                } else {
                    this.setState({ complementInfoModal: false })
                }
            })
        }
    }

    render() {
        if (!this.state.complementInfoModal){
            return null;
        }
        return (
            <ComplementModal show={this.state.complementInfoModal} onHide={() => this.setState({ complementInfoModal: false })} size="lg" contentClassName="bg-neutral-cloud-200 neutral-charcoal p-3"  backdrop="static" centered>
              <Modal.Body>
                  <h3 className='mb-2 n-fs-5 n-md-fs-4'>Komplett information</h3>
                  <p class="n-fs-6">Treebula strävar alltid att förbättra tjänsten. Vi ber dig därför att nu komplettera information som vi saknar.</p>
                  {/* Choose Roles for user if they dont have any */}
                  {(Window.user_data.roles).length < 1 ? (
                  <div>
                      <VBMultiOption 
                      ref = {this.rolesRef}
                      options={Window.react_data.lists.roles}    
                      label={<label>Ange din roll(er)</label>}
                      optionsContainerStyle={{zIndex:1056}}
                      onChange={(val) => {
                          this.setState({formData:{...this.state.formData, roles: val.map((itm) => itm.value) }});
                      }}
                      error={this.state.errors?.roles}
                      onFocus={() => this.setState({ errors: { ...this.state.errors, roles: '' } })}
                      />
                  </div>
                  ) : null}
                  {/* Choose Muni for user if they dont have any */}
                  {(Window.user_data.regions).length < 1 ? (
                  <div>
                      <VBMultiOption
                      ref = {this.munisRef}
                      options={Window.react_data.lists.munis}
                      label={<label className="mt-3">Välj kommun(er)</label>}
                      optionsContainerStyle={{zIndex:1056}}
                      onChange={(val) => {
                          this.setState({ formData:{...this.state.formData, munis: val.map((itm) => itm.value) }});
                      }}
                      error={this.state.errors?.muni}
                      onFocus={() => this.setState({ errors: { ...this.state.errors, muni: '' } })}
                      />
                  </div>
                  ) : null}
                  {/* Enter Telephone Number for user if they dont have any */}
                  {!Window.user_data.mobilePhoneNumber ? (
                  <div>
                      <VBPhoneInput
                      ref = {this.phoneInputRef}
                      label={
                          <label className="mt-3">Ange ditt telefonnummer: </label>
                      }
                      onChange={(val) => {
                          this.setState({formData: {...this.state.formData, mobile_phone_number: val} });
                      }}
                      error={this.state.errors?.mobile_phone_number}
                      onFocus={() => this.setState({ errors: { ...this.state.errors, mobile_phone_number: '' } })}
                      />
                  </div>
                  ) : null}

                  <div className='row justify-content-end'>
                      <button class="n-btn btn-forest-green mt-3 col-md-3 col-6" onClick={() => {this.submit();}}>Fortsätt</button>
                  </div>
              </Modal.Body>
            </ComplementModal>
          );
    }
}
export default ComplementInfo;