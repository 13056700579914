import React from 'react';

import VBInput from "@virkesborsen/vb-js-commons/lib/components/new/vbInput";
import VBPhoneInput from "@virkesborsen/vb-js-commons/lib/components/new/vbPhoneInput";
import VBMultiOption from "@virkesborsen/vb-js-commons/lib/components/new/vbMultiOption";
import VBLoadingScreen from "@virkesborsen/vb-js-commons/lib/components/vbLoadingScreen";
import VBCheckbox from '@virkesborsen/vb-js-commons/lib/components/new/vbCheckbox';
import Modal from 'react-bootstrap/Modal';

import { validateForm, userExists,  loginOrActivateBankID, default_redirect } from '../api';
import * as Opt from '../components/editor/Helpers';
import ColorSelector from '../components/editor/fields/ColorSelector';
import VBDropdown from '@virkesborsen/vb-js-commons/lib/components/new/vbDropdown';
import Cookies from 'js-cookie';
import styled from "styled-components";
import {isMobile} from 'react-device-detect';
import { handleInputChange } from './handlers/formHandlers';

const InputContainer= styled.div`
    position: relative;
    width: 16rem; 
    height: 2rem;
    display: flex;
    justify-content: space-between;
`
const Input = styled.input`
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 1;
    text-align: center;
    caret-color: transparent; 
`
const Box = styled.div`
    width: 1.8rem;
    height: 100%;
    line-height: 2rem;
    border: 1px solid #ccc;
    box-sizing: border-box;
    text-align: center;
    font-size: 1rem;
    background-color: white;
    z-index: 0;
    margin: 0 0.05rem;
    position: relative;
`
const Caret = styled.div`
  position: absolute;
  width: 1px;
  height: 70%;
  background-color: black;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
  animation: blink 1s steps(2, start) infinite;

  @keyframes blink {
    to {
      visibility: hidden;
    }
  }
`
const ErrorModal= styled(Modal)`
   z-index: 1110;
`
const BankConnectedToAnotherAccountModal = styled(Modal)`
        z-index: 1110;
`
class LoginForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isReg: null,
            isLogged: Window.user_data.isLogged,
            formData: props.data ? props.data:{},
            errors: {},
            loading: false,
            formStatus: props.formStatus ? props.formStatus:'start',
            bankidEnabledSSN: false,
            bankidContent: <></>,
            code: [],
            personalNumber: null,
            sessionID: null,
            caretPosition: 0,
            qrCodeAvailable: false,
            // Modals
            errorModal: false,
            bankConnectedModal:false,
            attempts:0
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.checkEmailOrPhonenumber = this.checkEmailOrPhonenumber.bind(this);

        this.emailInput = React.createRef();
        this.codeRef = React.createRef();
        this.qrContainer = React.createRef();
        this.phoneInput = React.createRef();
        this.emailorPhoneInput = React.createRef();
    
    }

    componentDidMount() {  
        // TODO: puck page for /logga-in page is set twice one for visible in desktop another for visible in mobile.
        // This makes the login form component to render twice in /logga-in page. Find a fix in puck to only have one form that does both.
        document.removeEventListener('visibilitychange', this.trackFieldsChange);
        document.addEventListener('visibilitychange',this.trackFieldsChange); 
        const params = new URLSearchParams(window.location.search);
        const personalNumber = params.get('personal_number');
        const noemail = params.get('noemail')
        const email = params.get('email')

        let newState = {};

        if(email) {
            newState.formData= {email: email}
            userExists(`email=${encodeURIComponent(email)}`).then(resp => resp.json()).then(json => {
                this.setState({
                    isReg: json.exists,
                    bankidEnabledSSN: json.bankidEnabled,
                    multiple_mobile_users: json.multiple_mobile_users,
                    user_id: json.user_id
                })
            })
        }

        if (personalNumber){
            newState={...newState, emailForm: true, personalNumber:personalNumber }
        }
        if (noemail){
            newState={...newState,formStatus:'requestForm'}
        }
        if (this.props.data) {
         
            if (this.props.data.email) {
                newState={...newState, isEmail: true };
                this.checkEmailOrPhonenumber(`email=${encodeURIComponent(this.props.data.email)}`)
            } else if (this.props.data.mobile_phone_number) {
                newState={...newState, isEmail: false };
                this.checkEmailOrPhonenumber(`mobile_phone_number=${encodeURIComponent(this.props.data.mobile_phone_number)}`);
            }

            // Automatically login or register if formStatus is magicLink
            if (this.props.formStatus === 'magicLink') {
                setTimeout(() => {
                    this.loginOrRegister();
                    this.setState({ loading: false });
                }, 1000);
            }
        }
          
        // this is for skogsbruksplan page when user is logged & theres actionLocation provided
        if (this.state.isLogged && this.props.action && this.props.action.actionLocation && !window.location.pathname.includes('editor')) {
            newState={...newState, loading: true }
            window.location = this.props.action.actionLocation;
        }
        // batch state updates to one function to avoid multiple re renders
        Object.keys(newState).length > 0 && this.setState(newState)
           
    }

    componentWillUnmount(){
        document.removeEventListener('visibilitychange',this.trackFieldsChange);
        
    }
    
    // Successful login process
    completeLoginProcess(){
        if(this.props.completed){
            this.props.completed();
        }
        else if(this.props.action?.actionLocation){
            window.location = this.props.action.actionLocation
        }
         else{
            default_redirect();
        }
    }

    // Validation functionality

    //this is the VBInput for email or mobile number
    handleInputChange = (val) => {
        handleInputChange(val, this.setState.bind(this), this.checkEmailOrPhonenumber, 'formData');
    }
    
    checkEmailOrPhonenumber = (val) => {
        //clear previous timeout to debounce user typing
        if (this.timeout) clearTimeout(this.timeout);
    
        //debounce API request for 100ms
        this.timeout = setTimeout(() => {
            userExists(val)
                .then(resp => {
                    if (!resp.ok) throw new Error('Failed to check user existence');
                    return resp.json();
                })
                .then(json => {
                    const { exists, bankidEnabled, multiple_mobile_users, user_id } = json;
                    if(!exists){
                        mixpanel.track("registration_start_fe",{"source":window.location.pathname});
                    }
                    this.setState({
                        isReg: exists,
                        bankidEnabledSSN: bankidEnabled,
                        multiple_mobile_users: multiple_mobile_users,
                        user_id:user_id,
                    }); 
                })
                .catch(error => {
                    console.error(error);
                    this.setState({ errorModal: true, loading: false });
                });
        }, 200);
    } 
        
    validateFormFields(data){
    
        let checkList = [];
        // Remove current_url
        const { current_url, ...filteredData } = data;
        
        if (!this.state.isLogged) {
            if (this.state.isReg){
                checkList.push('email_phone')
            }
            else if (!this.state.isReg) {
                checkList.push('email_phone','roles', 'muni', 'checkbox','mobile_phone_number','email') 
            }
    
        }
    
        let validation = validateForm(filteredData, checkList, this.state.isReg)

        if(Object.keys(validation).length !== 0 && !this.state.isReg) {
            mixpanel.track("registration_attempt_fe", {form: 'login_form', errors: validation})
        }
        
        //double '!!' to convert a value to a boolean: true/false
        let has_error = !!Object.values(validation).find(val => val)

        return {has_error, validation};
           
    }

    // Email login functionality
    loginOrRegister() {
        let data={}
        data = { ...this.state.formData, current_url: window.location.href};

        let urlParams = new URLSearchParams(window.location.search)
        

        //handle URLs
        if (this.props.next_url) {
            data.next_url = this.props.next_url;
        } else if(this.props.action?.actionLocation) {
            data.next_url = this.props.action.actionLocation;
        }
        else if (urlParams.get("next")){
            data.next_url = urlParams.get("next")
        }

        //add additional data
        if(this.state.allowMatch) {
            data.ssn = this.state.bankidResp;
        }
        if (this.props.disable_magic_link){
            data.send_magic_link= false;
        }

        //validate form fields
        let {has_error,validation} = this.validateFormFields(data)
        if (has_error) {
            this.setState({ errors: validation });
            return;
        }
            
        this.setState({loading:true});

        const headers = {
            'Content-Type': 'application/json',
            'X-CSRFToken': Cookies.get('csrftoken')
        }

        return fetch("/user-api/login/", {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({ data: data })
        })
        .then(resp => resp.ok ? resp.json() : Promise.reject(resp))
        .then(json => { 
            this.setState({loginSetUrl: json.next_url})
            Window.user_data.loggedInUserID = json.user_id;        
            if(json.event == 'registered'){
                mixpanel.track("registration_success_fe", {form: 'login_form'})
                if(this.props.onUserRegister){
                    this.props.onUserRegister();
                }
                else{this.completeLoginProcess(json.next_url)}
            } else{
                if(this.props.onUserLogin){
                    this.props.onUserLogin();
                }
                this.showMagicLinkScreen(json);
            }
            
        })
        .catch(resp => {
          //  console.error(error);
            resp.json().then((json) => {
                this.setState({ loading: false, errors:json.errors, formStatus:'login'});
            })
        });
    }

    showMagicLinkScreen(json){
        this.setState({ formStatus:'magicLink', event:json.event, loading:false, errors:{}}, ()=>{
            setTimeout(() => {
                this.codeRef.current.focus();
            }, 100)
        })
    }

    validateCode() {
        this.setState({ loading: true });
        const codeString = this.state.code.join('');
        
        const headers = {
            'Content-Type': 'application/json',
            'X-CSRFToken': Cookies.get('csrftoken')
        };
        const url = `/verifycode/${codeString}${this.state.user_id}/?json=true`;
        
        return fetch(url, {
            method: 'GET',
            headers: headers,
        })
        .then(resp => {
            if (resp.ok) {
                return resp.json(); //for further processing
            } else {
                return resp.json().then(json => {
                    //handle error msg from BE
                    this.setState({ codeError: json.error || "Koden är ogiltig", loading: false },()=>{
                        mixpanel.track("login_failure_fe",
                            { 
                                error_type: json.error ? json.error : "",
                                failed_attempts: json.error_info.attempts ? json.error_info.attempts : 0,
                                login_method: json.error_info?.login_method ? json.error_info.login_method : ""
                            }
                        )
                    });
                               
                });
            }
        })
        .then(json => {
            //only runs if the response was ok
            if (json) {
                if (json.error){
                    this.setState({codeError:json.error,loading:false},()=>{
                        mixpanel.track("login_failure_fe",{"failed_attempts":json.error_info.attempts, "error_type": this.state.codeError,"login_method":json.error_info.login_method  })
                    })                                  
                }
                else{       
                    this.setState({ bankidEnabledSSN: json.bankid_enabled, isLogged: true }, () => {

                        //register
                        if (this.state.event === "registered") {
                            gtag('event', 'Registered', {});
                            gtag("event", "sign_up", { method: "Site" });

                        //login
                        } else if (this.state.event === "login") {
                            gtag("event", "login", { method: "site" });
                            mixpanel.track("login_success_fe", {"login_method":json.login_method})              
                        }
                        
                        if (this.state.bankidEnabledSSN === false) {
                            this.setState({ formStatus: "requestForm", loading: false, codeError: null });
                            
                        } else {
                            this.completeLoginProcess(json.next_url);
                        }
                    });}
            }
        })
        .catch(error => {
                //handle network /other unexpected errors
            this.setState({ codeError: "Ett oväntat fel uppstod", loading: false },()=>{
                mixpanel.track("login_failure_fe", {  "error_type": this.state.codeError })
            });
        });
    }
    //  Track registration fields
    trackFieldsChange=()=> {
       
        if (document.visibilityState === 'hidden' && this.state.isReg == false) {
            let filledFields = [];
            let emptyFields = [];
    
            const { current_url, ...filteredData } = this.state.formData; // Exclude `current_url`
            const checkList = ['roles', 'muni', 'checkbox', 'mobile_phone_number', 'email'];
    
            checkList.forEach((field) => {
                if (filteredData[field] && filteredData[field].toString().trim() !== '') {
                    filledFields.push(field);
                } else {
                    emptyFields.push(field);
                }
            });

            mixpanel.track("form_field_entry_fe", { "filled_fields": filledFields, "empty_fields": emptyFields, })
    
        }
    }

    // Login bank ID functionality
    async loginBankID() {
        try {
            //tracking login attempt
            mixpanel.track("Login Attempt Clicked BankID Button_FE", {
                login_method: this.state.qrCodeAvailable ? "QR Code BankID" : "Mobile App BankID",
            });

            this.setState({ loading: true });
                
            while (true) {
                const resp = await loginOrActivateBankID(this.state.formData.email, this.state.sessionID);
                const json = await resp.json();
        
                if (json.hasOwnProperty("message")) {
                    if (json.message.status === 'success') {
                        this.handleSuccess(json);
                        break;
                    } else if (json.message.status === 'failed') {
                        this.handleFailure(json.message.hintCode);
                        break;
                    } else if (json.message.status === 'pending') {
                        this.handlePending(json.message.hintCode, json.svg_image);
                        //add a delay to prevent spamming the BankID server with requests
                        await new Promise(resolve => setTimeout(resolve, 500));
                    }
                     
                } else {
                    //Track QR code login attempt
                    mixpanel.track("QR code displayed_FE", {
                        login_method: "QR Code BankID",
                    })
                    this.handleQRCode(json);
                }
            }
        } catch (error) {
            console.error('Error in login BankID:', error);
            this.setState({ loading: false });
        }
    }
        
    handleSuccess(json) {
        if (this.qrContainer.current) {
            this.qrContainer.current.innerHTML = null;
        }

        this.setState({ sessionID: null, bankidResp: json.ssn, successResp: json.success_resp, emailResp: json.user_email,userIdResp: json.user_id }, () => {
            //track successful login
            mixpanel.track("Login Successful_FE", {
                login_method: this.state.qrCodeAvailable ? "QR Code BankID" : "Mobile App BankID",
            });
            this.successContent();
        });
    }
        
    handleFailure(hintCode) {
        this.setState({ sessionID: null });
        let startError = '';

        if (hintCode === 'startFailed') {
            startError = 'Inloggningen avbröts, eller tog för lång tid. Av säkerhetsskäl finns det en tidsbegränsning.';
        } else if (hintCode === 'userCancel') {
            startError = 'Identifieringen avbröts från BankID-appen.';
        }

        if (this.qrContainer.current) {
            this.qrContainer.current.innerHTML = null;
        }
        this.setState({ formStatus: 'start', startError: errorMessages[hintCode] });
    }
        
    handlePending(hintCode, svgImage) {
        if (hintCode === 'userSign') {
            if (this.qrContainer.current) {
                this.qrContainer.current.innerHTML = null;
            }
            this.setState({ bankidContent: this.renderSignContent(), loading: false, qrCodeAvailable: false});
            setTimeout(() => { /*delay for better UX */ }, 1000);
        } else if (hintCode === 'outstandingTransaction') {
                this.qrContainer.current.innerHTML = svgImage;
            this.setState({ loading: false, qrCodeAvailable: true }); //stop loading after setting the SVG
            setTimeout(() => { /*delay for better UX */ }, 1000);
        }
    }
        
    handleQRCode(json) {
        let bankidHref = '';
        if(isMobile){
            bankidHref = `bankid:///?autostarttoken=${json.auto_start_token}&redirect=null`
            window.location.href=bankidHref
        } else {
            bankidHref = `bankid:///?autostarttoken=${json.auto_start_token}&redirect=null`
        } 
        const content = this.renderBankIDContent(bankidHref);
        this.setState({ sessionID: json.session_id, bankidContent: content, loading: false, qrCodeAvailable: true });
        this.qrContainer.current.innerHTML = json.svg_image;
    }
        
    renderSignContent() {
        return (
            <>
                <p className='n-fs-6'>Skriv in din säkerhetskod i BankID-appen och välj Legitimera eller Skriv under.</p>
                <button type='button' className='n-btn btn-ghost btn-charcoal w-100 mt-2' onClick={() => this.setState({ formStatus: 'start', startError: 'Identifieringen avbröts.' })}>Avbryt</button>
            </>
        );
    }
        
    renderBankIDContent(bankidHref) {
        return (
            <>
                <div className='d-flex justify-content-center pt-4'>                   
                    <p className='lh-2 n-fs-7'><span>1. Öppna appen för BankID.</span><br />
                    <span>2. Tryck på skanna QR-kod.</span></p>
                </div>
                <p className="fw-medium n-fs-6">Har du BankID på den här enheten?</p>
                <p className='n-fs-7'>Om ja, klicka på "Öppna BankID här" nedan för att logga in.</p>
                <div className='d-flex flex-column'>
                    <a type='button' href={bankidHref} className='n-btn btn-core btn-sm w-100'>
                        <img src={`${Window.static_path}img/icons/BankID_logo_white.svg`} role="button" className="white" width="40px" height="40px"/>Öppna BankID här
                    </a>
                </div>
                <button type='button' className='n-btn btn-ghost btn-charcoal w-100 mt-2 btn-sm' onClick={() => this.setState({ formStatus: this.state.isLogged ? 'requestForm' : 'start', startError: 'Identifieringen avbröts.', loading: false })}>Avbryt</button>
            </>
        );
    }
          
    successContent() {
        const logLoginEvent = () => {
            gtag("event", "login", { method: "site" });
            mixpanel.track("login_success_fe", {"login_method":"bankid"})
        };
        switch (this.state.successResp){
            case 'bankid_already_linked_to_user':
                logLoginEvent(); 
                Window.user_data.loggedInUserID = this.state.userIdResp
                this.setState({bankConnectedModal:true, loading:false});
                break;

            case 'user_updated':
                if(this.props.onBankIDConnect){
                    this.props.onBankIDConnect();
                }
                this.completeLoginProcess();
                logLoginEvent();
                break;

            case 'user_logged_in':
                logLoginEvent();
                Window.user_data.loggedInUserID = this.state.userIdResp

                if(this.state.formData.email !== null && typeof this.state.formData.email !== "undefined"  && (this.state.formData.email !== this.state.emailResp)){
                    this.setState({bankConnectedModal:true})
                }else{
                    if(this.props.onBankIDConnect){
                        this.props.onBankIDConnect();
                    }
                    this.setState({loading:true})
                    this.completeLoginProcess();
                }
                break;

            case 'created_temp_user':
                this.setState({formStatus:'login', loading: false})

            //handle unexpected success responses
            default:
                console.warn(`Unexpected success response: ${this.state.successResp}`);
                break;
        }
        
    }

    // Forms

    startForm() {
        //step1: select bankId or email or register

        if (this.props.title ==  null || this.props.title == ''){
            this.props.title="Välkommen till Treebula"
        }
        if(this.props.subText == null || this.props.subText == ''){
            this.props.subText="Hur vill du logga in?"
        }
        if(this.props.titleLogged == null || this.props.titleLogged =='') {
            this.props.titleLogged="Välkommen till Treebula"
        }   
        if(this.props.subTextLogged == null || this.props.subTextLogged ==''){
            this.props.subTextLogged="Du är redan inloggad."
        }

        return !this.state.isLogged ? <div className='d-flex flex-column'>
            <p className={`n-fs-3 fw-medium`}>{this.props.title}</p>
            <div className="fw-bold danger pb-2">{this.state.startError && this.state.startError}</div>
            {this.props.subText && <p className="mb-4">{this.props.subText}</p>}
            <a href="#" className='n-btn btn-core w-100' style={{cursor: "pointer"}} onClick={(e) => { e.preventDefault(); this.setState({ formStatus: 'bankid', loading: true }, () => {this.loginBankID();}); }}>
                <img src={`${Window.static_path}img/icons/BankID_logo_white.svg`} role="button" className="white" width="40px" height="40px" />BankID
            </a>
            
            <button type='button' className='n-btn mt-2 btn-sm btn-ghost btn-charcoal w-100' onClick={() => {              
                if (this.props.data?.email || this.props.data?.mobile_phone_number) {
                    if (this.state.successResp == 'created_temp_user') {
                        {/* Since the user singed with bankid already we don't ask for connect, we just automatically connect */}
                        this.setState({ allowMatch: true }, () => {
                            this.loginOrRegister()
                        })
                    } else {
                        this.loginOrRegister();
                    }
                } else { this.setState({ formStatus: 'login' }) }
            }}>Fortsätt med e-post eller mobilnummer</button>
            {this.state.errors?.resend_error && <p className='n-fs-7 mt-2 semantic-danger-500 text-align-center'>{this.state.errors?.resend_error}</p>}
            <p className='n-fs-7 mt-3 d-flex justify-content-center'>Har du inget konto? <a href="#" className='fw-bold text-decoration-underline ms-2' style={{cursor: "pointer"}} onClick={() => { this.setState({ formStatus: 'login'}) }}>Registrera</a></p>
        </div> :<div className='d-flex flex-column'>
            <p className={`n-fs-3 fw-medium`}>{this.props.titleLogged}</p>
            <p className="mb-4">{this.props.subTextLogged}</p>
            <a href="#" className='n-btn btn-core w-100' style={{cursor: "pointer"}} onClick={()=>{
                if(this.props.action.actionLocation){
                    window.location = this.props.action.actionLocation
                }else{
                    window.location.href = "/"
                }
            }}>Fortsätt
                <span>
                    <img className={`ms-2 neutral-charcoal-filter`} style={{ width: "18px", height: "18px" }} src={Window.static_path + "img/icons/arrow-black.svg"} />
                </span>
            </a>
        </div>
    }

    bankidForm() {
        return <div className="row">
            <div className='d-flex justify-content-start'><img src={`${Window.static_path}img/icons/BankID_logo_white.svg`} role="button" className="white" width="40px" height="40px"/></div>
            <p className='n-fs-5 fw-medium'>Legitimera med BankID</p>
            {this.state.qrCodeAvailable && (
                <div>
                    <p className='n-fs-6'>Skanna QR-koden med BankID-appen.</p>
                    <div className='d-flex justify-content-center'><div className="rounded rounded-5 bg-neutral-cloud d-flex justify-content-center" style={{width:"196px", height:"196px"}} ref={this.qrContainer} /></div> 
                </div>
            )}
                <div>{this.state.bankidContent && this.state.bankidContent}</div>
        </div>
    }
    
    loginForm() {   
        return <div>
            {/* is not bankid regg */}
            {this.state.successResp != 'created_temp_user' && <p className={`n-fs-3 fw-medium`}>Välkommen till Treebula</p>}
            {/* is bankid regg */}
            {this.state.successResp == 'created_temp_user' && <p className={`n-fs-3 fw-medium`}>Vi hittade inget befintligt konto med detta BankID kopplat hos oss</p>}

            <>
                {/* is not bankid regg */}
                {this.state.successResp != 'created_temp_user' && <p className="mb-4">Ange e-postadress eller mobilnummer</p>}
                {/* is bankid regg */}
                {this.state.successResp == 'created_temp_user' && <p className="mb-4">För att fortsätta logga in eller registrera dig fyll i fälten nedan</p>}

                <form onSubmit={(e) => { e.preventDefault();
                        //validate loginForm fields before proceeding
                        let {has_error,validation} = this.validateFormFields(this.state.formData)
                        if (has_error) {
                            this.setState({ errors: validation });
                        } else {
                            if(this.state.multiple_mobile_users){
                                this.setState({formStatus:'multiplePhonenumberUsers'})
                            }
                            else if (this.state.successResp == 'created_temp_user') {
                                {/* Since the user singed with bankid already we don't ask for connect, we just automatically connect */}
                                this.setState({ allowMatch: true }, () => {
                                    this.loginOrRegister()
                                })
                            } 
                            else {
                                this.loginOrRegister();
                            }
                        }
                    }} className={`${this.props.textColor}`}>
                    <VBInput
                        ref={this.emailorPhoneInput}
                        type='text'
                        initial={this.state.formData.email || this.state.formData.mobile_phone_number}
                        placeholder={"E-postadress / Mobilnummer"}
                        label={<label className='n-fs-7 mt-3 neutral-pebble'>E-postadress / Mobilnummer</label>}
                        error={this.state?.errors?.email_phone}
                        onFocus={() => {this.setState({errors:{...this.state.errors, email_phone:''}})}}
                        onChange={(val) => this.handleInputChange(val)}
                    />

                {this.state.errors?.resend_error && <p className='n-fs-7 mt-2 semantic-danger-500'>{this.state.errors?.resend_error}</p>}

                {this.state.isReg == false ? <>
                
                <div className='row'>
                    <div className='col-12 col-md-6'>
                        <VBDropdown
                            label={<label className='n-fs-7 mt-3 neutral-pebble'>Ange en roll.</label>}
                            placeholder="Roll"
                            options={Window.react_data.lists.roles}
                            autoClose={true}
                            error={this.state.errors.roles}
                            onFocus={() => this.setState({ errors: { ...this.state.errors, roles: '' } })}          
                            onChange={(val) => {this.setState({ formData: { ...this.state.formData, roles: val.value } });}}
                             />
                    </div>
                    <div className='col-12 col-md-6'>
                        <VBMultiOption
                            label={<label className='n-fs-7 mt-3 neutral-pebble'>{this.state.formData?.roles?.indexOf(2) != -1 ? 'Var äger du skog?' : 'Var är du verksam?'}</label>}
                            options={Window.react_data.lists.munis}
                            placeholder="Välj Kommun(er)"
                            autoClose={true}
                            error={this.state?.errors?.muni}
                            onFocus={() => this.setState({ errors: { ...this.state.errors, muni: '' } })}
                            onChange={(val) => { this.setState({ formData: { ...this.state.formData, muni: val.map(itm => parseInt(itm.value)) } }); }}/>
                    </div>
                    </div>

                    {!this.state.isEmail ? (
                        //if User input email on first screen, we ask phoneNum else if user input phoneNum we ask email
                        <VBInput  
                        ref={this.emailInput}
                        type="email"
                        initial={this.state.formData.email}
                        placeholder="E-postadress"
                        label={<label className='n-fs-7 mt-3 neutral-pebble'>E-postadress</label>}
                        error={this.state?.errors?.email}
                        onFocus={() => this.setState({ errors: { ...this.state.errors, email: '' } })}
                        onChange={(val) => { 
                            this.setState({ formData: { ...this.state.formData, email: val } }); 
                        }}/>
                    ):(
                        <VBPhoneInput
                            ref = {this.phoneInput}
                            inputClass='my-1'
                            placeholder="Mobilnummer"
                            label={<label className='n-fs-7 mt-3 neutral-pebble'>Mobilnummer</label>}
                            error={this.state.errors.mobile_phone_number}
                            onFocus={() => this.setState({ errors: { ...this.state.errors, mobile_phone_number: '' } })}
                            onChange={(val) => { 
                                this.setState({ formData: { ...this.state.formData, mobile_phone_number: val } }); 
                        }} />
                    )}

                    <div className='d-flex flex-column' style={{ marginTop: "24px" }}>
                        {this.state.errors?.checkbox &&
                            <p className='text-danger n-fs-7 mb-1'>{this.state.errors?.checkbox}</p>
                        }
                        <div className='d-flex align-items-center'>
                            <VBCheckbox
                                label=""
                                initial={false}  
                                onClick={() => { this.setState({ formData: { ...this.state.formData, checkbox: !this.state.formData.checkbox }, errors: { ...this.state.errors, checkbox: '' } })}} />

                            <p className='mb-0 n-fs-7'>Genom att klicka på någon av knapparna ovan godkänner du våra <a href="/terms/" className={`text-decoration-underline`} style={{cursor: "pointer"}}>användarvillkor</a> och <a href="/privacy/" className={`text-decoration-underline`} style={{cursor: "pointer"}}>integritetspolicy.</a> Jag förstår att jag skapar ett konto för att fortsätta.</p>
                        </div>
                    </div>

                </> : null}
                <div className='mt-5 d-flex'>
                <button className='n-btn btn-core w-100' style={{cursor: "pointer"}} type='submit'>Fortsätt<span><img className={`ms-2 neutral-charcoal-filter`} style={{ width: "18px", height: "18px" }} src={Window.static_path + "img/icons/arrow-black.svg"} /></span></button>
               </div>
                {this.props.formStatus == 'start' || typeof this.props.formStatus == 'undefined' && <button type='button' className='n-btn mt-2 btn-sm btn-ghost btn-charcoal w-100 col-4 ms-1' onClick={(e) => { this.setState({formStatus: 'start'}) }}>Tillbaka</button>}
            </form> </>
        </div>
    }

    magicLinkForm(){
        return (
            <div>
            <h3 className='mb-2 n-fs-5 n-md-fs-4'>Ange verifieringskod</h3>
            <p className='n-fs-6'>{this.props.fromUrl ? 'Vi har skickat verifieringskoden':'Vi har skickat en tillfällig kod till'} <span className='fw-medium'>{this.state.formData.email?this.state.formData.email:this.state.formData.mobile_phone_number?this.state.formData.mobile_phone_number:''}</span>.
            { typeof this.props.data == "undefined" &&  ( <> Om du vill ange en annan e-postadress eller telefonnummer, klicka på länken <a href="#" onClick={()=>{this.setState({formStatus:'login', formData:{}})}} className='fw-medium supp-pine text-decoration-underline' style={{cursor: "pointer"}}>Gå tillbaka</a></>)}</p>
            <p className='fw-medium n-fs-6 mb-2'>Ange kod</p>
            <div className='d-flex justify-content-start mb-4'>
                <InputContainer>
                    <Input ref={this.codeRef} type="text" value={this.state.code.join('')} maxLength={4} 
                    onChange={(e)=>{
                        const newCode = e.target.value.replace(/[a-z]/g, letter => letter.toUpperCase()).slice(0, 4); 
                        const codeError = newCode.length < 4 ? 'Vänligen ange 4 siffror' : null;
                        const caretPosition = newCode.length;
                    
                        this.setState({ code: newCode.split(''), codeError, caretPosition }, () => {
                            if(newCode.length === 4){
                                this.validateCode()
                            }
                            if (this.codeRef && this.codeRef.current) {
                            this.codeRef.current.focus();
                            }
                            
                        });
                    }}
                    onFocus={(e)=>{ e.target.select();}}/>
                                    
                    {this.state.code.map((digit, index) => (
                    <Box>{digit}</Box>
                    ))}
                    {new Array(4-this.state.code.length).fill("").map((val,index) => 
                        <Box>{index === 0 && <Caret/>}</Box>
                    )}
                   
                </InputContainer>
            </div>
            {this.state.codeError && <p className='text-danger n-fs-7 d-flex justify-content-start mt-1 mb-2'>{this.state.codeError}</p>}
            <button className='n-btn btn-charcoal btn-sm mb-4' onClick={()=>{ this.loginOrRegister() }}
            ><span><img src={`${Window.static_path}img/icons/refresh.svg`} className="me-2 primary-core-filter" style={{ width: '18px', height: '18px' }}/> </span>Skicka koden igen</button>
            {this.state.errors?.resend_error && <p className='n-fs-7 mt-2 semantic-danger-500'>{this.state.errors?.resend_error}</p>}
                {this.state.formData.email ? (
                    <p className='n-fs-7 bg-neutral-cloud p-2 rounded-2'>
                        Om du inte ser vår e-post i din inkorg, kolla din skräppostmapp.
                    </p>
                ) : this.state.formData.mobile_phone_number ? (
                    <p className='n-fs-7 bg-neutral-cloud p-2 rounded-2'>Vi har skickat ett sms till det nummer du angett ovan</p>
                ) : null}
            </div>
        )    
    }

    newUserMatchForm(){
        return <div>
                <p className={`n-fs-3 fw-medium`}>Vill du koppla ditt BankID till det registrerade kontot?</p>
                {/* Do you want to connect your BankID to the registered account? */}
                <div className='row'>
                    {/* match email & ssn  */}
                    <button type='button' className='n-btn btn-core w-100 col-4 me-1' onClick={(e) => {
                        this.setState({ allowMatch: true }, () => {
                            this.loginOrRegister()
                        })
                    }}>Ja</button>
                    <button type='button' className='n-btn mt-2 btn-sm btn-ghost btn-charcoal w-100 col-4 ms-1' onClick={(e) => {
                        this.setState({ allowMatch: false }, () => {
                            this.loginOrRegister()
                        })
                    }}>Nej</button>
                </div>
                </div>
    }

    requestConnectBankIDForm(){
        let title = this.props.title
        let subText = this.props.subText

        title="Välkommen tillbaka!"
        subText="Koppla ditt befintliga konto med Mobilt BankID för en tryggare upplevelse."

        return <div className='d-flex flex-column'>
            <p className={`n-fs-3 fw-medium`}>{title}</p>
            {subText && <p className="mb-4">{subText}</p>}
            <a href="#" className='n-btn btn-core w-100' style={{cursor: "pointer"}} onClick={(e) => { 
                e.preventDefault(); 
                this.setState({ formStatus: 'bankid' }, 
                () => this.loginBankID()); 
            }}> 
                <img src={`${Window.static_path}img/icons/BankID_logo_white.svg`} role="button" className="white" width="40px" height="40px" /> Koppla med Mobilt BankID
            </a>

            <button type='button' className='n-btn mt-2 btn-sm btn-ghost btn-charcoal w-100' onClick={() => {
                this.completeLoginProcess(this.state.loginSetUrl);
                }}>Hoppa över</button>          
        </div>
    }

    multiplePhonenumberUsersForm(){
        return <div>
            <p>Du kan för tillfället inte logga in via SMS. Använd din e-post för att logga in istället, och kontakta gärna supporten om problemet så kan vi se till att det inte händer igen. </p>
            <button type='button' className='n-btn btn-core w-100 col-4 me-1' onClick={(e) => {
                if (this.props.completed && this.props.formStatus) {
                    this.completeLoginProcess()
                } else { this.setState({ formStatus: 'login' }) }
            }}>Gå tillbaka</button>
        </div>
    }


    render() {
        return <div className={`${this.state.formStatus == 'none'?'d-none':''}`}>
            <VBLoadingScreen show_loading={this.state.loading} />
            <div className={`bg-${this.props.backgroundColor} ${this.props.textColor} w-100 p-4`} style={{ borderRadius: "40px" }}>
                
                {this.state.formStatus == 'start' && this.startForm()}

                {this.state.formStatus == 'bankid' && this.bankidForm()}

                {this.state.formStatus == 'login' && this.loginForm()}

                {this.state.formStatus == 'requestForm' && this.requestConnectBankIDForm()} 

                {this.state.formStatus == 'magicLink' && this.magicLinkForm()}

                {this.state.formStatus == 'newUserMatch' && this.newUserMatchForm()}

                {this.state.formStatus == 'multiplePhonenumberUsers' && this.multiplePhonenumberUsersForm()}

                <div>
             <ErrorModal show={this.state.errorModal} onHide={() => this.setState({ errorModal: false })} contentClassName="bg-neutral-cloud-200 p-2" centered>
                <Modal.Body>
                    <h2 className="n-fs-4 neutral-charcoal">Oops! Något gick fel.</h2>
                    <p className='n-fs-5 neutral-charcoal py-3'>Bank-ID tillhör ett annat konto, kontakta oss för hjälp. Ring <a href="tel:08339944" className="fw-bold neutral-charcoal" style={{cursor: "pointer"}}>08-33 99 44</a> så hjälper vi dig vidare.</p>
                    <button type="button" className="n-btn float-end" style={{ background: "#FF5B6C", border: "None" }} onClick={() => {
                        if(this.props.onError){
                            this.props.onError();
                        }
                        this.setState({ errorModal: false })
                        }}>Fortsätt</button>
                </Modal.Body>
            </ErrorModal>

            <BankConnectedToAnotherAccountModal show={this.state.bankConnectedModal} onHide={() => this.setState({ bankConnectedModal: false })} contentClassName="bg-neutral-cloud-200 p-2" centered backdrop = "static">
                <Modal.Body>
                    <h2 className="n-fs-4 neutral-charcoal">Oops! Något gick fel.</h2>
                    <p className='n-fs-5 neutral-charcoal py-3 text-break'>{this.state.formData?.email? `E-postadressen som används är ${this.state.formData.email}`:`E-postadressen som används stämmer inte överens med den som är kopplad till BankID`}, men BankID är kopplat till ett annat e-postkonto {this.state.emailResp}. Kontakta oss för hjälp. Ring <a href="tel:08339944" className="fw-bold neutral-charcoal" style={{cursor: "pointer"}}>08-33 99 44</a> så hjälper vi dig vidare.</p>
                    <a href='#' className="n-btn float-end" style={{ background: "#FF5B6C", border: "None", cursor: "pointer" }} onClick={(e) => { e.preventDefault();
                        // If user had already logged in no need to log the bank id account that was connected
                        if(this.state.isLogged){
                            this.setState({formStatus:"requestForm", loading:false, codeError:null,bankConnectedModal:false})                  
                        }
                        else{
                            // Since a different user connects to bank id from the one who put in the email we will use onBankIDConnect incase there is a different process eg in saljavirke the ad_id cookie should update to the bank id user.
                            if(this.props.onBankIDConnect){
                                this.props.onBankIDConnect();
                            }else{
                                this.completeLoginProcess()
                            }
                            this.setState({bankConnectedModal:false})}
                        }}>Fortsätt</a>
                </Modal.Body>
            </BankConnectedToAnotherAccountModal>

            
        </div>            
                  
        
            </div>
        </div>
    }
}

LoginForm.defaultProps = {
    title: null,
    titleLogged: null,
    subText: null,
    subTextLogged: null,
    textColor: "neutral-charcoal",
    backgroundColor: "white",
    action: { actionLocation: null}
}

LoginForm.fields = {
    title: {
        type: "text",
    },
    titleLogged: {
        type: "text",
    },
    subText: {
        type: "text",
    },
    subTextLogged: {
        type: "text",
    },
    backgroundColor: {
        type: "custom",
        render: (props) => <ColorSelector {...props} />
    },
    textColor: {
        type: "select",
        options: Opt.colorVariables
    },
    action: {
        type: "object",
        objectFields: {
            actionLocation: { type: "text" },
        },
      },
}

export default LoginForm;