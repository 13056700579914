import React from "react";
import styled from "styled-components";
import VBInput from "@virkesborsen/vb-js-commons/lib/components/new/vbInput";
import { validateEmail } from "../../../api";
import StepButton from "./StepButton";

const EmailStep = ({
  onNext,
  stepNumber,
  initialEmail = "",
  onEmailChange,
  error,
  emailInputRef,
}) => {
  const handleChange = (val) => {
    const trimmedValue = val.trim();
    onEmailChange({
      target: {
        name: "email",
        value: trimmedValue,
        type: "email",
      },
    });
  };

  const handleBlur = () => {
    const trimmedEmail = initialEmail.trim();
    if (!validateEmail(trimmedEmail)) {
      onEmailChange({
        target: {
          name: "email",
          value: trimmedEmail,
          error: "Den här e-postadressen ser inte ut att vara korrekt",
        },
      });
    }
  };

  return (
    <StepContainer>
      <ContentWrapper>
        <StepNumberWrapper>
          <span>{stepNumber}</span>
          <StepArrow
            width="16px"
            height="16px"
            src={Window.static_path + "img/icons/arrow.svg"}
            alt="Step indicator"
          />
        </StepNumberWrapper>
        <MainContent>
          <Title>Ange din e-postadress*</Title>
          <Subtitle>Ange den e-postadress vi kan nå dig på</Subtitle>
          <InputWrapper>
            <VBInput
              ref={emailInputRef}
              inputStyle={{ minHeight: 0 }}
              inputClass="border-neutral-cloud"
              type="email"
              name="email"
              value={initialEmail}
              initial={initialEmail}
              placeholder="namn@mail.se"
              onChange={handleChange}
              error={error}
              onBlur={handleBlur}
              onFocus={() => {
                onEmailChange({
                  target: {
                    name: "email",
                    value: initialEmail,
                    error: "",
                  },
                });
              }}
            />
          </InputWrapper>
          <StepButton
            onClick={onNext}
            disabled={!validateEmail(initialEmail.trim())}
          />
        </MainContent>
      </ContentWrapper>
    </StepContainer>
  );
};

const StepContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0;
  flex-grow: 1;
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
`;

const StepNumberWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  gap: 8px;

  @media (max-width: 576px) {
    display: none;
  }
`;

const StepArrow = styled.img``;

const MainContent = styled.div`
  flex-grow: 1;
`;

const Title = styled.h2`
  font-size: 24px;
  margin-bottom: 0.5rem;
`;

const Subtitle = styled.p`
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.8);
  opacity: 0.75;
  margin-bottom: 1.5rem;

  @media (max-width: 576px) {
    font-size: 16px;
    margin-bottom: 1rem;
  }
`;

const InputWrapper = styled.div`
  margin-bottom: 1.5rem;
`;

export default EmailStep;
