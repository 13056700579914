import React from "react";
import styled from "styled-components";
import VBPhoneInput from "@virkesborsen/vb-js-commons/lib/components/new/vbPhoneInput";
import { isValidPhoneNumber } from "react-phone-number-input";
import StepButton from "./StepButton";

const MobileStep = ({
  onNext,
  stepNumber,
  initialMobile = "",
  onMobileChange,
  error,
  mobileInputRef,
}) => {
  const handleChange = (val) => {
    onMobileChange({
      target: {
        name: "mobile",
        value: val,
        type: "text",
      },
    });
  };

  const handleBlur = () => {
    if (!isValidPhoneNumber(initialMobile)) {
      onMobileChange({
        target: {
          name: "mobile",
          value: initialMobile,
          error: "Ange ett giltigt mobilnummer",
        },
      });
    }
  };

  return (
    <StepContainer>
      <ContentWrapper>
        <StepNumberWrapper>
          <span>{stepNumber}</span>
          <StepArrow
            width="16px"
            height="16px"
            src={Window.static_path + "img/icons/arrow.svg"}
            alt="Step indicator"
          />
        </StepNumberWrapper>
        <MainContent>
          <Title>Ditt mobilnummer*</Title>
          <Subtitle>
            Vi använder numret för att kontakta dig om din skogsfastighet
          </Subtitle>
          <InputWrapper>
            <VBPhoneInput
              ref={mobileInputRef}
              name="mobile"
              value={initialMobile}
              initial={initialMobile}
              placeholder="07X XXX XX XX"
              onChange={handleChange}
              variant="light"
              error={error}
              onBlur={handleBlur}
            />
          </InputWrapper>
          <StepButton
            onClick={onNext}
            disabled={!isValidPhoneNumber(initialMobile)}
          />
        </MainContent>
      </ContentWrapper>
    </StepContainer>
  );
};

const StepContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0;
  flex-grow: 1;
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
`;

const StepNumberWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  gap: 8px;

  @media (max-width: 576px) {
    display: none;
  }
`;

const StepArrow = styled.img``;

const MainContent = styled.div`
  flex-grow: 1;
`;

const Title = styled.h2`
  font-size: 24px;
  margin-bottom: 0.5rem;
`;

const Subtitle = styled.p`
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.8);
  opacity: 0.75;
  margin-bottom: 1.5rem;

  @media (max-width: 576px) {
    font-size: 16px;
    margin-bottom: 1rem;
  }
`;

const InputWrapper = styled.div`
  margin-bottom: 1.5rem;
`;

export default MobileStep;
