import React from "react";
import styled from "styled-components";
import VBMultiOption from "@virkesborsen/vb-js-commons/lib/components/new/vbMultiOption";
import StepButton from "./StepButton";

const MunicipalityStep = ({
  onNext,
  stepNumber,
  initialMunicipality = "",
  onMunicipalityChange,
  error,
  municipalityInputRef,
}) => {
  const handleChange = (val) => {
    onMunicipalityChange({
      target: {
        name: "municipalities",
        value: val.map((item) => item),
      },
    });
  };

  const validateField = (value) => {
    return value && Array.isArray(value) && value.length > 0;
  };

  return (
    <StepContainer>
      <ContentWrapper>
        <StepNumberWrapper>
          <span>{stepNumber}</span>
          <StepArrow
            width="16px"
            height="16px"
            src={Window.static_path + "img/icons/arrow.svg"}
            alt="Step indicator"
          />
        </StepNumberWrapper>
        <MainContent>
          <Title>Var finns din skog?*</Title>
          <Subtitle>Ange en eller flera kommuner där du har skogsmark</Subtitle>
          <InputWrapper>
            <VBMultiOption
              options={Window.react_data.lists.munis}
              initial={initialMunicipality}
              error={error}
              onChange={handleChange}
              placeholder="Sök eller bläddra bland kommuner..."
            />
          </InputWrapper>
          <StepButton
            onClick={onNext}
            disabled={!validateField(initialMunicipality)}
          />
        </MainContent>
      </ContentWrapper>
    </StepContainer>
  );
};

const StepContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0;
  flex-grow: 1;
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
`;

const StepNumberWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  gap: 8px;

  @media (max-width: 576px) {
    display: none;
  }
`;

const StepArrow = styled.img``;

const MainContent = styled.div`
  flex-grow: 1;
`;

const Title = styled.h2`
  font-size: 24px;
  margin-bottom: 0.5rem;
`;

const Subtitle = styled.p`
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.8);
  opacity: 0.75;
  margin-bottom: 1.5rem;

  @media (max-width: 576px) {
    font-size: 16px;
    margin-bottom: 1rem;
  }
`;

const InputWrapper = styled.div`
  margin-bottom: 1.5rem;
`;

export default MunicipalityStep;
