import React from 'react';
import Navigation from '../../components/static/Navigation';
import Footer from '../../components/static/Footer';

import VBDropdown from "@virkesborsen/vb-js-commons/lib/components/new/vbDropdown";
import VBInput from "@virkesborsen/vb-js-commons/lib/components/new/vbInput";
import VBCheckbox from "@virkesborsen/vb-js-commons/lib/components/new/vbCheckbox";

import NHTabs from './components/nhTabs';
import NHBox from './components/nhBox';
import NHDivider from './components/nhDivider';
import NHInfo from './components/nhInfo';
import NHPill from './components/nhPill';
import NHForestSection from './components/nhForestSection';
import NHEdit from './components/nhEdit';
import NHAdd from './components/nhAdd';
import NHDelete from './components/nhDelete';
import NHTextCapper from './components/nhTextCapper'

import NHLeaflet from './components/nhLeaflet';
import data_map from './api';
import { Modal } from "react-bootstrap";
import Cookies from 'js-cookie';

import txt from './components/txt';
import TxtTranslateModal from './components/txtTranslateModal';

import NHLeafletChange from './components/nhLeafletChange';
import Offcanvas from 'react-bootstrap/Offcanvas';
import VbTextarea from '@virkesborsen/vb-js-commons/lib/components/new/vbTextarea';
import { Truck } from 'react-bootstrap-icons';

class NHDetails extends React.Component {
    constructor() {
        super();
        this.navigation = React.createRef();

        window.addEventListener("resize", () => {this.setState({trigger:"value"})});

        this.changeMap = React.createRef()

        this.refSections = {}

        Window.react_data.sections.map((itm) => {
            this.refSections[itm.id] = React.createRef()
        })



        this.state={
            activeTab:"overblick",
            crudOffCanvas: null,
            show_image_modal: false,
            selected_changes:[],
            subEmail: "",
            subTermsCheck: false,
            subMessage: ""
        }
    }

    getAttachmentBox(){
        if(Window.react_data.attachments.length  == 0 && !Window.react_data.is_editor){
            return null
        }
        return <NHBox headline={txt({global:true, text:"Bilagor"})} hidden={false}>
            {Window.react_data.attachments.map((attch, i) => {
                return <>
                <a href={attch.file} download={attch.name}>
                    <div onClick={() => {}} style={{"cursor":"pointer"}} className="d-flex hover-bg-neutral-cloud-300 p-1 corners-1-all justify-content-between align-items-center">
                        <div>
                            <p className="n-fs-7 mb-1 neutral-charcoal-400">{attch.created_at_formats.iso}</p>
                            <p className="n-fs-7 fw-medium mb-0">{attch.name}</p>
                        </div>
                        <div>
                            <img src={`${Window.static_path}img/icons/arrow_down.svg`} style={{width:"12px", height:"12px"}} />
                            {Window.react_data.is_editor ? <span className="ps-2 n-fs-6 semantic-danger-500" onClick={(e) => {e.preventDefault(); this.handleDelete("attachment", attch.id)}}>✖</span> : null}
                        </div>
                    </div>
                    
                </a>
            
            
            {Window.react_data.attachments.length -1 != i && <NHDivider extra_class="mt-1 mb-2" />}
            </>
            })}
            <NHAdd className="pt-2 pb-2" onClick={() => {this.generateCreateForm("attachment", {}, {"project_id": Window.react_data.id})}} />
    
    </NHBox>
    }

    getOtherInfoBox(){
        return <NHBox 
        headline={txt({global:true, text:"Övrig information"})}
        hidden={this.state.activeTab != "overblick"}
        can_toggle={true}
        >
            <div className="row mb-2">
                <div className="col-3">
                    <p className="n-fs-7 neutral-charcoal-400 mb-1">{txt({global:true, text:"Plats"})}</p>
                    <p className="n-fs-6 mb-0">{Window.react_data.muni}, {txt({global:true, text:Window.react_data.country})}</p>
                </div>
                {Window.react_data.start_time_formats && <div className="col-3">
                    <p className="n-fs-7 neutral-charcoal-400 mb-1">{txt({global:true, text:"Utgivningsdatum"})}</p>
                    <p className="n-fs-6 mb-0">{Window.react_data.start_time_formats.pretty}</p>
                </div>}
                {Window.react_data.start_time_formats && <div className="col-3">
                    <p className="n-fs-7 neutral-charcoal-400 mb-1">{txt({global:true, text:"Grundat"})}</p>
                    <p className="n-fs-6 mb-0">{Window.react_data.start_time_formats.year}</p>
                </div>}
            </div>
            <p className="n-fs-7 mb-2" style={{whiteSpace: "pre-wrap"}}>
                <NHTextCapper max_length={512} text={txt({location_id:"project_other_info", context_clue:"Other information about a biodiversity project", text:Window.react_data.other_info})} />
            </p>
            {Window.react_data.other_info_link && <a href={Window.react_data.other_info_link} className="n-fs-7 supp-plum">{txt({global:true, text:"Läs mer"})}</a>}

            {this.getImages()}
            <NHAdd className="position-absolute end-0 bottom-0 pb-4 pe-3" onClick={() => {this.generateCreateForm("image_file", {}, {"project_id": Window.react_data.id})}} />
        </NHBox>
    }

    getDetailsBox(){
        if(Window.react_data.details.length  == 0 && !Window.react_data.is_editor){
            return null
        }
        return <NHBox 
        hidden={this.state.activeTab != "overblick"}
        headline={txt({global:true, text:"Detaljer"})}
        can_toggle={true}
        >
            {Window.react_data.details.sort((a,b) => a.order - b.order).map((detail, i) => {
                return <>
                    <div className="position-relative">
                        <NHEdit onClick={() => {this.generateUpdateForm("detail", detail.id, detail, {"project_id": Window.react_data.id})}} className="position-absolute end-0 pe-1" />
                        <p className="n-fs-6 mb-1">{txt({location_id:`ProjectDetail_title_${detail.id}`, text:detail.title})}</p>
                        <p className="n-fs-7 m-0" style={{whiteSpace: "pre-line"}}>
                            <NHTextCapper max_length={512} text={txt({location_id:`ProjectDetail_description_${detail.id}`, text:detail.description})} />
                        </p>
                        {detail.link && <a href={detail.link} className="n-fs-7 supp-plum d-flex align-items-center"><span>{txt({global:true, text:"Läs mer"})}</span> <img src={`${Window.static_path}img/icons/arrow_up_right.svg`} /></a>}
                    </div>
                    {Window.react_data.details.length -1 != i && <NHDivider extra_class="my-2" />}
                    </>
            })}
            <NHAdd onClick={() => {this.generateCreateForm("detail", {}, {"project_id": Window.react_data.id})}} />
        </NHBox>
    }

    get_left_side(){
        if(!Window.react_data.forest.left_side_headline){
            return null;
        }
        return <>
        <div className="position-relative d-flex justify-content-between" style={{height:"26px"}}>
            <div className="w-100 bg-neutral-cloud-300 position-absolute" style={{height:"1px", bottom:"4px"}}></div>
            <p className="bg-neutral-cloud-100 position-absolute px-1 mb-0 n-fs-7 bottom-0">{txt({location_id:"project_details_left_side", text:Window.react_data.forest.left_side_headline})}</p>
        </div>
        {Window.react_data.forest.left_side && Window.react_data.forest.left_side.split("\n").map((line) => {
            let title = line.split(":")[0].trim()
            let text = line.split(":")[1].trim()
            return <div className="position-relative d-flex justify-content-between" style={{height:"26px"}}>
                <div className="w-100 bg-neutral-cloud-300 position-absolute" style={{height:"1px", bottom:"4px"}}></div>
                <p className="bg-neutral-cloud-100 position-absolute px-1 ps-3 mb-0 n-fs-7 bottom-0">{txt({location_id:`project_details_left_side_line_title_${title}`, text:title})}</p>
                <p className="bg-neutral-cloud-100 position-absolute px-1 mb-0 n-fs-7 bottom-0 end-0 d-flex align-items-center">{txt({location_id:`project_details_left_side_line_text_${text}`, text:text})}</p>
            </div>
        })}
        </>
    }

    get_right_side(){
        if(!Window.react_data.forest.right_side_headline){
            return null;
        }
        return <>
        <div className="position-relative d-flex justify-content-between" style={{height:"26px"}}>
            <div className="w-100 bg-neutral-cloud-300 position-absolute" style={{height:"1px", bottom:"4px"}}></div>
            <p className="bg-neutral-cloud-100 position-absolute px-1 mb-0 n-fs-7 bottom-0">{txt({location_id:"project_details_right_side", text:Window.react_data.forest.right_side_headline})}</p>
        </div>
        {Window.react_data.forest.right_side && Window.react_data.forest.right_side.split("\n").map((line) => {
            let title = line.split(":")[0].trim()
            let text = line.split(":")[1].trim()
            return <div className="position-relative d-flex justify-content-between" style={{height:"26px"}}>
                <div className="w-100 bg-neutral-cloud-300 position-absolute" style={{height:"1px", bottom:"4px"}}></div>
                <p className="bg-neutral-cloud-100 position-absolute px-1 ps-3 mb-0 n-fs-7 bottom-0">{txt({location_id:`project_details_right_side_line_title_${title}`, text:title})}</p>
                <p className="bg-neutral-cloud-100 position-absolute px-1 mb-0 n-fs-7 bottom-0 end-0 d-flex align-items-center">{txt({location_id:`project_details_right_side_line_text_${text}`, text:text})}</p>
            </div>
        })}
        </>
    }

    getAboutBox(){
        return <NHBox  headline={txt({global:true, location_id:"title_about_biodiversity_project", text:"Om projektet"})} hidden={this.state.activeTab != "overblick"}>
        <p className="n-fs-6" style={{whiteSpace: "pre-wrap"}}><NHTextCapper max_length={512} text={txt({location_id:"project_nature_description", context_clue:"A short summary of a biodiversity project", text:Window.react_data.nature_description})} /></p>
        <NHDivider />
        <div className="mt-3">
            <div className="row">
                <div className="col-lg-6 col-12">
                    <div className="position-relative d-flex justify-content-between" style={{height:"26px"}}>
                        <div className="w-100 bg-neutral-cloud-300 position-absolute" style={{height:"1px", bottom:"4px"}}></div>
                        <p className="bg-neutral-cloud-100 position-absolute px-1 mb-0 n-fs-7 bottom-0">{txt({global:true, text:"Kommun"})}</p>
                        <p className="bg-neutral-cloud-100 position-absolute px-1 mb-0 n-fs-7 bottom-0 end-0">{Window.react_data.muni}</p>
                    </div>
                    <div className="position-relative d-flex justify-content-between" style={{height:"26px"}}>
                        <div className="w-100 bg-neutral-cloud-300 position-absolute" style={{height:"1px", bottom:"4px"}}></div>
                        <p className="bg-neutral-cloud-100 position-absolute px-1 mb-0 n-fs-7 bottom-0">{txt({global:true, text:"Län"})}</p>
                        <p className="bg-neutral-cloud-100 position-absolute px-1 mb-0 n-fs-7 bottom-0 end-0">{Window.react_data.region}</p>
                    </div>
                    {Window.react_data.owner_count != 0 && <div className="position-relative d-flex justify-content-between" style={{height:"26px"}}>
                        <div className="w-100 bg-neutral-cloud-300 position-absolute" style={{height:"1px", bottom:"4px"}}></div>
                        <p className="bg-neutral-cloud-100 position-absolute px-1 mb-0 n-fs-7 bottom-0">{txt({global:true, text:"Antal Ägare"})}</p>
                        <p className="bg-neutral-cloud-100 position-absolute px-1 mb-0 n-fs-7 bottom-0 end-0">{Window.react_data.owner_count}</p>
                    </div>}
                    {Window.react_data.forest ? 
                    this.get_left_side()
                    :null}
                </div>
                <div className="col-lg-6 col-12">
                    {Window.react_data.forest ? this.get_right_side() :null}
                    {Window.react_data.certified && <div className="position-relative d-flex justify-content-between" style={{height:"26px"}}>
                        <div className="w-100 bg-neutral-cloud-300 position-absolute" style={{height:"1px", bottom:"4px"}}></div>
                        <p className="bg-neutral-cloud-100 position-absolute px-1 mb-0 n-fs-7 bottom-0">{txt({global:true, text:"Certifiering"})}</p>
                        <p className="bg-neutral-cloud-100 position-absolute px-1 mb-0 n-fs-7 bottom-0 end-0 d-flex align-items-center">{txt({global:true, text:"Ja"})}</p>
                    </div>}
                </div>
            </div>
        </div>
    </NHBox>
    }

    getSectionsBox(){
        if(Window.react_data.sections.length  == 0 && !Window.react_data.is_editor){
            return null
        }
        return <NHBox 
        headline={txt({global:true, location_id:"biodiversity_project_sections_title", context_clue:"biodiversity project forest sections", text:"Avdelningar"})}
        hidden={this.state.activeTab != "overblick"}
        can_toggle={true}
        >
            {Window.react_data.forest ? <NHEdit 
            className="py-2" 
            onClick={() => {this.generateUpdateForm("forest", Window.react_data.forest.id, Window.react_data.forest)}} /> :
            <NHAdd 
            className="py-2" 
            onClick={() => {this.generateCreateForm("forest", {}, {"project_id": Window.react_data.id})}} />
            }
            {Window.react_data.sections.sort((a,b) => a.name.localeCompare(b.name)).map((sect) => {
                return <NHForestSection ref={this.refSections[sect.id]} deleteImage={(img_id)=>{this.handleDelete("image_file", img_id)}} addImage={() => {this.generateCreateForm("image_file", {}, {"forest_section_id": sect.id})}} edit={() => {this.generateUpdateForm("forest_section", sect.id, sect)}} data={sect} />;
            })}
            {Window.react_data.forest ? <NHAdd 
            className="py-2" 
            onClick={() => {this.generateCreateForm("forest_section", {}, {"forest_id": Window.react_data.forest.id})}} /> : null}
        </NHBox>
    }

    getUpdateBox(){
        if(Window.react_data.updates.length  == 0 && !Window.react_data.is_editor){
            return null
        }
        return <NHBox 
            headline={txt({global:true, location_id:"biodiv_updates", text:"Uppdateringar"})}
            hidden={this.state.activeTab != "overblick" && this.state.activeTab != "aktivitet"}
            >
                {Window.react_data.updates.sort((a,b) => Number(b.date_formats.timestamp) - Number(a.date_formats.timestamp)).map((itm) => {
                    return <div className="d-flex w-100 position-relative">
                        <NHDelete 
                        className="position-absolute end-0 top-0 pt-3 pe-3" 
                        onClick={() => {this.handleDelete("update", itm.id)}} />
                    <div className="position-relative" style={{paddingLeft:"56px"}}>
                        <div className="h-100 bg-neutral-cloud-300 position-absolute" style={{width:"2px", left:"19px"}}></div>
                        <div className="bg-neutral-cloud-300 corners-2-all position-absolute start-0 d-flex flex-wrap align-items-center" style={{width:"40px", height:"40px"}}>
                            <p className="text-center fw-bold n-fs-8 mb-0 w-100">{itm.date_formats.day}<br/><span className="fw-medium">{txt({global:true, context_clue:"Short format month name", text:itm.date_formats.month_name_short.toUpperCase()})}</span></p>
                        </div>
                        <p className="fw-medium n-fs-7 mb-1">{txt({location_id:`ProjectUpdate_title_${itm.id}`, context_clue:"The title of a biodiversity project", text:itm.title})} - {itm.written_by.public_name}</p>
                        <ul className="mb-0 n-fs-7 ps-4 pb-2">
                            {itm.items.map((itm2) => {
                                return <li>{txt({location_id:`ProjectUpdateSingle_description_${itm2.id}`, context_clue:"One description line of a bigger project updates segment", text:itm2.description})}</li>
                            })}
                        </ul>
                    </div>
                </div>
                })}
                <NHAdd onClick={() => {this.generateCreateForm("update", {}, {"project_id": Window.react_data.id, "written_by_id": Window.react_data.editor_id})}} />
        </NHBox>
    }

    getTimelineBox(){
        return <NHBox bg="bg-neutral-stone-100" hidden={this.state.activeTab != "tidslinje"}>
        <div className="d-flex mb-3">
            <div className="d-flex align-items-center me-4">
                <div className="p-2 bg-neutral-stone-300 me-2 corners-1-all">
                    <img style={{width:"20px", height:"20px"}} src="https://placehold.co/20x20" />
                </div>
                <div>
                    <p className="n-fs-6 fw-medium mb-0">Sep 14, 2024</p>
                    <p className="n-fs-7 neutral-charcoal-400 mb-0">Startar</p>
                </div>
            </div>
            <div className="d-flex align-items-center me-4">
                <div className="p-2 bg-neutral-stone-300 me-2 corners-1-all">
                    <img style={{width:"20px", height:"20px"}} src="https://placehold.co/20x20" />
                </div>
                <div>
                    <p className="n-fs-6 fw-medium mb-0">20 år</p>
                    <p className="n-fs-7 neutral-charcoal-400 mb-0">Förväntad tidsperiod</p>
                </div>
            </div>
        </div>
        <NHDivider />
        <div className="mt-3 px-2">
            <div className="position-relative" style={{paddingLeft:"35px"}}>
                {/* only visual */}
                <div className="position-absolute h-100 bg-supp-pine" style={{width:"2px", left:"14px"}}></div>
                <div className="d-flex align-items-center justify-content-center position-absolute bg-supp-pine rounded-circle top-0" style={{width:"16px", height:"16px", left:"7px"}}>
                    <img src="https://placehold.co/8x8" style={{width:"8px", height:"8px"}} />
                </div>

                {/* content */}
                <p className="n-fs-7 neutral-charcoal-400 mb-0">14 Apr. 2024</p>
                <p className="n-fs-6 fw-medium mb-2">Data uploaded</p>
                <p className="n-fs-7 fw-medium mb-0">Subtitle</p>
                <p className="n-fs-7 mb-0 pb-2">Amet magna nulla a semper. Lacus nisi molestie sed lobortis enim tempor sit. Arcu morbi faucibus nec malesuada et aliquam ac blandit. Nam sed magna urna massa tortor felis eu pellentesque augue.</p>
            </div>
            <div className="position-relative" style={{paddingLeft:"35px"}}>
                {/* only visual */}
                <div className="position-absolute h-100 bg-supp-pine" style={{width:"2px", left:"14px"}}></div>
                <div className="d-flex align-items-center justify-content-center position-absolute bg-supp-pine rounded-circle top-0" style={{width:"16px", height:"16px", left:"7px"}}>
                    <img src="https://placehold.co/8x8" style={{width:"8px", height:"8px"}} />
                </div>

                {/* content */}
                <p className="n-fs-7 neutral-charcoal-400 mb-0">14 Apr. 2024</p>
                <p className="n-fs-6 fw-medium mb-2">Data uploaded</p>
                <p className="n-fs-7 fw-medium mb-0">Subtitle</p>
                <p className="n-fs-7 mb-0 pb-2">Amet magna nulla a semper. Lacus nisi molestie sed lobortis enim tempor sit. Arcu morbi faucibus nec malesuada et aliquam ac blandit. Nam sed magna urna massa tortor felis eu pellentesque augue.</p>
            </div>
            <div className="position-relative" style={{paddingLeft:"35px"}}>
                {/* only visual */}
                <div className="d-flex align-items-center justify-content-center position-absolute bg-supp-pine rounded-circle top-0" style={{width:"24px", height:"24px", left:"3px"}}>
                    <img src="https://placehold.co/16x16" style={{width:"16px", height:"16px"}} />
                </div>

                {/* content */}
                <p className="n-fs-7 neutral-charcoal-400 mb-0">14 Apr. 2024</p>
                <p className="n-fs-6 fw-medium mb-2">Data uploaded</p>
            </div>
        </div>
        <div className="mt-3 px-2">
            <div className="position-relative" style={{paddingLeft:"35px"}}>
                {/* only visual */}
                <div className="position-absolute h-100 bg-supp-pine" style={{width:"2px", left:"14px"}}></div>
                <div className="d-flex align-items-center justify-content-center position-absolute bg-supp-pine rounded-circle top-0" style={{width:"16px", height:"16px", left:"7px"}}>
                    <img src="https://placehold.co/8x8" style={{width:"8px", height:"8px"}} />
                </div>

                {/* content */}
                <p className="n-fs-7 neutral-charcoal-400 mb-0">14 Apr. 2024</p>
                <p className="n-fs-6 fw-medium mb-2">Data uploaded</p>
                <p className="n-fs-7 fw-medium mb-0">Subtitle</p>
                <p className="n-fs-7 mb-0 pb-2">Amet magna nulla a semper. Lacus nisi molestie sed lobortis enim tempor sit. Arcu morbi faucibus nec malesuada et aliquam ac blandit. Nam sed magna urna massa tortor felis eu pellentesque augue.</p>
            </div>
            <div className="position-relative" style={{paddingLeft:"35px"}}>
                {/* only visual */}
                <div className="position-absolute h-100 bg-supp-pine" style={{width:"2px", left:"14px"}}></div>
                <div className="d-flex align-items-center justify-content-center position-absolute bg-supp-pine rounded-circle top-0" style={{width:"16px", height:"16px", left:"7px"}}>
                    <img src="https://placehold.co/8x8" style={{width:"8px", height:"8px"}} />
                </div>

                {/* content */}
                <p className="n-fs-7 neutral-charcoal-400 mb-0">14 Apr. 2024</p>
                <p className="n-fs-6 fw-medium mb-2">Data uploaded</p>
                <p className="n-fs-7 fw-medium mb-0">Subtitle</p>
                <p className="n-fs-7 mb-0 pb-2">Amet magna nulla a semper. Lacus nisi molestie sed lobortis enim tempor sit. Arcu morbi faucibus nec malesuada et aliquam ac blandit. Nam sed magna urna massa tortor felis eu pellentesque augue.</p>
            </div>
            <div className="position-relative" style={{paddingLeft:"35px"}}>
                {/* only visual */}
                <div className="d-flex align-items-center justify-content-center position-absolute bg-supp-pine rounded-circle top-0" style={{width:"24px", height:"24px", left:"3px"}}>
                    <img src="https://placehold.co/16x16" style={{width:"16px", height:"16px"}} />
                </div>

                {/* content */}
                <p className="n-fs-7 neutral-charcoal-400 mb-0">14 Apr. 2024</p>
                <p className="n-fs-6 fw-medium mb-2">Data uploaded</p>
            </div>
        </div>

    </NHBox>;
    }

    getRelatedProjectBox(){
        const colors =[
            ["bg-supp-plum", "white"],
            ["bg-neutral-stone", "black"],
            ["bg-neutral-charcoal", "white"],
            ["bg-supp-forest-green", "white"]
        ]
        return <><h3 className="n-fs-5 mb-4">{txt({global:true, text:"Relaterade projekt"})}</h3>
        <div className="w-100 mb-4 no-scroll-bar" style={{overflowX:"auto", overflowY:"hidden"}}>
        <div className="d-flex">
            {Window.react_data.related_projects.slice(0,3).map((itm, i) => {
                return <div style={{cursor:"pointer", width:(screen.width >= 992 ? "calc(25% - 20px)" : "224px")}} onClick={() => {window.location = "/"+ itm.attribute_label +"/" + itm.id + "/"}} className="flex-shrink-0 me-3">
                <div className={`corners-2-all ${colors[i][0]} position-relative p-3 d-inline-block d-lg-block`} style={{height:"212px"}}>
                    <span className={`${colors[i][0]}-300 ${colors[i][1]} rounded-5 py-1 px-2 n-fs-8`}>{txt({global:true, text:itm.tag})}</span>
                    <h4 className={`${colors[i][1]} n-fs-6 mt-2`}>{txt({location_id:"project_title", context_clue:"Biodiversity project title", text:itm.title})} - {itm.property_designation}</h4>
                    <span className={`${colors[i][0]}-300 fw-medium ${colors[i][1]} rounded-5 py-1 px-2 n-fs-6 position-absolute bottom-0 mb-3`}>{itm.end_time_formats ? itm.end_time_formats.pretty : ""}</span>
                </div>
            </div>
            })}
        </div>
        </div></>
    }

    getBuyBox(){
        return <NHBox hidden={false}>
        <div className="d-flex justify-content-between">
            <p className="d-flex align-items-center n-fs-7 n-lg-fs-6"><img className="pe-1" src={`${Window.static_path}img/icons/sun.svg`} style={{height:"20px"}} /> {txt({global:true, text:"Status"})}</p>
            <p className="d-flex align-items-center"><NHPill bg={Window.react_data.state < 40 ? "bg-neutral-stone" :"bg-semantic-success"} font_size="n-fs-7 n-lg-fs-6" font_weight="fw-normal" text={txt({global:true, location_id:`project_state_${Window.react_data.state_name}`, text:Window.react_data.state_name})} icon={`${Window.static_path}img/icons/icon_circle.svg`} /></p>
        </div>
        <div className="d-flex justify-content-between">
            <p className="d-flex align-items-center n-fs-7 n-lg-fs-6"><img className="pe-1" src={`${Window.static_path}img/icons/timer.svg`} style={{height:"20px"}} /> {txt({global:true, location_id:"project_length_title", text:"Projektlängd"})}</p>
            <p className="d-flex align-items-center n-fs-7 n-lg-fs-6">{Window.react_data.project_length} {Window.react_data.project_length_unit} <NHInfo title={txt({global:true, text:"Projektlängd"})} body={txt({global:true, location_id:"project_length_info_text", context_clue:"Description of how the project length works", text:"Projektet pågår ett visst antal år, ofta med möjlighet till förnyelse av köparen. Det betyder att skogen inte är skyddas i en evighet utan kan återgå till skogsägaren efter perioden är över."})} /></p>
        </div>
        <NHDivider extra_class="mt-1 mb-2" />
        {Window.react_data.buy_link && <a href={Window.react_data.buy_link} className="n-btn btn-forest-green w-100 d-block">{txt({global:true, text:"Kontakt för köp"})}</a>}
        <p className="text-center my-3"><span className="neutral-charcoal-400">{txt({global:true, text:"Frågor?"})}</span> <a href="mailto:info@treebula.se" className="text-decoration-underline">{txt({global:true, text:"Kontakta oss"})}</a></p>
    </NHBox>
    }

    getContactBox(){
        return <NHBox headline={Window.react_data.project_manager ? txt({global:true, localtion_id:"contact_project_owner", text:"Projektägare"}) : ""} hidden={false}>
        {Window.react_data.project_manager ? <NHEdit 
        className="position-absolute end-0 top-0 pt-3 pe-3" 
        onClick={() => {this.generateUpdateForm("project_manager", Window.react_data.project_manager.id, Window.react_data.project_manager)}} />
        : <NHAdd className="position-absolute end-0 top-0 pt-3 pe-3" 
        onClick={() => {this.generateCreateForm("project_manager", {}, {"project_id": Window.react_data.id})}} /> }
        {Window.react_data.project_manager && <><div className="d-flex align-items-center">
            <img src={`${Window.react_data.project_manager.picture}`} className="rounded-circle" style={{objectFit:"cover", height:"48px"}} />
            <p className="n-fs-7 ms-1 mb-0">
                {Window.react_data.project_manager.public_name}<br/>
                <a className="neutral-charcoal-400">{Window.react_data.project_manager.public_email}</a>
            </p>
        </div>
        <NHDivider extra_class="mt-1 mb-2" /></>}

        {Window.react_data.links.length > 0 || Window.react_data.is_editor ? <>
        <h3 className="n-fs-5 fw-medium mb-3">{txt({global:true, text:"Länkar"})}</h3>
        <div className="d-flex flex-wrap w-100 position-relative">
            {Window.react_data.links.map((lnk) => {
                return <>
                <NHPill 
                    delete={Window.react_data.is_editor ? () => {this.handleDelete("project_link", lnk.id)} : undefined}
                    extra_class="me-2 mb-2" 
                    link={lnk.link} 
                    text={lnk.name} 
                    icon={`${Window.static_path}${lnk.icon}`} />
                </>;
            })}
            <NHAdd 
            className="pt-2 w-100" 
            onClick={() => {this.generateCreateForm("project_link", {}, {"project_id": Window.react_data.id})}} />
        </div>
        <NHDivider extra_class="mt-1 mb-2" />
        </>: null}
        {Window.react_data?.linkedin_link || Window.react_data?.instagram_link || Window.react_data?.facebook_link ? <>
        <h3 className="n-fs-5 fw-medium mb-3">{txt({global:true, text:"Sociala Medier"})}</h3>
        <div className="d-flex flex-wrap w-100">
            {Window.react_data?.linkedin_link && <NHPill link={Window.react_data.linkedin_link} extra_class="me-2 mb-2" text="LinkedIn" icon={`${Window.static_path}img/social/linkedin.svg`} />}
            {Window.react_data?.instagram_link && <NHPill link={Window.react_data.instagram_link} extra_class="me-2 mb-2" text="Instagram" icon={`${Window.static_path}img/social/instagram.svg`} />}
            {Window.react_data?.facebook_link && <NHPill link={Window.react_data.facebook_link} extra_class="me-2 mb-2" text="Facebook" icon={`${Window.static_path}img/social/facebook.svg`} />}
        </div>
        <NHDivider extra_class="mt-1 mb-2" />
        </> : null}
        <h3 className="n-fs-5 fw-medium mb-3 mb-2">{txt({global:true, text:"Kontakt"})}</h3>
        <p className="n-fs-7 mb-3">{txt({global:true, location_id:"contact_box_contact_copy", context_clue:"Text describing how to contact", text:"För förfrågningar eller samarbetsmöjligheter, vänligen kontakta oss via e-post på"})} {Window.react_data.company ? Window.react_data.company.company_email : "info@treebula.se"}.</p>
        <div className="px-3 py-2 bg-neutral-cloud-300 d-inline-block corners-1-all">
            <img style={{width:"137px"}} src={Window.react_data.company ? Window.react_data.company.company_logo : `${Window.static_path}img/icons/treebula_lockup_charcoal.svg`}/>
        </div>
        {Window.react_data.company ? 
        <NHEdit className="pt-2 w-100" onClick={() => {this.generateUpdateForm("project_company", Window.react_data.company.id, Window.react_data.company)}} />
        :<NHAdd className="pt-2 w-100" onClick={() => {this.generateCreateForm("project_company", {}, {"project_id": Window.react_data.id})}} />
        }
    </NHBox>
    }

    getNewsletterBox(){
        return <NHBox headline={txt({global:true, location_id:"sub_newsletter_updates", text:"Uppdateringar"})} hidden={false}>
        <p className="n-fs-7 mb-4">{txt({global:true, location_id:"newsletter_subscribe_title", text:"Anmäl dig och få de senaste uppdateringarna om projektet."})}</p>
        {this.state.subMessage && <p className="n-fs-6">{this.state.subMessage}</p>}
        <VBInput 
            placeholder={txt({global:true, context_clue:"Input field placeholder for email", text:"Ange mailadress"})}
            onChange={(val) => {this.setState({subEmail:val})}}
            type="email"
        />
        <div className='d-flex align-items-center my-2'>
            <VBCheckbox
                label=""
                initial={false}
                onChange={(val) => {this.setState({subTermsCheck:val})}}
                 />
            <p className='mb-0 n-fs-7' >{txt({location_id:"newsletter_terms_text", context_clue:"Terms for subscribing to newsletter", text:'Genom att klicka på "Prenumerera", bekräftar du att ha tagit del av våra'})} <a className="text-decoration-underline" href="/terms/">{txt({global:true, text:'"användarvillkor"'})}</a> & <a className="text-decoration-underline" href="/privacy/">{txt({global:true, text:'"integritetspolicy"'})}</a>.</p>
        </div>
        <a className="n-btn btn-forest-green w-100 d-inline-block" onClick={() => {this.subEmailUpdate()}}>{txt({global:true, text:"Prenumerera"})}</a>
    </NHBox>
    }

    getTabs(){
        return <div>
            {/* tabs */}
            <NHTabs onChange={(itm) => {this.setState({activeTab:itm})}} activeDefault={this.state.activeTab} tabs={[["overblick", txt({global:true, location_id:"bio_tab_overblick", text:'Överblick'})], ["bilagor",txt({global:true, location_id:'bio_tab_bilagor', text:'Bilagor'})], ["forandringsanalys", txt({global:true, location_id:'bio_tab_change', text:'Förändringaranalys'})], ["aktivitet", txt({global:true, location_id:'bio_tab_activity', text:'Aktivitet'})]]} />
        </div>
    }

    getTopBar(){
        return <div className="corners-1-et corners-1-st bg-lg-neutral-cloud-200 bg-white p-2 d-flex no-scroll-bar" style={{overflowY:"hidden", overflowX:"auto"}}>
        <div className="d-flex bg-neutral-cloud-200 corners-2-all p-2 p-lg-0 m-2 m-lg-0" style={{width:(screen.width >= 992 ? "192px" : "auto"), height:(screen.width >= 992 ? "36px" : "auto")}}>
            <span className="p-2 rounded-2 bg-neutral-cloud-300 me-2 align-items-center justify-content-center d-none d-lg-flex">
                <img src={`${Window.static_path}img/icons/hash.svg`} />
            </span>
            <div>
                <p className="fw-medium m-0 w-100 n-fs-7 text-nowrap">#NH{Window.react_data.id}</p>
                <p className="neutral-pebble m-0 n-fs-7 text-nowrap">{txt({global:true, text:"Biodiversitets nr."})}</p>
            </div>
        </div>
        <div className="d-flex bg-neutral-cloud-200 corners-2-all p-2 p-lg-0 m-2 m-lg-0" style={{width:(screen.width >= 992 ? "192px" : "auto"), height:(screen.width >= 992 ? "36px" : "auto")}}>
            <span className="p-2 rounded-2 bg-neutral-cloud-300 me-2 align-items-center justify-content-center d-none d-lg-flex">
                <img src={`${Window.static_path}img/icons/map_pin.svg`} />
            </span>
            <div>
                <p className="fw-medium m-0 w-100 n-fs-7 text-nowrap">{Window.react_data.muni}, {txt({global:true, text:Window.react_data.country})}</p>
                <p className="neutral-pebble m-0 n-fs-7 text-nowrap">{txt({global:true, text:"Plats"})}</p>
            </div>
        </div>
        <div className="d-flex bg-neutral-cloud-200 corners-2-all p-2 p-lg-0 m-2 m-lg-0" style={{width:(screen.width >= 992 ? "192px" : "auto"), height:(screen.width >= 992 ? "36px" : "auto")}}>
            <span className="p-2 rounded-2 bg-neutral-cloud-300 me-2 align-items-center justify-content-center d-none d-lg-flex">
                <img src={`${Window.static_path}img/icons/corners_out.svg`} />
            </span>
            <div>
                <p className="fw-medium m-0 w-100 n-fs-7 text-nowrap">{Window.react_data.area ? Number(Window.react_data.area).toFixed(1) : 0} ha</p>
                <p className="neutral-pebble m-0 n-fs-7 text-nowrap">{txt({global:true, location_id:"size_hectares", text:"Storlek"})}</p>
            </div>
        </div>
        {Window.react_data.end_time_formats && <div className="d-flex bg-neutral-cloud-200 corners-2-all p-2 p-lg-0 m-2 m-lg-0" style={{width:(screen.width >= 992 ? "192px" : "auto"), height:(screen.width >= 992 ? "36px" : "auto")}}>
            <span className="p-2 rounded-2 bg-neutral-cloud-300 me-2 align-items-center justify-content-center d-none d-lg-flex">
                <img src={`${Window.static_path}img/icons/calendar_x.svg`} />
            </span>
            <div>
                <p className="fw-medium m-0 w-100 n-fs-7 text-nowrap">{Window.react_data.end_time_formats.pretty}</p>
                <p className="neutral-pebble m-0 n-fs-7 text-nowrap">{txt({global:true, text:"Slutdatum"})}</p>
            </div>
        </div>}
    </div>
    }

    getImageSentinel(change_id, after=false){
        let headers = {
            'credentials': 'same-origin',
            'X-CSRFToken': Cookies.get('csrftoken'),
            'Content-Type': "application/json"
            }
        fetch('/naturehub/api/get-image/', {
            method:"POST",
            headers:headers,
            body: JSON.stringify({"change_id": change_id, "after":after})
        }).then(resp => resp.json()).then((json) => {
            let bbox = json.bbox.split(",")
            let bounds = [[bbox[1], bbox[0]], [bbox[3], bbox[2]]]
            var imageOverlay = L.imageOverlay(json.b64, bounds);
            this.changeMap.current.addImageOverlayToMap(imageOverlay);

        })
    }

    getChangeAnalysis(){
        return <div className={`corners-1-all bg-neutral-cloud-300 mb-3 position-relative mt-2`}>
            <div className="container-fluid">
                <div className="row flex-column-reverse flex-lg-row g-3">
                    <div className="col-12 col-lg-5 no-scroll-bar" style={{maxHeight:"667px", overflowY:"auto"}}>
                        {screen.width >= 992 ? <div className="p-3 mb-2 bg-white corners-2-all">
                            <p className="fw-medium n-fs-7 mb-1">{txt({global:true, text:"Förändringsanalys"})}</p>
                            <p className="n-fs-7 mb-0">{txt({global:true, text:"I den här vyn kommer du att kunna se Treebulas analys av förändringar som sker på fastigheten över tid. Du får tillgång till en komplett historik som visar vad som har ändrats, och du kan välja en viss tidpunkt för att se detaljer om förändringarna."})}</p>
                        </div>: null}
                        {Window.react_data.changes.map((itm) => {
                            return <div onClick={() => {
                                if(this.state.selected_changes.includes(itm.id)){
                                    this.changeMap.current.removeLayer(itm.id)
                                    let indx = this.state.selected_changes.indexOf(itm.id)
                                    this.state.selected_changes.splice(indx, 1)
                                    this.setState({selected_changes:this.state.selected_changes})
                                    return;
                                }
                                this.changeMap.current.addGeojsonToMap(itm.geojson, itm.id); 
                                this.state.selected_changes.push(itm.id)
                                this.setState({selected_changes:this.state.selected_changes})
                                }} style={{"cursor":"pointer"}} className={`d-flex flex-wrap justify-content-between align-items-center p-3 corners-2-all mb-2 bg-white`}>
                                <div className="d-flex align-items-center">
                                    <div className={`p-2 me-2 bg-semantic-warning-50 corners-1-all`}>
                                        <img style={{width:"24px"}} src={Window.static_path+"img/icons/Warning.svg"} />
                                    </div>
                                    <div className="me-3">
                                        <p className="n-fs-7 mb-0">{itm.date_formats.iso}</p>
                                        <p className="n-fs-6 mb-0 fw-medium">{txt({location_id:`ProjectForestChange_title_${itm.id}`, context_clue:"Detected change in forest data short description (Title)", text:itm.title})}</p>
                                    </div>
                                    <div 
                                        style={{width:"16px", "height":"16px"}} 
                                        className="corners-1-all border border-supp-pine d-flex align-items-center justify-content-center">
                                        <div
                                        style={{width:"10px", "height":"10px"}}
                                        className={`corners-1-all ${this.state.selected_changes.includes(itm.id) ? "bg-supp-pine" : ""}`}></div>   
                                    </div>
                                </div>
                                
                                {(this.state.selected_changes.includes(itm.id)) && <NHDivider extra_class="mt-1 mb-2" />}
                                <p 
                                style={{height:this.state.selected_changes.includes(itm.id) ? "auto":"0", transition:"300ms", overflow:"hidden"}} 
                                className={`w-100 n-fs-7 mb-2`}>{txt({location_id:`ProjectForestChange_description_${itm.id}`, context_clue:"Detected change in forest data long description", text:itm.description})}</p>
                                {(this.state.selected_changes.includes(itm.id) && itm.image_before_date) && <div className="d-flex w-100 justify-content-between">
                                    <button className="n-btn btn-sm btn-outline btn-forest-green n-fs-7 p-2" onClick={(e)=>{e.stopPropagation(); this.getImageSentinel(itm.id)}}>{txt({global:true, location_id:"change_analysis_before_image", context:"Button for showing before change sattelite image", text:"Visa före bild"})}</button>
                                    <button className="n-btn btn-sm btn-outline btn-forest-green n-fs-7 p-2" onClick={(e)=>{e.stopPropagation(); this.getImageSentinel(itm.id, true)}}>{txt({global:true, location_id:"change_analysis_after_image", context:"Button for showing after change sattelite image", text:"Visa efter bild"})}</button>
                                </div>}
                            </div>
                        })}
                    </div>

                    <div className="col-lg-7 col-12 position-relative mb-2" style={{height:screen.width > 992 ? "667px" : "450px"}}>
                        <NHLeafletChange ref={this.changeMap} sections={Window.react_data.sections} geojson={Window.react_data?.forest?.geom} />
                    </div>
                    
                </div>
            </div>
        </div>
    }

    getImages(){
        if(Window.react_data.images.length == 0){
            return
        }

        let has_names = false

        let unique_photographer_names = Window.react_data.images.reduce((acum,img) => {
            if(!acum.includes(img.photographer_name)){
                if(img.photographer_name){
                    acum.push(img.photographer_name)
                    has_names = true
                }
            } 
            return acum
        }, [])


        let photographers = has_names && <div className="col-12"><p className="n-fs-7 mt-1">{txt({global:true, text:"Fotografer"})}: {unique_photographer_names.join(", ")}</p></div>

        let modal = <Modal size="lg" centered show={this.state.show_image_modal} onHide={() => {this.setState({show_image_modal:false})}}>
        <Modal.Header closeButton>
          <Modal.Title>{txt({global:true, text:"Bilder"})}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{Window.react_data.images.map(img => <div className="position-relative">
            <img src={img.image} className="d-block w-100 px-1" /> 
            {img.photographer_name && <p className="mb-2 mt-1 n-fs-7">{txt({global:true, text:"Fotograf"})}: {img.photographer_name}</p>}
            {Window.react_data.is_editor ? <button className="position-absolute top-0 me-2 mt-1 end-0 n-btn btn-sm btn-error" onClick={() => {this.handleDelete("image_file", img.id)}}>Delete image</button> : null}
            </div>)}</Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>;
        if(screen.width <= 992){
            return <div className="row">
                {modal}
                <div className="col-12 position-relative" style={{height:"260px"}}>
                    <img src={Window.react_data.images[0].image} className="w-100" style={{height:"260px", objectFit:"cover"}} />
                    <button onClick={() => {this.setState({show_image_modal:true})}} className="n-btn btn-sm btn-core position-absolute" style={{top:"50%", left:"50%", transform:"translate(-50%,-50%)"}}>{txt({global:true, context_clue:"Show all images button text", text:"Visa alla bilder"})}</button>
                </div>
                {photographers}
            </div>
        }
        if(Window.react_data.images.length == 1){
            return <div className="row g-2 mt-2">
                {modal}
                <div className="col-12 position-relative" style={{height:"530px"}}>
                    <img src={Window.react_data.images[0].image} className="w-100" style={{height:"530px", objectFit:"cover"}} />
                    <button onClick={() => {this.setState({show_image_modal:true})}} className="n-btn btn-sm btn-core position-absolute" style={{top:"50%", left:"50%", transform:"translate(-50%,-50%)"}}>{txt({global:true, context_clue:"Show all images button text", text:"Visa alla bilder"})}</button>
                </div>
                {photographers}
            </div>
        } else if(Window.react_data.images.length == 2){
            return <div className="row g-2 mt-2">
                {modal}
            <div className="col-6" style={{height:"530px"}}>
                <img src={Window.react_data.images[0].image} className="w-100" style={{height:"530px", objectFit:"cover"}} />
            </div>
            <div className="col-6 position-relative" style={{height:"530px"}}>
                <img src={Window.react_data.images[1].image} className="w-100" style={{height:"530px", objectFit:"cover"}} />
                <button onClick={() => {this.setState({show_image_modal:true})}} className="n-btn btn-sm btn-core position-absolute" style={{top:"50%", left:"50%", transform:"translate(-50%,-50%)"}}>{txt({global:true, context_clue:"Show all images button text", text:"Visa alla bilder"})}</button>
            </div>
            {photographers}
        </div>
        } else if(Window.react_data.images.length == 3){
            return <div className="row g-2 mt-2">
                {modal}
                <div className="col-4" style={{height:"530px"}}>
                    <img src={Window.react_data.images[0].image} className="w-100" style={{height:"260px", objectFit:"cover", marginBottom:"10px"}} />
                    <img src={Window.react_data.images[1].image} className="w-100" style={{height:"260px", objectFit:"cover"}} />
                </div>
                <div className="col-8 position-relative" style={{height:"530px"}}>
                    <img src={Window.react_data.images[2].image} className="w-100" style={{height:"530px", objectFit:"cover"}} />
                    <button onClick={() => {this.setState({show_image_modal:true})}} className="n-btn btn-sm btn-core position-absolute" style={{top:"50%", left:"50%", transform:"translate(-50%,-50%)"}}>{txt({global:true, context_clue:"Show all images button text", text:"Visa alla bilder"})}</button>
                </div>
                {photographers}
            </div>
        } else if(Window.react_data.images.length == 4){
            return <div className="row g-2 mt-2">
                {modal}
                <div className="col-6" style={{height:"530px"}}>
                    <img src={Window.react_data.images[0].image} className="w-100" style={{height:"260px", objectFit:"cover", marginBottom:"10px"}} />
                    <img src={Window.react_data.images[1].image} className="w-100" style={{height:"260px", objectFit:"cover"}} />
                </div>
                <div className="col-6 position-relative" style={{height:"530px"}}>
                    <img src={Window.react_data.images[2].image} className="w-100" style={{height:"260px", objectFit:"cover", marginBottom:"10px"}} />
                    <img src={Window.react_data.images[3].image} className="w-100" style={{height:"260px", objectFit:"cover"}} />
                    <button onClick={() => {this.setState({show_image_modal:true})}} className="n-btn btn-sm btn-core position-absolute" style={{top:"75%", left:"50%", transform:"translate(-50%,-50%)"}}>{txt({global:true, context_clue:"Show all images button text", text:"Visa alla bilder"})}</button>
                </div>
                {photographers}
            </div>
        }
        return <div className="row g-2 mt-2">
            {modal}
            <div className="col-4" style={{height:"530px"}}>
                <img src={Window.react_data.images[0].image} className="w-100" style={{height:"170px", objectFit:"cover", marginBottom:"10px"}} />
                <img src={Window.react_data.images[1].image} className="w-100" style={{height:"170px", objectFit:"cover", marginBottom:"10px"}} />
                <img src={Window.react_data.images[2].image} className="w-100" style={{height:"170px", objectFit:"cover"}} />
            </div>
            <div className="col-8 position-relative" style={{height:"530px"}}>
                <img src={Window.react_data.images[3].image} className="w-100" style={{height:"260px", objectFit:"cover", marginBottom:"10px"}} />
                <img src={Window.react_data.images[4].image} className="w-100" style={{height:"260px", objectFit:"cover"}} />
                <button onClick={() => {this.setState({show_image_modal:true})}} className="n-btn btn-sm btn-core position-absolute" style={{top:"75%", left:"50%", transform:"translate(-50%,-50%)"}}>{txt({location_id:"show_all_images", context_clue:"Text for button to show all images popup", text:"Visa alla bilder"})}</button>
            </div>
            {photographers}
        </div>
    }

    getLeaflet(){
        return <NHLeaflet geojson={Window.react_data?.forest?.geom} handleSectionClick={(id) => {this.refSections[id].current.setState({show_more:true})}} sections={Window.react_data.sections} />;
    }

    setVal(val, name, data_set){
        if(!this.state.hasOwnProperty(data_set)){
            this.state[data_set] = {}
        }
        this.setState({
            [data_set]:{...this.state[data_set], [name]:val}
        });
    }

    handleUpdate(name,id, extra_data){
        const formData = new FormData();
        let req_data = {}
        let data = data_map[name];
        let url = data.update_url + id + "/"
        let headers = {
            'credentials': 'same-origin',
            'X-CSRFToken': Cookies.get('csrftoken')
            }
        if(data.contentType){
            headers["Content-Type"] = data.contentType;
        }

        Object.keys(extra_data).map((key) => {
            formData.append(key, this.state[name][key]);
            req_data[key] = this.state[name][key]
        });

        
        Object.keys(data.fields).map((key) => {
            let val = this.state[name][key]
            if(data.formatters?.hasOwnProperty(key)){
                val = data.formatters[key].output(val);
            }
            if(val !== undefined){
                formData.append(key, val);
                req_data[key] = val
            }
            
        })

        fetch(url,  {
            method:"PATCH",
            headers: headers,
            body: (data.contentType ? JSON.stringify(req_data) : formData)
        }).then((resp) =>{
            if(resp.status < 300){
                window.location.reload();
            } 
        })

    }

    handleCreate(name, extra_data){
        const formData = new FormData();
        let req_data = {}
        let data = data_map[name];
        let url = data.create_url
        let headers = {
            'credentials': 'same-origin',
            'X-CSRFToken': Cookies.get('csrftoken')
            }
        if(data.contentType){
            headers["Content-Type"] = data.contentType;
        }
        
        Object.keys(extra_data).map((key) => {
            formData.append(key, this.state[name][key]);
            req_data[key] = this.state[name][key]
        });
        
        Object.keys(data.fields).map((key) => {
            let val = this.state[name][key]
            if(data.formatters?.hasOwnProperty(key)){
                val = data.formatters[key].output(val);
            }

            if(val !== undefined){
                formData.append(key, val);
                req_data[key] = val
            }
        })

        fetch(url,  {
            method:"POST",
            headers: headers,
            body: (data.contentType ? JSON.stringify(req_data) : formData)
        }).then((resp) =>{
            if(resp.status < 300){
                window.location.reload();
            } 
        })

    
    }

    handleDelete(name, id){
        let data = data_map[name];
        let url = data.update_url + id.toString() + "/"
        let headers = {
            'credentials': 'same-origin',
            'X-CSRFToken': Cookies.get('csrftoken')
            }
        fetch(url,  {
            method:"DELETE",
            headers: headers,
        }).then((resp) =>{
            if(resp.status < 300){
                window.location.reload();
            } 
        })
    }

    generateCreateForm(name, init_data, extra_data={}){
        let init_data_formatted = {...init_data}
        let jsx = <>
            {Object.keys(data_map[name].fields).map((key) => {
                let data = data_map[name].fields[key]
                let initial = null;
                if(init_data){
                    initial = init_data[key];
                    if(data_map[name].formatters?.hasOwnProperty(key)){
                        initial = data_map[name].formatters[key].input(initial);
                    }
                }
                init_data_formatted[key] = initial;

                let get_input_args =[data.label, initial, (val) => {this.setVal(val, key, name)}]
                if(data.window_options){
                    
                    get_input_args.push(Window.react_data[data.window_options])
                    console.log(get_input_args)
                }
                return <>{data.input.get_input(...get_input_args)} <br/></>
            })}
            <button className="n-btn btn-sm btn-forest-green" onClick={() => {this.handleCreate(name, extra_data)}}>Create</button>
        </>
        
        this.setState({crudOffCanvas: jsx, [name]:{...init_data_formatted, ...extra_data}});
    }

    generateUpdateForm(name, id, init_data, extra_data={}){
        let init_data_formatted = {...init_data}
        let jsx = <>
        {Object.keys(data_map[name].fields).map((key) => {
            let data = data_map[name].fields[key]
            let initial = null;
            if(init_data){
                initial = init_data[key];
                if(data_map[name].formatters?.hasOwnProperty(key)){
                    initial = data_map[name].formatters[key].input(initial);
                }
            }
            init_data_formatted[key] = initial;

            let get_input_args =[data.label, initial, (val) => {this.setVal(val, key, name)}]
            if(data.window_options){
                
                get_input_args.push(Window.react_data[data.window_options])
                console.log(get_input_args)
            }
            return <>{data.input.get_input(...get_input_args)} <br/></>
        })}
        <button className="n-btn btn-sm btn-forest-green" onClick={() => {this.handleUpdate(name, id, extra_data)}}>Update</button>
        {data_map[name].allow_delete ? <button className="n-btn btn-sm btn-error ms-1" onClick={() => {this.handleDelete(name, id)}}>Delete</button>:null}
        </>
        this.setState({crudOffCanvas: jsx, [name]:{...init_data_formatted, ...extra_data}});
    }

    subEmailUpdate(){

        if(!this.state.subTermsCheck){
            this.setState({subMessage: txt({location_id:"newsletter_incorrect_terms", context_clue:"User attemped to subscibe to newsletter but did not accept the terms", text:"Du måste acceptera våra villkor"})})
            return;
        }

        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(!re.test(String(this.state.subEmail).toLowerCase())){
            this.setState({subMessage: txt({location_id:"newsletter_incorrect_email", context_clue:"User attemped to subscribe to newsletter but the email they wrote was incorrect", text:"Du har angett en felaktig e-post"})})
            return;
        }


        let headers = {
            'credentials': 'same-origin',
            'X-CSRFToken': Cookies.get('csrftoken'),
            'Content-Type': "application/json"
            }
        fetch('/naturehub/api/project-email-notify/create/', {
            method:"POST",
            headers: headers,
            body: JSON.stringify({
                "email": this.state.subEmail,
                "project_id": Window.react_data.id
            })
        }).then((resp) => {
            if(resp.status > 100 && resp.status < 300){
                this.setState({subMessage: txt({location_id:"newsletter_success_message", context_clue:"User subscribed to newsletter with success", text:"Du kommer nu få mejl när uppdateringar sker i projektet"})})
            } else{
                this.setState({subMessage: txt({location_id:"newsletter_error_message", context_clue:"User attempted to subscribe to newsletter but some internal error occurred", text:"Något gick fel, var god försök igen"})})
            }
        })
    }

    getMobileOrder(){
        return <div className="row">
            <div className="col-12 g-0">
            <div style={{width:"100%", height: "512px"}}>
                {/* leaflet go here */}
                {this.getLeaflet()}
            </div>
            </div>
        <div className="col-12">
            
            
            {this.getTopBar()}
            {this.getTabs()}
            {this.state.activeTab == 'overblick' ? this.getBuyBox() : null}
            {this.state.activeTab == 'overblick' ? this.getContactBox() : null}
            {this.state.activeTab == 'overblick' || this.state.activeTab == 'bilagor' ? this.getAttachmentBox() : null}
            {this.state.activeTab == "forandringsanalys" ? this.getChangeAnalysis() : null}
            {this.state.activeTab == 'overblick' ? this.getAboutBox() : null}
            {this.state.activeTab == 'overblick' ? this.getDetailsBox() : null}
            {this.state.activeTab == 'overblick' ? this.getSectionsBox(): null}
            {this.state.activeTab == 'overblick' ? this.getOtherInfoBox() : null}
            {this.state.activeTab == 'overblick' || this.state.activeTab == 'aktivitet' ? this.getUpdateBox() :null}
            {this.state.activeTab == 'overblick' ? this.getNewsletterBox() :null}
            {this.getRelatedProjectBox()}

        </div>
    </div>
    }

    getDesktopOrder(){
        return <div className="row">
        <div className="col-8">
            {this.getTopBar()}
            <div style={{width:"100%", height: "512px"}} className="corners-1-eb corners-1-sb overflow-hidden">
                {/* leaflet go here */}
                {this.getLeaflet()}
            </div>

            {this.getTabs()}
            {this.getAboutBox()}
            {this.state.activeTab == "forandringsanalys" ? this.getChangeAnalysis() : null}
            {this.getDetailsBox()}
            {this.getSectionsBox()}
            {this.getOtherInfoBox()}
            {this.getUpdateBox()}
            {this.state.activeTab != "bilagor" ? null : this.getAttachmentBox()}
            {this.getRelatedProjectBox()}
        </div>
        <div className="col-4">
            {this.getBuyBox()}
            {this.getContactBox()}
            {this.getAttachmentBox()}
            {this.getNewsletterBox()}
        </div>
    </div>
    }

    render() {
        let has_untranslated = Object.keys(Window?.lang_data).reduce((acum, curr) => Window?.lang_data[curr].result_txt && !Window?.lang_data[curr].requires_update ? acum: true, false)

        return (
            <div>
                <div className="bg-white">
                    <Navigation ref={this.navigation} />
                </div>
                <div className="container neutral-charcoal mt-3 mb-5">
                    <a href="/">{txt({global:true, context_clue:"Breadcrumb for navigation to startpage", text:"Startsida"})}</a> > <a href="/biodiversitet/projekt/">{txt({location_id:"breadcrumb-bio-projectspage", context_clue:"Breadcrumb for navigation to projekts page", text:`${Window.react_data.attribute_label}`})}</a> >  {txt({location_id:"project_name", context_clue:"Property name (#id + designation)", text:`#NH${Window.react_data.id} - ${Window.react_data.property_designation}`})}
                </div>
                <div className="container neutral-charcoal mt-3">
                    <div className="row justify-content-between">
                        <div className="col-lg-8 col-12">
                            <p className="n-fs-7+">{txt({location_id:"project_name", context_clue:"Property name (#id + designation)", text:`#NH${Window.react_data.id} - ${Window.react_data.property_designation}`})}</p>
                            <h1 className="n-fs-2">{txt({location_id:"project_title", context_clue:"Biodiversity project title", text:Window.react_data.title})} - {Window.react_data.property_designation}</h1>
                            <p className="n-fs-5"><NHTextCapper max_length={512} text={txt({location_id:"project_description", context_clue:"Project Description", text:Window.react_data.description})} /></p>
                        </div>
                        <div className="col-8 col-lg-2">
                            <NHEdit className="mb-3 float-end" onClick={() => {this.generateUpdateForm("project", Window.react_data.id.toString(), Window.react_data)}} />
                            <VBDropdown initial={Cookies.get("target_lang")} placeholder="Svenska" onChange={(opt) => {Cookies.set("target_lang", opt.value); window.location.reload()}} options={[
                                {"label": "Svenska", "value": "sv-se"}, 
                                {"label": "English", "value": "en-us"},
                                /*{"label": "Deutsch", "value": "de"},
                                {"label": "Soumi", "value": "fi"},
                                {"label": "Norsk", "value": "no"},
                                {"label": "Espanol", "value": "es"}*/
                                ]} inputStyle={{ minHeight: 0 }} />
                            <p className="n-fs-7 mt-2">{txt({global:true, location_id:"bio_auto_translate_warn", text:"Översättning sker automatiskt och kan innehålla fel"})}</p>
                        </div>
                    </div>
                </div>
                <div className="container mt-3">
                    {screen.width >= 992 ? this.getDesktopOrder() : this.getMobileOrder()}
                </div>
                <Footer/>

            <Modal size="lg" centered show={this.state.crudOffCanvas != null} onHide={() => {this.setState({crudOffCanvas:null})}}>
                <Modal.Body>
                    <div style={{maxHeight:"60vh"}} className="overflow-auto">{this.state.crudOffCanvas}</div>
                </Modal.Body>
            </Modal>

            {(has_untranslated && Window.user_data.isAdmin) && <TxtTranslateModal />}
            
            </div >
        )
    }
}
export default NHDetails;