import React from 'react';



export default function HoverLabel(props) {

    const [visible, setVisible] = React.useState(false);
    const [textPos, setTextPos] = React.useState({top:-1000, left:-1000});

    const handleMouseMove = (e) => {
        if(!visible){
            setTextPos({
                top: e.clientY-24,
                left: e.clientX
            })
            setVisible(true)
        }
    }

    
    return <>
    <p onMouseMove={handleMouseMove} onMouseLeave={() => {setVisible(false); setTextPos({top:-1000, left:-1000})}} className={props.className} style={{...props.style, ...{display:"inline-block", cursor:"pointer"}}}>
        {props.children}
        <div style={{display: visible ? "inline-block" : "none", top: textPos.top, left: textPos.left}} className="bg-white p-1 border border-solid position-fixed rounded-1">
            <p className="n-fs-7 mb-0">{props.text}</p>
        </div>
    </p>
    </>
}