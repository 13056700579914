import Cookies from 'js-cookie';
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { ErrorModal } from '../modals';
import { validateForm, userExists, createAd, getAdState } from '../api';
import * as Opt from '../components/editor/Helpers';
import VBLoadingScreen from "@virkesborsen/vb-js-commons/lib/components/vbLoadingScreen";
import ColorSelector from '../components/editor/fields/ColorSelector';
import LoginFormModal from './LoginFormModal';
import styled from 'styled-components';
import VBInput from '@virkesborsen/vb-js-commons/lib/components/new/vbInput';
import { handleInputChange } from './handlers/formHandlers';

const StyledModal  = styled(Modal)`
   z-index: 1100;
`

class SellerLandingForm extends React.Component {
    constructor() {
        super();

        this.state = {
            isReg: null,
            isLogged: Window.user_data.isLogged,
            didReg: false,
            formData: {
                arrangement: Window.react_data.lists.arrangements[0].value //set the default value to the first option
            },
            errors: {},
            activeTab: 1,
            goto_ad_id: "",
            temp_user_id: -1,
            popupChoice:null,
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.checkEmailOrPhonenumber = this.checkEmailOrPhonenumber.bind(this);
        this.onLoginRegComplete = this.onLoginRegComplete.bind(this);

        this.timeout = 0;
        this.navigation = React.createRef();
        this.emailorPhoneInput = React.createRef();
    }

    componentDidMount(){
        const currentCookie = Cookies.get(`ad_id_${Window.user_data.loggedInUserID}`)
        if (currentCookie){
            this.setState({oldCookie:currentCookie, old_url_magic_link:"/auctions/create/?ad_id=" + currentCookie})
        }

    }

    //validations

    //this is the VBInput for email or mobile number
    handleInputChange = (val) => {
        handleInputChange(val, this.setState.bind(this), this.checkEmailOrPhonenumber, 'formData');
    }

    checkEmailOrPhonenumber(val) {
        // clear timeout if the user is still typing before timer is over
        if (this.timeout) { 
            clearTimeout(this.timeout); 
        }
    
        return new Promise((resolve, reject) => {
            this.timeout = setTimeout(() => {

                userExists(val)
                    .then(resp => {
                        if (resp.ok) {
                            resp.json().then((json) => {
                                this.setState({ 
                                    isReg: json.exists, 
                                    temp_user_id: json.user_id, 
                                    bankidEnabled: json.bankidEnabled,
                                    multiple_mobile_users:json.multiple_mobile_users 
                                });
                                resolve(json);
                            });
                        } else {
                            reject("Response not OK");
                        }
                    })
                    .catch(error => {
                        reject(error);
                    });
            }, 200);
        });
    }
    

    handleCreateAdClick = (e) => {
        /* Always creates a draft on a user (not logged in, if their email is in the correct format)
            then can do three more things
            1. Show error in form fields
            2. Show continueOnAdModal
            3. Redirect user to auctions/create */
        e.preventDefault();
        
        let checkList = [];
    
        if (this.state.isLogged) {
            checkList.push('arrangement');
        } else {
            checkList.push('email_phone', 'arrangement');
        }
    
        const validation = validateForm(this.state.formData, checkList, this.state.isReg);
        const has_error = Object.values(validation).some(val => val);
    
        if (has_error) {
            this.setState({ errors: validation });
            return;
        }
    
        if (!this.state.isLogged) {
            this.setState({ loading: true });   

            //check whether to send email or mobile phone number
            mixpanel.track("login_start_fe", {});
            const emailOrPhone = this.state.formData.email ? ( `email=${encodeURIComponent(this.state.formData.email)}`): ( `mobile_phone_number=${encodeURIComponent(this.state.formData.mobile_phone_number)}`);
            this.checkEmailOrPhonenumber(emailOrPhone)
                .then(() => {
                    if (this.state.isReg) {
                        const adData = { arrangement: this.state.formData.arrangement };
                        let loginCredential = '';
    
                        if (this.state.formData.email) {
                            adData.email = this.state.formData.email;
                            loginCredential = `email=${encodeURIComponent(this.state.formData.email)}`;

                        } else if (this.state.formData.mobile_phone_number) {
                            adData.mobile_phone_number = this.state.formData.mobile_phone_number;
                            loginCredential = `mobile_phone_number=${encodeURIComponent(this.state.formData.mobile_phone_number)}`;
                        }
    
                        if (this.state.formData.arrangement === "grot") {
                            window.location = `/biobransle/?${loginCredential}`;
                        } else { 
                            if (this.state.multiple_mobile_users){
                                this.setState({ loginFormModal: true, loading: false, formStatus: 'multiplePhonenumberUsers' });
                            } else{                  
                            createAd(adData)
                                .then(resp => resp.json())
                                .then(json => {
                                    this.setState({ goto_ad_id: json.ad_id }, () => {
                                        const has_cookie_ad = Cookies.get(`ad_id_${this.state.temp_user_id}`);
                                        if (has_cookie_ad) {
                                            //has ad cookie show 'continueOnAdModal' first then, loginFormModal will appear in handleContinueClick function
                                            this.setState({ continueOnAdModal: true, loginFormModal: false });

                                        } else {
                                            //no ad cookie
                                            const formStatus = this.state.bankidEnabled ? 'start' : 'magicLink';
                                            this.setState({ loginFormModal: true, formStatus });                                       

                                        }
                                        this.setState({ loading: false });
                                    });
                                });
                            }
                        }
                    } else {
                        this.setState({ loginFormModal: true, loading: false, formStatus: 'login' });
                    }
                })
                .catch((error) => {
                    console.error("Error in handleCreateAdClick:", error);
                    this.setState({ loading: false });
                });
        } else {
            const has_cookie_ad = Cookies.get(`ad_id_${Window.user_data.loggedInUserID}`);
    
            if (has_cookie_ad && this.state.formData.arrangement !== 'grot') {
                this.setState({ loading: false, continueOnAdModal: true });
            } else {
                this.createRedirectToAd();
            }
        }
    };
    
    createRedirectToAd() {
        this.setState({ loading: true })

        if (this.state.formData.arrangement == "grot") {
            if(this.state.didReg) {
                window.location = "/auctions/grot/create/?register=true"
            } else {
                window.location = "/auctions/grot/create/"
            }
        } else {
            let user_id = Window.user_data.loggedInUserID != -1? Window.user_data.loggedInUserID : this.state.temp_user_id;
            //users input email does not match the login user
            let force_create_new = this.state.temp_user_id != -1 && Window.user_data.loggedInUserID != -1 && Window.user_data.loggedInUserID != this.state.temp_user_id;

            const handleAdCreation = () => {
                createAd({ arrangement: this.state.formData.arrangement }).then((resp) => {
                    if (resp.ok) {
                        resp.json().then((json) => {
                            Cookies.set(`ad_id_${user_id}`, json.ad_id)
                            Cookies.set('curr_step', 1)
                            window.location = "/auctions/create/"
    
                        })
                    } else {
                        this.setState({ loading: false, errorModal: true })
                    }
                });
            };
                       
            if(!this.state.isLogged && (this.state.goto_ad_id == "" || force_create_new)){
                //create new ad and goto it
               handleAdCreation();
            } else{
                if(this.state.isLogged){
                    if(this.state.goto_ad_id == ""){
                        handleAdCreation();
                    }
                    else{
                        //goto already created ad
                        user_id = Window.user_data.loggedInUserID
                        Cookies.set(`ad_id_${user_id}`, this.state.goto_ad_id)
                        Cookies.set('curr_step', 1)
                        getAdState(this.state.goto_ad_id).then((resp) => resp.json()).then((json) => {
                            if(json.state == 10){
                                window.location = "/auctions/create/"
                            } else if(json.state == 20){
                                window.location = "/auctions/" + goto_ad_id.toString() + "/review/";
                            } else if (json.state >= 40) {
                                Cookies.remove("ad_id");
                                window.location = "/auctions/" + json.id.toString() + "/";
                            } else{
                                window.location = "/auctions/" + goto_ad_id.toString() + "/"
                            }
                        })}
                }
        
            }
            
        }
    }

    handleBankIDConnect = () => {
        this.setState({ isLogged: true }, () => {
            let adIdCookie = Cookies.get(`ad_id_${Window.user_data.loggedInUserID}`);
            let isUserDifferent = Window.user_data.loggedInUserID !== this.state.temp_user_id && this.state.temp_user_id !== -1;
    
            const goToCreateRedirectToAd = () => {
                if (isUserDifferent) {
                    this.setState({ goto_ad_id: "" }, this.createRedirectToAd);
                } else {
                    // Go to the goto_ad_id draft created when user was not logged
                    this.createRedirectToAd();
                }
            };
    
            switch (this.state.popupChoice) {
                case 'continue':
                    if (adIdCookie) {
                        this.setState({ goto_ad_id: adIdCookie }, this.createRedirectToAd);
                    } else {
                        goToCreateRedirectToAd();
                    }
                    break;
    
                case 'new':
                    Cookies.remove(adIdCookie);
                    goToCreateRedirectToAd();
                    break;
    
                default:
                    if (this.state.popupChoice === null && adIdCookie) {
                        if (isUserDifferent) {
                            this.setState({ goto_ad_id: "", continueOnAdModal: true });
                        } else {
                            this.setState({ continueOnAdModal: true });
                        }
                    } else {
                        goToCreateRedirectToAd();
                    }
                    break;
            }
        });
    };
    

    onLoginRegComplete(){
        if (this.state.multiple_mobile_users){
            this.setState({loginFormModal:false,loading:false})
        }
        else{
            this.setState({isLogged:true},()=>{
                this.createRedirectToAd();
            })
        }
    }

    getNextUrl(){
        if(this.state.goto_ad_id){
            return "/auctions/create/?ad_id=" + this.state.goto_ad_id.toString()
        } else if(this.state.formData.arrangement){
            if(this.state.formData.arrangement == 'grot') {
                return "/biobransle/"
            } else {
                return "/auctions/create/?ad_arrangement=" + this.state.formData.arrangement;
            }
        } else {
            return ""
        }
    }

    handleContinueClick = () => {
        let ad_id = this.state.goto_ad_id;
    
        if (!this.state.isLogged) {
            //handle not logged-in state
            if (this.state.popupChoice === 'continue') {
                ad_id = Cookies.get(`ad_id_${this.state.temp_user_id}`);
            }
    
            //user is not logged & show loginFormModal
            const formStatus = this.state.bankidEnabled ? 'start' : 'magicLink';
            this.setState({ loginFormModal: true, continueOnAdModal: false, goto_ad_id: ad_id, formStatus: formStatus });
        } else {
            //handle logged-in state
            if (this.state.popupChoice === 'continue') {
                const adIdCookie = Cookies.get(`ad_id_${Window.user_data.loggedInUserID}`);
                this.setState({ goto_ad_id: adIdCookie }, () => {this.createRedirectToAd();});

            } else if (this.state.popupChoice === 'new') {
                const adIdCookie = `ad_id_${Window.user_data.loggedInUserID}`;
                Cookies.remove(adIdCookie);
                this.createRedirectToAd();
            }
    
            this.setState({ continueOnAdModal: false });
        }
    };
    

    render() {
        return (<>
            <VBLoadingScreen show_loading={this.state.loading} />
            <div className={`bg-${this.props.backgroundColor} ${this.props.textColor}`} style={{ borderRadius: "40px", padding: "32px" }}>
                <div>
                    <p className="n-fs-3 fw-medium">Skapa din annons</p>
                    <p>Välj åtgärd</p>
                    <form>
                        <ul style={{ display: 'flex', flexWrap: 'wrap', listStyleType: 'none', padding: 0 }}>
                            {Window.react_data.lists.arrangements.map((tab, index) => (
                                <li key={index} style={{ marginTop: "12px", marginRight: "8px" }}>
                                    <button className='fs-6' style={{ borderRadius: "8px", padding: '4px 8px', border: 'none',backgroundColor: this.state.activeTab === index + 1 ? '#023436' : '#EEE8E2', color: this.state.activeTab === index + 1 ? '#FFFFFF' : '#025650',}} onClick={(e) => { e.preventDefault(); this.setState({ activeTab: index + 1, formData: { ...this.state.formData, arrangement: tab.value } }) }}>
                                        {tab.label}
                                    </button>
                                </li>
                            ))}
                        </ul>
                        {!this.state.isLogged && <>
                            <p>Börja med att ange din mailadress eller mobilnummer:</p>
                            <VBInput 
                                ref={this.emailorPhoneInput} 
                                type='text'
                                label={<label className='n-fs-7 mt-3 neutral-pebble'>E-postadress / Mobilnummer</label>}placeholder={"E-postadress / Mobilnummer"} 
                                error={this.state.errors.email_phone}
                                onFocus={() => {this.state.errors.email_phone}}
                                onChange={(val) => this.handleInputChange(val)}
                            />
                        </>}
                        <div className='mt-4 d-flex'>
                            <a href="#" className='n-btn btn-core w-100' style={{cursor: "pointer"}} onClick={(e) => { e.preventDefault(); this.handleCreateAdClick(e) }}>Skapa din annons<span><img className='ms-2' width="18px" height="18px" src={Window.static_path + "img/icons/arrow-black.svg"} /></span></a>
                        </div>
                    </form>
                </div>

                <LoginFormModal 
                    completed={this.onLoginRegComplete}
                    onHide={() => this.setState({loginFormModal: false})}
                    data={this.state.formData}
                    visible={this.state.loginFormModal && !this.state.loading} 
                    next_url={this.getNextUrl()}
                    onBankIDConnect={this.handleBankIDConnect}
                    formStatus={this.state.formStatus}
                />

                <StyledModal show={this.state.continueOnAdModal} onHide={() => this.setState({ continueOnAdModal: false })} size="lg" contentClassName="bg-neutral-cloud-200 p-3" centered>
                    <Modal.Body className="neutral-charcoal pb-0"> 
                        <p className="n-fs-4 fw-medium mb-1">Skapa Annons</p>
                        <p className='n-fs-7'>Du kan även se alla dina annonsutkast under <a className="supp-pine text-decoration-underline" href="/my-account/">Mina sidor</a>.</p>

                        <div className="card mt-4 p-3" role="button" onClick={() => this.setState({popupChoice: 'continue'})} style={{ border: `solid 2px ${this.state.popupChoice == 'continue' ? "#6A58C6" : "transparent"}` }}>
                            <p className='n-fs-5 fw-medium mb-2 d-flex justify-content-between'>Fortsätt på påbörjad annons{this.state.popupChoice == 'continue' && <img src={`${Window.static_path}img/icons/checkmark.svg`} alt="Checkmark" height="22px" />}</p>
                            <p className='n-fs-6 mb-0'>Återuppta där du lämnade och gör din annons redo för publicering.</p>
                        </div>

                        <div className="card mt-4 p-3" role="button" onClick={() => this.setState({popupChoice: 'new'})} style={{ border: `solid 2px ${this.state.popupChoice == 'new' ? "#6A58C6" : "transparent"}` }}>
                            <p className='n-fs-5 fw-medium mb-2 d-flex justify-content-between'>Skapa ny annons{this.state.popupChoice == 'new' && <img src={`${Window.static_path}img/icons/checkmark.svg`} alt="Checkmark" height="22px" />}</p>
                            <p className='n-fs-6 mb-0'>Börja från grunden och skapa din perfekta annons.</p>
                        </div>
                    </Modal.Body>

                    <Modal.Footer className="justify-content-center justify-content-lg-end border-0 mt-4">
                        <div className='float-end'>
                            <button type="button" className="n-btn btn-charcoal btn-ghost neutral-charcoal me-2" onClick={() => this.setState({ continueOnAdModal: false })}>Avbryt</button>
                            <button type="button" className="n-btn btn-forest-green" disabled={!this.state.popupChoice} onClick={this.handleContinueClick}>Fortsätt
                                <span>
                                    <img className={`${!this.state.popupChoice ? 'neutral-stone-filter' : 'white-filter'} ms-2`} width="18px" height="18px" src={Window.static_path + "img/icons/arrow-black.svg"} />
                                </span>
                            </button>
                        </div>
                    </Modal.Footer>
                </StyledModal>

                <ErrorModal show={this.state.errorModal} onToggle={() => this.setState({errorModal: !this.state.errorModal})} />
            </div>
        </>
        )
    }
}

SellerLandingForm.defaultProps = {
    textColor: "neutral-charcoal",
    backgroundColor: "white",
}

SellerLandingForm.fields = {
    backgroundColor: {
        type: "custom",
        render: (props) => <ColorSelector {...props} />
    },
    textColor: {
        type: "select",
        options: Opt.colorVariables
    },

}
export default SellerLandingForm;
