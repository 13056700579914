import React, { useState } from "react";
import StepButton from "./StepButton";
import styled from "styled-components";

const formQuestions = [
  {
    id: "final_felling",
    alternative: "A",
    text: "Jag har en slutavverkning som jag ska göra",
  },
  {
    id: "thinning",
    alternative: "B",
    text: "Jag har en gallring som jag ska göra",
  },
  {
    id: "both",
    alternative: "C",
    text: "Jag har både slutavverkning och gallring jag ska göra",
  },
  {
    id: "grot",
    alternative: "D",
    text: "Sälja GROT/Brännved",
  },
];

const ForestActivitiesStep = ({
  onNext,
  stepNumber,
  initialActivities = [],
  onActivitiesChange,
  isLoading = false,
}) => {
  const [activities, setActivities] = useState(initialActivities);
  const [error, setError] = useState("");

  const handleActivityToggle = (activity) => {
    const newActivities = activities.includes(activity)
      ? activities.filter((a) => a !== activity)
      : [...activities, activity];

    setActivities(newActivities);
    setError("");
    onActivitiesChange(newActivities);
  };

  const handleNext = () => {
    if (activities.length === 0) {
      setError("Du måste välja minst ett alternativ");
      return;
    }
    onNext();
  };

  return (
    <StepContainer>
      <ContentWrapper>
        <StepNumberWrapper>
          <span>{stepNumber}</span>
          <StepArrow
            width="16px"
            height="16px"
            src={Window.static_path + "img/icons/arrow.svg"}
            alt="Step indicator"
          />
        </StepNumberWrapper>
        <MainContent>
          <Title>Vad vill du göra i skogen?*</Title>
          <Subtitle>
            Det är kostnadsfritt för dig som skogsägare att sälja via Treebula
            och du får möjlighet att ta in anbud från flera köpare utan
            förbindelser.
          </Subtitle>
          <SelectionText>Välj så många du vill</SelectionText>
          <QuestionsContainer>
            {formQuestions.map((question) => (
              <QuestionButton
                key={question.id}
                isSelected={activities.includes(question.text)}
                onClick={() => handleActivityToggle(question.text)}
              >
                <span>{question.alternative}.</span>
                <QuestionText>{question.text}</QuestionText>
                <CheckmarkContainer>
                  {activities.includes(question.text) && (
                    <img
                      width="20px"
                      height="20px"
                      src={Window.static_path + "img/icons/checkmark.svg"}
                      alt="Selected"
                    />
                  )}
                </CheckmarkContainer>
              </QuestionButton>
            ))}
          </QuestionsContainer>
          {error && <ErrorMessage>{error}</ErrorMessage>}
          <StepButton
            onClick={handleNext}
            disabled={activities.length === 0}
            text="Nästa"
            isLoading={isLoading}
          />
        </MainContent>
      </ContentWrapper>
    </StepContainer>
  );
};

const StepContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0;
  flex-grow: 1;
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
`;

const StepNumberWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;

  @media (max-width: 576px) {
    display: none;
  }
`;

const StepArrow = styled.img`
  // margin-left: 8px;
`;

const MainContent = styled.div`
  flex-grow: 1;
`;

const Title = styled.h2`
  font-size: 24px;
  margin-bottom: 0.5rem;
`;

const Subtitle = styled.p`
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.8);
  opacity: 0.75;
  margin-bottom: 1.5rem;

  @media (max-width: 576px) {
    font-size: 16px;
    margin-bottom: 1rem;
  }
`;

const SelectionText = styled.p`
  margin-bottom: 12px;

  @media (max-width: 576px) {
    font-size: 16px;
  }
`;

const QuestionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const QuestionButton = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 14px;
  border-radius: 4px;
  margin-bottom: 4px;
  cursor: pointer;
  transition: all 0.2s;
  border: 1px solid ${props => props.isSelected ? '#DFFF5D' : 'white'};
  background-color: ${props => props.isSelected ? 'hwb(72deg 36% 0%)' : 'transparent'};
  color: ${props => props.isSelected ? '#000' : 'inherit'};

  &:hover {
    background-color: ${props => props.isSelected ? 'hwb(72deg 36% 0%)' : 'rgba(255, 255, 255, 0.1)'};
  }

  @media (max-width: 576px) {
    // padding: 8px;
    font-size: 14px;
  }
`;

const QuestionText = styled.span`
  flex-grow: 1;
`;

const CheckmarkContainer = styled.div`
  flex-shrink: 0;
  min-width: 20px;
`;

const ErrorMessage = styled.p`
  color: red;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 1rem;
`;

export default ForestActivitiesStep;
