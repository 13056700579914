import React from "react";
import styled from "styled-components";

const ThankYouStep = ({ isError = false }) => {
  const handleCta = () => {
    if (isError) {
      window.location.reload();
    } else {
      window.location.href = "/min-skog/";
    }
  };

  if (isError) {
    return (
      <StepContainer>
        <ContentWrapper>
          <MainContent>
            <Title>Oj då, vi gick vilse i skogen!</Title>
            <Subtitle>
              Något gick fel när vi försökte skicka in ditt formulär. Vi undersöker problemet och löser det så snabbt vi kan – tack för ditt tålamod!
            </Subtitle>
            <Subtitle>
              Behöver du hjälp? Ring oss på <Link href="tel:08339944">08 33 99 44</Link> eller mejla oss på <Link href="mailto:info@treebula.se">info@treebula.se</Link>.
            </Subtitle>

            <ButtonWrapper>
              <ActionButton onClick={handleCta}>
                Försök igen
              </ActionButton>
            </ButtonWrapper>
          </MainContent>
        </ContentWrapper>
      </StepContainer>
    );
  }

  return (
    <StepContainer>
      <ContentWrapper>
        <MainContent>
          <Title>Tack! Vi återkommer till dig så snabbt som möjligt.</Title>
          <Subtitle>
            Medan vi behandlar din förfrågan kan du passa på att skapa en
            digital skogsbruksplan. Det ger dig en bättre överblick över din
            skog och dess potential.
          </Subtitle>

          <ButtonWrapper>
            <ActionButton onClick={handleCta}>
              Skapa skogsbruksplan
              <StepArrow
                src={Window.static_path + "img/icons/arrow-black.svg"}
                alt="Arrow"
              />
            </ActionButton>
          </ButtonWrapper>
        </MainContent>
      </ContentWrapper>
    </StepContainer>
  );
};

const StepContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0;
  flex-grow: 1;
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
`;

const MainContent = styled.div`
  flex-grow: 1;
  text-align: center;
`;

const Title = styled.h2`
  font-size: 24px;
  margin-bottom: 1.5rem;
`;

const Subtitle = styled.p`
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.8);
  opacity: 0.75;
  margin-bottom: 1.5rem;

  @media (max-width: 576px) {
    font-size: 16px;
    margin-bottom: 1rem;
  }
`;

const Link = styled.a`
  color: inherit;
  text-decoration: underline;
  
  &:hover {
    color: #DFFF5D;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const ActionButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background-color: #DFFF5D;
  color: #000;
  font-weight: 500;
  padding: 12px 24px;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background-color: #CBE855;
  }
`;

const StepArrow = styled.img`
  width: 24px;
  height: 24px;
`;

export default ThankYouStep;
