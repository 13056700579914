import React, { useState, useRef, useEffect } from "react";
import Cookies from "js-cookie";
import { Button, Col, Image } from "react-bootstrap";
import { userExists, validateEmail, leadsRegisterUser } from "../../api";
import { isValidPhoneNumber } from "react-phone-number-input";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import Navigation from "../../components/static/Navigation";
import {
  NavigationButtonsContainer,
  SlideContainer,
  MainContainer,
} from "./TypeformPage.styles";
import WelcomeStep from "./components/WelcomeStep";
import EmailStep from "./components/EmailStep";
import MobileStep from "./components/MobileStep";
import ForestActivitiesStep from "./components/ForestActivitiesStep";
import MunicipalityStep from "./components/MunicipalityStep";
import TermsStep from "./components/TermsStep";
import ThankYouStep from "./components/ThankYouStep";
import styled from "styled-components";

const Typeform = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [direction, setDirection] = useState("next");
  const [isLoggedIn, setIsLoggedIn] = useState(Window.user_data.isLogged);
  const [showNavigation, setShowNavigation] = useState(false);
  const [formStatus, setFormStatus] = useState("idle");
  const [isTransitioning, setIsTransitioning] = useState(false);
  const totalSteps = isLoggedIn ? 2 : 6;
  const TRANSITION_COOLDOWN = 1000; // 1 second cooldown between transitions
  const [formData, setFormData] = useState({
    email: "",
    mobile: "",
    forestActivities: [],
    municipalities: [],
    acceptTerms: false,
  });
  const [errors, setErrors] = useState({});
  const [hasError, setHasError] = useState(false);

  const containerRef = useRef(null);
  const emailInputRef = useRef(null);
  const mobileInputRef = useRef(null);
  const municipalityInputRef = useRef(null);
  const checkboxRef = useRef(null);
  const navigationRef = useRef(null);

  useEffect(() => {
    setIsLoggedIn(Window.user_data.isLogged);
  }, [Window.user_data.isLogged]);

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === "Enter") {
        e.preventDefault();
        const currentField = getCurrentField(currentStep);
        if (currentField) {
          const isValid = validateField(currentField, formData[currentField]);
          if (!isValid) {
            setErrors((prev) => ({
              ...prev,
              [currentField]: getErrorMessage(currentField),
            }));
            return;
          }
        }
        nextStep();
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [currentStep, formData]);

  useEffect(() => {
    if (currentStep === 1 && formStatus === "idle") {
      trackEvent("started_fe");
      setFormStatus("active");
    }
  }, [currentStep, formStatus]);

  const trackEvent = (eventName, additionalData = {}) => {
    const { loggedInUserID, name, mobilePhoneNumber, regions } =
      Window.user_data;

    const baseTrackingData = {
      email: name || formData.email,
      mobile: mobilePhoneNumber || formData.mobile,
      forest_activities: formData.forestActivities,
      municipalities:
        formData.municipalities.map((m) => m.label) ||
        regions?.map((r) => r.label),
      step: currentStep,
      total_steps: totalSteps,
      isLoggedIn: isLoggedIn,
      user_id: loggedInUserID,
    };

    console.log("trackEvent", eventName, baseTrackingData);

    mixpanel.track(`typeform_${eventName}`, {
      ...baseTrackingData,
      ...additionalData,
    });
  };

  // Update the form abandonment tracking
  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (formStatus === "active") {
        trackEvent("abandoned_fe", {
          type: "page_close",
        });
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [formStatus]);

  useEffect(() => {
    const handleScroll = (e) => {
      e.preventDefault();
      if (e.deltaY > 0) {
        handleStepTransition("next");
      } else if (e.deltaY < 0) {
        handleStepTransition("prev");
      }
    };

    if (containerRef.current) {
      containerRef.current.addEventListener("wheel", handleScroll, {
        passive: false,
      });
    }

    return () => {
      if (containerRef?.current) {
        containerRef.current.removeEventListener("wheel", handleScroll);
      }
    };
  }, [currentStep, isTransitioning]);

  useEffect(() => {
    const focusCurrentInput = () => {
      setTimeout(() => {
        if (isLoggedIn) return;

        switch (currentStep) {
          case 1:
            emailInputRef?.current.input.current.focus();
            break;
          case 2:
            mobileInputRef?.current.input.current.focus();
            break;
          case 4:
            municipalityInputRef?.current?.input?.current?.focus();
            break;
        }
      }, 500);
    };

    focusCurrentInput();
  }, [currentStep, isLoggedIn]);

  // Keyboard navigation
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "ArrowUp") {
        e.preventDefault();
        handleStepTransition("prev");
      } else if (e.key === "ArrowDown") {
        e.preventDefault();
        handleStepTransition("next");
      } else if (e.key === "Enter") {
        e.preventDefault();
        handleStepTransition("next");
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [currentStep, formData, isTransitioning]);

  const handleStepTransition = async (newDirection) => {
    if (isTransitioning) return;

    setIsTransitioning(true);
    setDirection(newDirection);

    if (newDirection === "next") {
      await nextStep();
    } else {
      await prevStep();
    }

    // Reset the transition lock after cooldown
    setTimeout(() => {
      setIsTransitioning(false);
    }, TRANSITION_COOLDOWN);
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (name === "municipalities" && Array.isArray(value)) {
      setFormData((prev) => ({
        ...prev,
        municipalities: value,
      }));
      return;
    }

    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const validateField = (field, value) => {
    if (!value) return false;

    switch (field) {
      case "email":
        return validateEmail(value);
      case "mobile":
        return isValidPhoneNumber(value);
      case "forestActivities":
        return value && value.length > 0;
      case "municipalities":
        return value && Array.isArray(value) && value.length > 0;
      case "acceptTerms":
        return value === true;
      default:
        return true;
    }
  };

  const getErrorMessage = (field) => {
    switch (field) {
      case "email":
        return "Ange en giltig e-postadress";
      case "mobile":
        return "Ange ett giltigt mobilnummer";
      case "forestActivities":
        return "Du måste välja minst ett alternativ";
      case "municipalities":
        return "Du måste välja minst en kommun";
      case "acceptTerms":
        return "Du måste acceptera villkoren";
      default:
        return "";
    }
  };

  const getCurrentField = (step) => {
    if (step === 0) return null;

    if (isLoggedIn) {
      return "forestActivities";
    }

    switch (step) {
      case 1:
        return "email";
      case 2:
        return "mobile";
      case 3:
        return "forestActivities";
      case 4:
        return "municipalities";
      case 5:
        return "acceptTerms";
      default:
        return null;
    }
  };

  const nextStep = async () => {
    const currentField = getCurrentField(currentStep);

    if (currentField && !validateField(currentField, formData[currentField])) {
      setErrors((prev) => ({
        ...prev,
        [currentField]: getErrorMessage(currentField),
      }));
      return;
    }

    setDirection("next");
    const nextStepNumber = Math.min(currentStep + 1, totalSteps);

    if (nextStepNumber === totalSteps && formStatus !== "completed") {
      setFormStatus("submitting");
      await completeForm();
      setCurrentStep(nextStepNumber);
    } else {
      setCurrentStep(nextStepNumber);
    }
  };

  const prevStep = () => {
    const prevStepNumber = Math.max(currentStep - 1, 0);
    const prevField = getCurrentField(prevStepNumber);

    setErrors({});

    if (prevField && formData[prevField]) {
      const isValid = validateField(prevField, formData[prevField]);

      if (!isValid) {
        setErrors((prev) => ({
          ...prev,
          [prevField]: getErrorMessage(prevField),
        }));
      }
    }

    setCurrentStep(prevStepNumber);
    setDirection("prev");
  };

  const getProgressValue = () => {
    if (currentStep === 0) return 0;
    if (currentStep === totalSteps) return 100;

    if (isLoggedIn) {
      return currentStep === 1 ? 100 : 0;
    } else {
      return (currentStep / 5) * 100;
    }
  };

  const completeForm = async () => {
    try {
      setHasError(false);

      let userEmail;
      let userMobile;
      const userMunicipalities = formData.municipalities.map((m) => m.label);

      if (isLoggedIn) {
        const { name, mobilePhoneNumber } = Window.user_data;
        userEmail = name;
        userMobile = mobilePhoneNumber;
      } else {
        userEmail = formData.email;
        userMobile = formData.mobile;

        // 1. Check if user exists
        const responseEmail = await userExists(
          `email=${encodeURIComponent(userEmail)}`
        );
        const responseMobile = await userExists(
          `mobile_phone_number=${encodeURIComponent(userMobile)}`
        );
        const { exists: emailExists } = await responseEmail.json();
        const { exists: mobileExists } = await responseMobile.json();

        if (!emailExists && !mobileExists) {
          // 1.5. Create new user account if user doesn't exist
          const registerResponse = await leadsRegisterUser({
            email: userEmail,
            muni: formData.municipalities.map((m) => m.value),
            mobile_phone_number: userMobile,
            trackingContext: "typeform",
          });

          // Handle registration response
          switch (registerResponse.status) {
            case "SUCCESS":
              trackEvent("created_user_success_fe");
              break;
            case "PHONE_EXISTS":
              trackEvent("phone_already_exists_fe");
              return;
            case "EMAIL_EXISTS":
              trackEvent("email_already_exists_fe");
              return;
            default:
              trackEvent("registration_error", {
                status: registerResponse.status,
              });
              return;
          }
        }
      }

      // 2. Create typeform ticket
      const ticketResponse = await fetch("/create-typeform-ticket/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": Cookies.get("csrftoken"),
        },
        body: JSON.stringify({
          data: {
            email: userEmail,
            mobile: userMobile,
            forest_activities: formData.forestActivities,
            munis: userMunicipalities,
          },
        }),
      });

      if (!ticketResponse.ok) {
        setHasError(true);
        console.error("Failed to create typeform ticket", {
          email: userEmail,
        });
        trackEvent("ticket_creation_error", {
          status: ticketResponse.status,
        });
        setFormStatus("error");
      } else {
        trackEvent("completed_fe");
        setShowNavigation(true);
        document.getElementsByTagName("body")[0].classList.toggle("nav-update");
        setFormStatus("completed");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      trackEvent("submission_error", {
        error_message: error.message,
      });
      setFormStatus("error");
    }
  };

  const renderStep = () => {
    let steps = [];
    if (isLoggedIn) {
      steps = steps.concat([
        <WelcomeStep onNext={nextStep} isLoggedIn={isLoggedIn} />,
        <ForestActivitiesStep
          onNext={nextStep}
          stepNumber={1}
          initialActivities={formData.forestActivities}
          onActivitiesChange={(activities) => {
            setFormData((prev) => ({
              ...prev,
              forestActivities: activities,
            }));
            setErrors((prev) => ({ ...prev, forestActivities: "" }));
          }}
          isLoading={formStatus === "submitting"}
        />,
        <ThankYouStep isError={hasError} />,
      ]);
    } else {
      steps = steps.concat([
        <WelcomeStep onNext={nextStep} isLoggedIn={isLoggedIn} />,
        <EmailStep
          onNext={nextStep}
          stepNumber={1}
          initialEmail={formData.email}
          onEmailChange={handleInputChange}
          error={errors.email}
          emailInputRef={emailInputRef}
        />,
        <MobileStep
          onNext={nextStep}
          stepNumber={2}
          initialMobile={formData.mobile}
          onMobileChange={handleInputChange}
          error={errors.mobile}
          mobileInputRef={mobileInputRef}
        />,
        <ForestActivitiesStep
          onNext={nextStep}
          stepNumber={3}
          initialActivities={formData.forestActivities}
          onActivitiesChange={(activities) => {
            setFormData((prev) => ({
              ...prev,
              forestActivities: activities,
            }));
            setErrors((prev) => ({ ...prev, forestActivities: "" }));
          }}
        />,
        <MunicipalityStep
          onNext={nextStep}
          stepNumber={4}
          initialMunicipality={formData.municipalities}
          onMunicipalityChange={handleInputChange}
          error={errors.municipalities}
          municipalityInputRef={municipalityInputRef}
        />,
        <TermsStep
          onNext={nextStep}
          stepNumber={5}
          initialTerms={formData.acceptTerms}
          onTermsChange={handleInputChange}
          error={errors.acceptTerms}
          checkboxRef={checkboxRef}
          isLoading={formStatus === "submitting"}
        />,
        <ThankYouStep isError={hasError} />,
      ]);
    }

    return (
      <div
        style={{ position: "relative", height: "100vh", overflow: "hidden" }}
      >
        <TransitionGroup>
          <CSSTransition
            key={currentStep}
            timeout={500}
            classNames={{
              enter: `slide-${direction}-enter`,
              enterActive: `slide-${direction}-enter-active`,
              exit: `slide-${direction}-exit`,
              exitActive: `slide-${direction}-exit-active`,
            }}
          >
            <SlideContainer style={styles.slideContainer}>
              <Col xs={11} md={8} lg={8} xl={6} xxl={4}>
                {steps[currentStep]}
              </Col>
            </SlideContainer>
          </CSSTransition>
        </TransitionGroup>
      </div>
    );
  };

  const ProgressBar = ({ progress }) => (
    <div style={styles.progressBarContainer}>
      <div style={{ ...styles.progressBar, width: `${progress}%` }} />
    </div>
  );

  const NavigationButtons = () => {
    const currentField = getCurrentField(currentStep);
    const isValid = currentField
      ? validateField(currentField, formData[getCurrentField(currentStep)])
      : true;
    const showButtons = currentStep > 0 && currentStep < 6;

    if (!showButtons) return null;

    return (
      <NavigationButtonsContainer>
        <Button
          variant="link"
          className="d-flex align-items-center justify-content-center"
          style={styles.navButton}
          onClick={() => handleStepTransition("prev")}
          disabled={isTransitioning}
        >
          <img
            width="24px"
            height="24px"
            src={Window.static_path + "img/icons/arrow-black.svg"}
            alt="Previous"
            style={{
              transform: "rotate(-90deg)",
              opacity: isTransitioning ? 0.5 : 1,
            }}
          />
        </Button>
        <Button
          variant="link"
          className="d-flex align-items-center justify-content-center"
          style={styles.navButton}
          onClick={() => handleStepTransition("next")}
          disabled={!isValid || isTransitioning}
        >
          <img
            width="24px"
            height="24px"
            src={Window.static_path + "img/icons/arrow-black.svg"}
            alt="Next"
            style={{
              transform: "rotate(90deg)",
              opacity: isValid && !isTransitioning ? 1 : 0.5,
            }}
          />
        </Button>
      </NavigationButtonsContainer>
    );
  };

  const StepIndicator = () => {
    const isFirstOrLastStep = currentStep === 0 || currentStep === totalSteps;
    if (isFirstOrLastStep) return null;

    if (isLoggedIn) {
      return currentStep === 1 ? (
        <div style={styles.stepIndicatorContainer}>
          <span style={styles.stepIndicatorText}>Steg 1/1</span>
        </div>
      ) : null;
    }

    return (
      <div style={styles.stepIndicatorContainer}>
        <span style={styles.stepIndicatorText}>Steg {currentStep}/5</span>
      </div>
    );
  };

  const isWelcomeStep = currentStep === 0;

  return (
    <div style={styles.wrapper}>
      {showNavigation && (
        <div>
          <Navigation ref={navigationRef} />
        </div>
      )}

      {isWelcomeStep && !showNavigation && (
        <div style={styles.logoContainer}>
          <Image
            className="primary-core-filter"
            src={`${Window.static_path}img/icons/treebula_lockup_charcoal.svg`}
            style={{ width: "180px" }}
            alt="Logo"
            style={{ maxWidth: "150px" }}
          />
        </div>
      )}

      <MainContainer ref={containerRef}>
        {!isWelcomeStep && !showNavigation && (
          <>
            <StepIndicator />
            <ProgressBar progress={getProgressValue()} />
          </>
        )}
        {renderStep()}
        <NavigationButtons />
      </MainContainer>
    </div>
  );
};

const styles = {
  wrapper: {
    backgroundColor: "#023436",
  },
  logoContainer: {
    padding: "24px",
  },
  mainContainer: {
    // backgroundColor: "#023436",
    color: "white",
    fontFamily: '"Bw Nista Grotesk"',
  },
  button: {
    borderColor: "#DFFF5D",
    color: "#000",
    fontWeight: "500",
    transition: "transform 0.2s",
    border: "none",
    outline: "none",
  },
  timerIcon: {
    width: "16px",
    height: "16px",
    backgroundColor: "white",
    borderRadius: "50%",
  },
  small: {
    fontSize: "14px",
    color: "#DFFF5D",
    fontWeight: "600",
  },
  selectButton: {
    border: "1px solid white",
    cursor: "pointer",
    transition: "all 0.2s",
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.1)",
    },
  },
  selectedButton: {
    backgroundColor: "hwb(72deg 36% 0%)",
    border: "1px solid #DFFF5D",
    color: "#000",
  },
  stepNumber: {
    fontSize: "16px",
  },
  mainTitle: {
    fontSize: "36px",
  },
  title: {
    fontSize: "24px",
  },
  subtitle: {
    fontSize: "18px",
    lineHeight: "24px",
    fontWeight: "400",
    color: "rgba(255, 255, 255, 0.8)",
  },
  enterText: {
    fontSize: "14px",
  },
  terms: {
    fontSize: "14px",
    fontWeight: "400",
  },
  link: {
    fontSize: "14px",
    fontWeight: "500",
    textDecoration: "underline",
    color: "white",
  },
  errorMessage: {
    fontSize: "16px",
    fontWeight: "400",
    color: "red",
  },
  slideContainer: {
    position: "absolute",
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  progressBarContainer: {
    position: "fixed",
    top: "48px",
    left: 0,
    width: "100%",
    height: "4px",
    backgroundColor: "rgba(255, 255, 255, 0.2)",
    zIndex: 1000,
  },
  progressBar: {
    height: "100%",
    backgroundColor: "#DFFF5D",
    transition: "width 0.5s cubic-bezier(0.4, 0, 0.2, 1)",
    transformOrigin: "left",
  },
  navButton: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    backgroundColor: "#DFFF5D",
    border: "none",
    padding: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: "transform 0.2s, opacity 0.2s",
    "&:hover:not(:disabled)": {
      transform: "scale(1.1)",
      backgroundColor: "#DFFF5D",
    },
    "&:disabled": {
      backgroundColor: "#DFFF5D",
      opacity: 0.5,
    },
  },
  stepIndicatorContainer: {
    position: "fixed",
    top: "16px",
    left: "50%",
    transform: "translateX(-50%)",
    zIndex: 1001,
    textAlign: "center",
  },
  stepIndicatorText: {
    color: "white",
    fontSize: "14px",
    fontWeight: "500",
  },
};

const ErrorMessage = styled.p`
  color: red;
  font-size: 16px;
  font-weight: 400;
  margin-top: 1rem;
  text-align: center;
`;

export default Typeform;
