import { Button } from "react-bootstrap";
import styled from "styled-components";

export const SlideContainer = styled.div`
  &.slide-next-enter {
    transform: translateY(100%);
    opacity: 0;
  }
  &.slide-next-enter-active {
    transform: translateY(0);
    opacity: 1;
    transition: all 500ms ease-in-out;
  }
  &.slide-next-exit {
    transform: translateY(0);
    opacity: 1;
  }
  &.slide-next-exit-active {
    transform: translateY(-100%);
    opacity: 0;
    transition: all 500ms ease-in-out;
  }

  &.slide-prev-enter {
    transform: translateY(-100%);
    opacity: 0;
  }
  &.slide-prev-enter-active {
    transform: translateY(0);
    opacity: 1;
    transition: all 500ms ease-in-out;
  }
  &.slide-prev-exit {
    transform: translateY(0);
    opacity: 1;
  }
  &.slide-prev-exit-active {
    transform: translateY(100%);
    opacity: 0;
    transition: all 500ms ease-in-out;
  }
`;

export const NavigationButtonsContainer = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  z-index: 10;
  

  @media (max-width: 576px) {
    bottom: 75px;
  }

  button {
    width: 20px;
    height: 20px;
  }
`;

export const MainContainer = styled.div`
  background-color: #023436;
  color: white;
  font-family: "Bw Nista Grotesk";
  overflow: hidden;
`;
