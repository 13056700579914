import React from 'react';
import Cookies from 'js-cookie';
import styled from "styled-components";

import Modal from 'react-bootstrap/Modal';
import Collapse from 'react-bootstrap/Collapse';

import VBSearchInput from '@virkesborsen/vb-js-commons/lib/components/new/vbSearchInput';

import { PropsData, VisaValda, Navigation } from '../context';
import { getPropStatus, fetchPropertyData, searchProps, getProp, generateProp, unlockProperty, generateForestandProperty } from '../../../api/services/property';
import SettingsPanel from './SettingsPanel';

const PropItem = styled.div`
    .lock-icon {
        background-image: url(${props => props.lockIcon});
    }

    &:hover {
        .lock-icon {
            background-image: url(${props => props.unlockIcon});
        }
    }
`
const LockedUnlockedIcon = styled.div`
    width: 20px;
    height: 20px;
    background-size: contain;
    display: inline-block;
    `
const CheckIcon = styled.div`
    width: 25px;
    height: 25px;
    &.checked {
        background-size: contain;
        background-image: url(${props => props.checkmarkIcon});
    }
    `
const WarningIcon = styled.div`
    width: 20px;
    height: 20px;
    background-size: contain;
    background-image: url(${props => props.risksIcon});
    `
const ForestandFileIcon = styled.div`
    width: 18px;
    height: 25px;
    background-size: cover;
    background-image: url(${props => props.forestIcon});
    `


export default function PropSelector() {
    const propsContext = React.useContext(PropsData);
    const visaValdaContext = React.useContext(VisaValda);
    const NavigationContext = React.useContext(Navigation);

    const [loading, setLoading] = React.useState(false);

    const [collapsed, setCollapsed] = React.useState(true);
    const [view, setView] = React.useState('props');
    const [searchString, setSearchString] = React.useState('');
    const [searchResult, setSearchResult] = React.useState([]);
    const [usedProps,setUsedProps] = React.useState(Window.react_data.used_props);
  
    const [errorModal, setErrorModal] = React.useState(false);
    const [unlockPropModal, setUnlockPropModal] = React.useState(false);
    const [infoModal, setInfoModal] = React.useState({show: false});
    const [forestandCreateModal,setForestandCreateModal]=React.useState({show:false});

    const [focus, setFocus] = React.useState('');
    const [searchTimeout, setSearchTimeout] = React.useState(null);
    const [selectedProperty, setSelectedProperty] = React.useState(null);

    const [settingsVisibility, setSettingsVisibility] = React.useState(false);
    const [fileError, setFileError] = React.useState("");

    const imageBaseUrl  = `${Window.static_path}img/icons`

    React.useEffect(() => {
        if(propsContext.props.length == 0) {
            setView('add')
        }

        let CookieProps = Cookies.get('activeProps')
        if(CookieProps && CookieProps != '') {
            CookieProps = CookieProps.split(',')
        } else {
            CookieProps = null
        }

        if(!CookieProps) {
            setCollapsed(false)
        }

        propsContext.props.map(async (p) => {
            if(p.status == 'data' && !p.data) {
                let preSelected = CookieProps && CookieProps.includes(p.ref.toString()) ? true : false
                p.checked = preSelected
                p.active = preSelected
                fetchPropData(p)
            }

            if(p.status == 'running') {
                checkPropStatus(p)
            }
        })
    }, []);


    const setActiveCookie = () => {
        const activeProps = propsContext.props.filter(p => p.active && p.data.sections.length <= 300)
      
        Cookies.set('activeProps', activeProps.map(p => p.ref).join(','))
    }

    const fetchPropData = async (p) => {
        const resp = await fetchPropertyData(p.ref, p.type)
        const data = resp.data

        data && typeof data == 'object' ? p.data = data : p.status = 'warning'

        if(data) {
            if(p.type == 'PROP') {
                const getPropResp = await getProp(p.ref)
                const propData = getPropResp.data

                if(propData) {
                    p.border = propData.geojson.geometry.coordinates
                    p.area = propData.area
                }
            }
            
            if('data' in p) {
                let volume = 0
                data.sections.map(s => volume += s.volume)
                p.volume = volume
            }
        }
        
        propsContext.setOneProp(p)
    }

    const checkPropStatus = async (p) => {
        const resp = await getPropStatus(p.ref)
        const status = resp.data

        if(status == 'broken' || status == 'data') {
            p.status = status
            propsContext.setOneProp(p)
            if(status == 'data') {
                fetchPropData(p)
            }
        } else {
            setTimeout(() => {
                checkPropStatus(p); 
            }, 2000);
        }
    }

    const addProp = async (p) => {
        setView('props')
       
        const remainingProps = Window.react_data.allowed_props - propsContext.props.length
  
        if (remainingProps > 0 || Window.user_data.isAdmin ){          
            setLoading({prop: p.beteckning})

            const getPropResponse = await getProp(p.registerenhet)
            const createdProp = await generateProp({geometry: getPropResponse.data.geojson.geometry, prop: p})
            if(createdProp.data.status == 'to_small') {
                setInfoModal({message: `Fastighet ${p.beteckning} är för liten`, show: true})
            }

            if(createdProp.data.status == 'set_visible' || createdProp.data.status == 'running') {
                propsContext.setOneProp(createdProp.data.data)
                checkPropStatus(createdProp.data.data)                
            }              
            setLoading(false)

        } else {
            setInfoModal({title: 'Maximalt antal egenskaper använt', message: 'Du har redan använt det maximala antalet egenskaper och kan inte lägga till fler', show: true}) 
        }
    }

    const handlePropClick = (p) => {

        if(p.locked && (p.status == 'data' || p.status == 'running')) {
            setUnlockPropModal(p)
        }
        
        if(!p.locked && ('data' in p)) {
            p.checked = !p.checked
            propsContext.setOneProp(p)
        }

        if(p.status == 'warning' || p.status == 'broken') {
            setInfoModal({title: 'Tyvärr kunde vi inte generera din skogsbruksplan', message: <>Det verkar ha uppstått ett problem med att skapa skogsbruksplanen för {p.designation}. Kontakta oss på <a className='fw-bold' href='mailto:info@treebula.se'>info@treebula.se</a> eller <a className='fw-bold' href='tel:00468339944'>08-33 99 44</a> så hjälper våra rådgivare dig att lösa detta så snabbt som möjligt.</>, show: true})
        }
    }

    const propStart = (p) => {
        if(p.status == 'data' || p.status == 'running') {
            if(p.loading) {
                return <div className="spinner-border" style={{width: "20px", height: "20px"}} role="status"></div>
            }
            if('data' in p && p.locked) {
                return <LockedUnlockedIcon className='lock-icon'/>
            }
            if((p.status == 'data' && !('data' in p)) || p.status == 'running') {
                return <div className="spinner-border" style={{width: "20px", height: "20px"}} role="status"></div>
            }
            if('data' in p) {
                return <CheckIcon checkmarkIcon={`${imageBaseUrl}/checkmark.svg`} className={`d-flex border border-2 ${p.checked ? 'checked' : ''}`} />
            }
        } else {
            return <WarningIcon risksIcon={`${imageBaseUrl}/risks.svg`}  className={`${p.status == 'broken' ? 'danger-filter' : p.status == 'warning' ? 'warning-filter' : ''}`} />
        }
    }

    const propsInfoText = () => { 
        let desc = <>Du har just nu {usedProps}/{Window.react_data.allowed_props} skogsbrukplaner aktiverade. Kontakta oss om du behöver fler.</>
        return desc
    }

    const visaValdaClicked = () => {
        let props = propsContext.props
        props.map(p => {
            if(p.checked) {
                p.active = true
            } else {
                p.active = false
            }
        })
        propsContext.setProps(props)
        setActiveCookie();
        visaValdaContext.setVisaValda(true)
        setCollapsed(true);

        let newView = {comp: NavigationContext.view.comp, active: propsContext.props.filter(p => p.active).map(p => p.ref)}
        history.pushState(newView, '', '')
        NavigationContext.setView(newView)
    }

    const setSelectorTitle = () => {
        let activeProps = propsContext.props.filter(p => p?.active)
        if(activeProps.length == 0) {
            return 'Välj fastighet'
        } else if(activeProps.length == 1) {
            return <span className='text-nowrap overflow-hidden'>{activeProps[0].designation.length < 21 ? activeProps[0].designation : activeProps[0].designation.substring(0, 20)+'...'}</span>
        } else {
            return `${activeProps.length} fastigheter valda`
        }
    }

    const generateForestandSBP = async fba => {
        var data = new FormData()
        data.append('file', fba.file)
        if (fba.lm_info){
            data.append('lmID', fba.lm_info.registerenhet)
            data.append('designation_overwrite', fba.lm_info.beteckning)
        }

        setLoading({sbo: fba.file.name})

        const resp = await generateForestandProperty(data)

        if(resp.ok) {
            let volume = 0
            resp.data.data.sections.map(s => volume += s.volume)
            resp.data.volume = volume
            await propsContext.setOneProp(resp.data)
            setLoading(false)
        } else {
            setLoading(false)
            setInfoModal({title: 'Tyvärr kunde vi inte generera din skogsbruksplan', message: <>Det verkar ha uppstått ett problem med att skapa skogsbruksplanen för {fba.file.name}. Kontakta oss på <a className='fw-bold' href='mailto:info@treebula.se'>info@treebula.se</a> eller <a className='fw-bold' href='tel:00468339944'>08-33 99 44</a> så hjälper våra rådgivare dig att lösa detta så snabbt som möjligt.</>, show: true})
        }
    }

    const sortProps = (list) => {
        return list.sort((a, b) => {
            // Prioritize active items
            if (a.active !== b.active) {
                return b.active - a.active;
            }
        
            // Prioritize status
            const statusOrder = ['data', 'running', 'warning', 'broken'];
            const statusA = statusOrder.indexOf(a.status);
            const statusB = statusOrder.indexOf(b.status);
            if (statusA !== statusB) {
                return statusA - statusB; 
            }
        
            // Within the same status, prioritize unlocked items
            if (a.locked !== b.locked) {
                return a.locked - b.locked;
            }
        
            // If all else fails, default to alphabetical order by name
            return a.designation.localeCompare(b.designation);
        })
    }

    return <div className='d-flex flex-column bg-neutral-cloud shadow rounded user-select-none p-2'>
        <div className='bg-white p-2'>
            <div id='propSelector' role='button' className='d-flex justify-content-between neutral-pebble' onClick={() => setCollapsed(!collapsed)}>{setSelectorTitle()}<div className={`${collapsed ? 'rotate-50' : ''}`} style={{transition: 'transform .2s ease-in-out'}}><img src={`${Window.static_path}img/icons/dropdown-arrow.svg`} height='20px' width='20px' /></div></div>


            <Collapse in={!collapsed}>
                <div>
                    {view == 'props' && <>
                        <p className='n-fs-6 mb-0 mt-2'>{propsInfoText()}</p>
                        <button className='n-btn btn-forest-green btn-sm rounded w-100 my-1' onClick={() => setView('add')}>Lägg till fastighet +</button>
                        <div className='overflow-auto' style={{maxHeight: 'calc(-470px + 100vh)'}}>
                            {propsContext.props && propsContext.props.length > 0 && sortProps(propsContext.props).map(p => (
                                <div key={p?.label} className={`d-flex ${p?.active ? 'bg-primary-core hover-bg-primary-core-200' : p.status == 'data' ? 'hover-bg-primary-core-200' : 'grey bg-neutral-cloud hover-bg-neutral-cloud-300'}`}>
                                    <PropItem lockIcon={`${imageBaseUrl}/lock.svg`} unlockIcon={`${imageBaseUrl}/unlocked.svg`} role='button' onClick={() => handlePropClick(p)} className='d-flex justify-content-start align-items-center n-fs-6 w-100 p-2'>
                                        <div className='d-flex justify-content-center' style={{width: '25px'}}>{propStart(p)}</div>{p.type == 'SBO' && <ForestandFileIcon forestIcon={`${imageBaseUrl}/forestand-colored.svg`} className='ms-1' />}<span className='ms-1'>{p.designation}</span>
                                    </PropItem>
                                    {!p.loading && <div className='d-flex align-items-center p-2'>
                                        <img src={Window.static_path + 'img/icons/cog.svg'} style={{height:"25px", width:"25px" , cursor:"pointer"}} onClick={()=>{setSelectedProperty(p); setSettingsVisibility(true); setCollapsed(true)}}/>
                                    </div>}
                                </div>
                            ))}
                            {loading && 'sbo' in loading && <div className='d-flex grey bg-neutral-cloud hover-bg-neutral-cloud-300'>
                                <PropItem className='d-flex justify-content-start align-items-center n-fs-6 w-100 p-2'>
                                    <div className='d-flex justify-content-center' style={{width: '25px'}}>
                                        <div className="spinner-border" style={{width: "20px", height: "20px"}} role="status"></div>
                                    </div>
                                    <span className='ms-1'>{loading.sbo}</span>
                                </PropItem>
                            </div>}
                            {loading && 'prop' in loading && <div className='d-flex grey bg-neutral-cloud hover-bg-neutral-cloud-300'>
                                <PropItem className='d-flex justify-content-start align-items-center n-fs-6 w-100 p-2'>
                                    <div className='d-flex justify-content-center' style={{width: '25px'}}>
                                        <div className="spinner-border" style={{width: "20px", height: "20px"}} role="status"></div>
                                    </div>
                                    <span className='ms-1'>{loading.prop}</span>
                                </PropItem>
                            </div>}
                        </div>
                        <button onClick={() => visaValdaClicked()} className='n-btn btn-core btn-sm rounded w-100 mt-2'>Visa valda</button>
                        <div className='bg-supp-spruce w-100 my-2' style={{height: '2px'}}></div>
                        {loading ? <p className='n-fs-7 mb-0'>Vi bearbetar din skogsbruksplan fil.</p> : <p role='button' className='n-fs-7 mb-0' onClick={() => setForestandCreateModal({show:true})}>Du kan genom att klicka <b>här</b> importera en skogsbruksplan i formatet forestand (.xml)</p>}

                    </>}
                    
                    {view == 'add' && <div>
                        <p className='fw-bold mt-2'>Lägg till fastighet <button className='n-btn btn-forest-green btn-sm rounded float-end py-1 px-2' onClick={() => setView('props')}>Avbryt</button></p>
                        <p className='n-fs-7 mb-2'>Skriv in din fastighetsbeteckning nedan (Exempel Eskilstuna Kullen 1:2)</p>

                        <VBSearchInput
                            id='propSearchInput'
                            placeholder='Sök eller eg: Sevedstorp 1:8'
                            initial={searchString}
                            hasFocus={focus}
                            onBlur={() => setFocus('')}
                            onChange={async (val) => {
                                setFocus('propSearchInput')
                                setSearchString(val)

                                if(searchTimeout) {
                                    clearTimeout(searchTimeout)
                                }
                                const timeout = setTimeout(async () => {
                                    const searchResult = await searchProps(val)
                                    setSearchResult(searchResult.data)
                                }, 400)

                                setSearchTimeout(timeout)
                            }}
                            searchResult={
                                searchString !== '' && 
                                    searchResult && searchResult.length > 0 ? 
                                    searchResult.map(prop => <p className="option w-100 ps-2" onClick={() => addProp(prop) }>{prop.beteckning}</p>) : 
                                    <p>Inga träffar på den sökningen.</p>
                            }
                        />
                        {loading ? <p className='n-fs-7 mt-1 mb-0'>Vi bearbetar din skogsbruksplan fil.</p> : <p role='button' className='n-fs-7 mt-1 mb-0' onClick={() => {setView('props'); setForestandCreateModal({show:true})}}>Du kan genom att klicka <b>här</b> importera en skogsbruksplan i formatet forestand (.xml)</p>}
                    </div>}
                </div>
            </Collapse>
        </div>
        <SettingsPanel visible={settingsVisibility}  property={selectedProperty} setVisibility={()=>{setSettingsVisibility(false)}} />

        <Modal show={errorModal} onHide={() => setErrorModal(false)} contentClassName="bg-neutral-cloud-200 p-2" centered>
            <Modal.Header className="align-items-start border-0" closeButton>
                {errorModal.title ? errorModal.title : <h4 className="mb-0">Oops! Något gick fel.</h4>}
            </Modal.Header>
            <Modal.Body>
                {errorModal.message ? errorModal.message : <p className='n-fs-5 neutral-charcoal mb-0'>Ring <a href="tel:08339944" className="fw-bold neutral-charcoal">08-33 99 44</a> så hjälper vi dig vidare.</p>}
                <h2 className="n-fs-4 neutral-charcoal">Oops! Något gick fel.</h2>
            </Modal.Body>
            <Modal.Footer className="justify-content-end border-0">
                <button type="button" className="n-btn btn-forest-green" onClick={() => setErrorModal(false)}>Fortsätt</button>
            </Modal.Footer>
        </Modal>

        <Modal show={unlockPropModal} onHide={() => setUnlockPropModal(false)} contentClassName="bg-neutral-cloud-200 p-2" centered>
            <Modal.Header className="align-items-start border-0" closeButton>
                <h4 className="mb-0">Lås upp en ny fastighet</h4>
            </Modal.Header>
            <Modal.Body>
                <p className='n-fs-5 neutral-charcoal mb-0'>Är du säker på att du vill låsa upp {unlockPropModal.designation}?</p>
            </Modal.Body>
            <Modal.Footer className="justify-content-between border-0">
                <button type="button" className="n-btn btn-forest-green" onClick={() => setUnlockPropModal(false)}>Lås inte upp</button>
                <button type="button" className="n-btn btn-core" onClick={async () => {
                    setUnlockPropModal(false)
                    let p = unlockPropModal
                    p.loading = true
                    propsContext.setOneProp(p)
                    setUsedProps(usedProps + 1)

                    const resp = await unlockProperty(unlockPropModal.ref)
                    const json = resp.data
                    
                    p.loading = false
                    if(resp.ok) {
                        p.locked = false
                        propsContext.setOneProp(p)
                    } else {
                        propsContext.setOneProp(p)
                        if('error' in json && json.error == "exceeding_delete_limit" ) {
                            setErrorModal({message: <>Vårt system ser att du är intresserad av flera fastigheter. Hör av dig till oss på <a href="mailto:info@virkesborsen.se:">info@virkesborsen.se</a> så hjälper vi dig.</>})
                        } else {
                            setErrorModal(true)
                        }
                    }
                }}>Lås upp</button>
            </Modal.Footer>
        </Modal>

        <Modal show={infoModal.show} onHide={() => setInfoModal({...infoModal, show: false})} contentClassName="bg-neutral-cloud-200 p-2" centered>
            <Modal.Header className="align-items-start border-0" closeButton>
                <h4 className="mb-0">{infoModal.title ? infoModal.title : 'Något gick fel'}</h4>
            </Modal.Header>
            <Modal.Body>
                <p className='n-fs-5 neutral-charcoal mb-0'>{infoModal.message ? infoModal.message : 'Kontakta oss och får hjälp direkt från våra rådgivare.'}</p>
            </Modal.Body>
            <Modal.Footer className="justify-content-end border-0">
                <button type="button" className="n-btn btn-forest-green" onClick={() => setInfoModal({...infoModal, show: false})}>Okej</button>
            </Modal.Footer>
        </Modal>

        <Modal show={forestandCreateModal?.show} onHide={() => setForestandCreateModal({...forestandCreateModal, show: false})} size="md" contentClassName="bg-neutral-cloud-200 p-2" centered>
            <Modal.Header className="align-items-start border-0" closeVariant="white" closeButton>
                <h2 className="n-fs-4 mb-0">Importera en skogsbruksplan (.xml)</h2>
            </Modal.Header>
            <Modal.Body >
                <div className="mb-4">
                    <label className="d-block fw-medium" for="forestandPropFile">Välj fil att ladda upp</label>
                    <div className="d-flex flex-column align-items-start">
                        <input type="file" id="forestandPropFile" className="position-absolute opacity-0" style={{width: "20px"}} onChange={(e) => {                 
                            if (e.target.files[0]){
                                const file = e.target.files[0]
                                const fileType = file.name.split('.').pop().toLowerCase();
                                if (fileType !== 'xml'){
                                    setFileError("Endast forestan XML-filer är tillåtna");
                                    setForestandCreateModal({...forestandCreateModal, file: null});
                                }
                                else{
                                    const reader = new FileReader()
                                    
                                    reader.onload=(event)=>{
                                        const parser = new DOMParser();
                                        const xmlDoc = parser.parseFromString(event.target.result, "text/xml");
                                        if (!xmlDoc.getElementsByTagNameNS("*","ForestandData").length){
                                            setFileError("Ogiltig XML-fil. Endast ForestandData XML är tillåten.")
                                            setForestandCreateModal({...forestandCreateModal, file: null});
                                        }
                                        else{
                                            setFileError("")
                                            setForestandCreateModal({...forestandCreateModal, file: file})
                                        }

                                    };
                                    reader.readAsText(file)
                                }
                            }
                            }} /> 
                        {forestandCreateModal.file && <a className='mt-2 bg-supp-forest-green-300 p-2 me-2 white rounded-pill n-fs-7 d-inline-block'>{forestandCreateModal?.file?.name}
                        <img src={Window.static_path + 'img/icons/X.svg'} className='ms-2 white-filter'role='button'width='16px' height='16px'
                          onClick={() => {setForestandCreateModal({ ...forestandCreateModal, file: null });
                        }}
                        /> 
                        </a>}           
                        <label className="n-btn btn-forest-green btn-sm mt-3" htmlFor="forestandPropFile" role='button'> <img className={`me-2`} style={{ width: "18px", height: "18px" }} src={Window.static_path + "img/icons/icon-left.svg"} />Välj filer</label>
                    </div>
                    {fileError && <p className="text-danger n-fs-7 mt-1">{fileError}</p>}
                </div>
                <div>
                    <p className='n-fs-6 mb-1'>Hitta ansluten fastighet hos Lantmäteriet:</p>
                    <p className='n-fs-6'>Vald fastighet: <b>{forestandCreateModal?.lm_info?.beteckning ? forestandCreateModal.lm_info.beteckning: ''}</b></p>
                    <VBSearchInput
                        id='propSearchInput'
                        initial = {forestandCreateModal.searchText}
                        placeholder='Sök eller eg: Sevedstorp 1:8'
                        onChange={async (val) => {
                            setForestandCreateModal({...forestandCreateModal,searchText:val})

                            if(searchTimeout) {
                                clearTimeout(searchTimeout)
                            }
                            const timeout = setTimeout(async () => {
                                const searchResult = await searchProps(val)
                                setForestandCreateModal({...forestandCreateModal, searchResult: searchResult.data})
                            }, 400)

                            setSearchTimeout(timeout)
                        }}
                        searchResult={
                            forestandCreateModal?.searchText !== '' && 
                                forestandCreateModal?.searchResult && forestandCreateModal.searchResult.length > 0 ? 
                                forestandCreateModal?.searchResult?.map(prop => <p className="option w-100 ps-2" onClick={() => setForestandCreateModal({...forestandCreateModal, lm_info: prop})}>{prop.beteckning}</p>) : 
                                <p>Inga träffar på den sökningen.</p>
                        }/>
                </div>
            </Modal.Body>
            <Modal.Footer className="justify-content-between justify-content-lg-end border-0">
            <button type="button" className="n-btn btn-forest-green btn-ghost btn-sm" onClick={() => {setForestandCreateModal({...forestandCreateModal, show: false}); setFileError("")}}>Avbryt</button>
            <button type="button" className="n-btn btn-core btn-sm" onClick={() => { 
                if (!forestandCreateModal.file){
                    setFileError("Vänligen välj en fil att ladda upp.");
                }else{
                    setFileError("")
                    generateForestandSBP({file: forestandCreateModal.file, lm_info: forestandCreateModal.lm_info});
                    setForestandCreateModal({...forestandCreateModal, show: false});
                }}}>Spara</button>
                 </Modal.Footer>
        </Modal>
    </div>
}