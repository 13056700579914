import React, { useState } from "react";

import { Navigation } from '../context';

import { Table, Accordion, useAccordionButton, AccordionContext,AccordionButton } from "react-bootstrap";
import styled from "styled-components";

const ScrollableDiv = styled.div`
    overflow-x: auto;
    white-space: nowrap;
    
    /* For Webkit browsers (Chrome, Safari) */
    ::-webkit-scrollbar {
        height: 8px; 
    }

    ::-webkit-scrollbar-thumb {
        background-color: #023436; 
        border-radius: 4px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background-color: #021d2e; 
    }

    ::-webkit-scrollbar-track {
        background-color: #f1f1f1;
    }

    /* For Firefox */
    scrollbar-width: thin;
    scrollbar-color: #023436 #f1f1f1; 
`;

const StyledAccordionButton = styled(AccordionButton)`
    background-color: ${({ sectionIndex }) => sectionIndex % 2 === 0 ? "#ffffff" : "#e9e9e9"};
    padding: 16px 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    /* Remove the default arrow */
    &::after {
        display: none;
    }
`;

const StyledDropdownIcon = styled.div`
    transition: transform 0.2s ease-in-out;
    transform: ${({ collapsed }) => (collapsed ? 'rotate(180deg)' : 'rotate(0deg)')};
`;

// Generic DepartmentSection component 
// To get format of data use overblick propsContext for DepartmentSection
export default function DepartmentSection({ propsContext,table,sortOptions,accordionTitle,accordionData,mainTitle,mainSubTitle,
    tableBackgroundColor = "bg-neutral-cloud-600",
    evenAccordionItem = "#ffffff",
    oddAccordionItem = "#e9e9e9"
 }) {

    const NavigationContext = React.useContext(Navigation);

    const [activeTab, setActiveTab] = useState();
    const [propDataSelected, setPropDataSelected] = useState(null);
    const [isMobile, setIsMobile] = React.useState(window.innerWidth < 768);

    React.useEffect(() => {

        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const openSectionInKarta = sectionNR => {
        let newObj = {comp: 'karta', section: sectionNR, active: NavigationContext.view.active}
        history.pushState(newObj, '', ''); 
        NavigationContext.setView(newObj)
    }

    // Avdelningsbeskrivning table
    // Desktop view
    const DepartmentTable = () => (
        <Table striped hover className="position-relative">
            <thead className="position-sticky" style={{ top: "-30px", height: "60px" }}>
                <tr className={tableBackgroundColor}>
                    {propsContext.length > 1 ? <th className='fw-medium' scope="col">Fastighet </th> : null}
                    {table.map((col, index) => (<th key={index} className='fw-medium' scope="col">{col.label}</th>))}
                </tr>
            </thead>

            <tbody>
                {propsContext.map(prop => {
                    if (prop.data) {
                        return prop.data.sections.sort((a, b) => a[sortOptions] - b[sortOptions]).map((section,index) => {
                            return <tr role='button' onClick={() => openSectionInKarta(section.nr)} key={index}>
                                {propsContext.length > 1 ? <td>{prop.designation}</td> : null}
                                 {table.map((col, colIndex) => (
                                    <td key={colIndex}>{col.render ? col.render(section) : section[col.accessor]}</td>
                                ))}
                            </tr>
                        })
                    }
                })}
            </tbody>
        </Table>
    )

    function CustomDropdownIcon({ eventKey, callback }) {
        const { activeEventKey } = React.useContext(AccordionContext);
        const isCurrentEventKey = activeEventKey === eventKey;
        return (
            <StyledDropdownIcon onClick={useAccordionButton(eventKey, () => callback && callback(eventKey),)} collapsed={isCurrentEventKey}>
                <img src={`${Window.static_path}img/icons/dropdown-arrow.svg`} height="20px" width="20px" alt="Dropdown Arrow" />
            </StyledDropdownIcon>
        );
    }
    // Mobile view
    const DepartmentAccordion = ({ propsContext }) => {
        return (<>
            <ScrollableDiv className='mb-3'>
                <ul style={{ display: 'flex', flexWrap: 'nowrap', listStyleType: 'none', padding: 0 }}>
                    {propsContext.map((prop, propIndex) => {
                            if (prop.data) {
                                return (
                                    <li key={propIndex} style={{ marginTop: "12px", marginRight: "8px" }}>
                                        <button className='rounded-pill n-fs-7 p-2' style={{ borderColor: "#1C332A", borderStyle: "solid", borderWidth: "1px", backgroundColor: activeTab === propIndex + 1 ? '#023436' : '#FFFFFF', color: activeTab === propIndex + 1 ? '#FFFFFF' : '#1C332A', }} onClick={(e) => { e.preventDefault(); let propSelected = prop.data.sections; setActiveTab(propIndex + 1); setPropDataSelected(propSelected) }}>
                                            {prop.designation.charAt(0).toUpperCase() + prop.designation.slice(1).toLowerCase()}
                                        </button>
                                    </li>)
                            }
                        })} </ul>
            </ScrollableDiv>
            {propDataSelected !== null && <Accordion>
                {propDataSelected
                    .sort((a, b) => (a[sortOptions] - b[sortOptions]))
                    .map((section, sectionIndex) => (
                        <Accordion.Item eventKey={sectionIndex.toString()} key={sectionIndex} style={{ backgroundColor: sectionIndex % 2 == 0 ? evenAccordionItem : oddAccordionItem }} >
                            <StyledAccordionButton sectionIndex={sectionIndex} eventKey={sectionIndex.toString()} key={sectionIndex} >
                                <tr className="n-fs-7">
                                {accordionTitle.map((title, index) => (<td key={index}><div className="me-3">{title.label}<strong>{section[title.accessor]}</strong></div></td>))}
                                </tr>
                                <CustomDropdownIcon eventKey={sectionIndex.toString()} />
                            </StyledAccordionButton>
                            <Accordion.Collapse eventKey={sectionIndex.toString()}>
                                <div className='p-2'>
                                    {accordionData.map((data,index)=>(<div key={index}><p className="n-fs-7 my-2"><strong>{data.label}</strong></p>
                                    <ul className="n-fs-7 ps-3">
                                        {data.options.map((option, index)=>(<li key={index}>{option.option} {option.render(section)}</li>))}
                                    </ul></div>))}
                                </div>
                            </Accordion.Collapse>
                        </Accordion.Item>
                    ))}
            </Accordion>}
        </>
        )

    }

    return (<div
        className={`shadow rounded neutral-charcoal overflow-auto p-2 p-lg-4 ${isMobile ? "d-md-none" : "d-none d-md-block bg-neutral-cloud"}`} style={{ height: isMobile ? "auto" : "500px" }}>
        <h5 className="fw-medium">{mainTitle}</h5>
        <p className={`subtitle ${isMobile ? "mb-1" : ""}`}>{mainSubTitle}</p>
        {isMobile ? (
            <DepartmentAccordion propsContext={propsContext} />
        ) : (
            <DepartmentTable />
        )}
    </div>)
};
