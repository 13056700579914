import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import { PropsData } from '../context';

import VBCheckbox from '@virkesborsen/vb-js-commons/lib/components/new/vbCheckbox';

import Cookies from 'js-cookie';




export default function ArbonicsModal(props) {
    const [content, setContent] = React.useState(null);
    const [allowSend, setAllowSend] = React.useState(false);
    const [acceptedTerms, setAcceptedTerms] = React.useState(false)

    const propsContext = React.useContext(PropsData);
    const activeProps = propsContext.props.filter(p => p.active)

    const [isLoading, setIsLoading] = React.useState(true);

    
    React.useEffect(() => {
        if (propsContext.props.every((itm) => itm.hasOwnProperty("data"))){
            setArbonicsModalContent()
        }
    }, [propsContext.props.every((itm) => itm.hasOwnProperty("data")), propsContext])

    function setArbonicsModalContent(){
        let eligible = -1
        let eligibleHa = 0
        if(activeProps){
            eligibleHa = activeProps.reduce((acum, itm) => acum+Number(itm.data.arbonics.ha), 0)
            eligible = activeProps.reduce((acum, itm) => itm.data.arbonics.eligible > acum ? itm.data.arbonics.eligible : acum, -1)
        } else{
            eligibleHa = propsContext.props.reduce((acum, itm) => acum+Number(itm.data.arbonics.ha), 0)
            eligible = propsContext.props.reduce((acum, itm) => itm.data.arbonics.eligible > acum ? itm.data.arbonics.eligible : acum, -1)
        }
        
        if(propsContext.props.length == 0 || activeProps.length == 0){
            setContent(getModalNoPropContent())
            setAllowSend(true)
        } else if(Cookies.get("arbonicsModalSent")){
            setContent(getModalSentContent())
            setAllowSend(false)
        } else if(eligible == -1){
            setAllowSend(false)
            setContent(getModalNotEligible())
        } else if(eligible == 1){
            setContent(getModalWrongRegion(eligibleHa))

            //in the future when we enable people to signup in the wrong region setAllowSend should be true
            setAllowSend(false)
        } else{
            setContent(getModalFormContent(eligibleHa))
            setAllowSend(true)
        }
        setIsLoading(false)
    }


    function SendData(){
        const headers = {
            'Content-Type': 'application/json',
            'X-CSRFToken': Cookies.get('csrftoken')
        }

        let data = {}

        if (activeProps.length == 1){
            data["description_of_property"] = activeProps[0]["designation"]
        }

        fetch("/create-arbonics-ticket/", {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({ "data": data }),
        }).then((resp) => {
            if(resp.status == 200){
                // Cookies.set('arbonicsModalSent', "1");
                setContent(getModalSentContent())
                setAllowSend(false)
            } else{
                setContent(getModalSomethingWentWrong())
                setAllowSend(false)
            }
        })
    }

    function getModalFormContent(eligibleHa){
        return <div>
        <div className="mb-5">
            <p>
                Du har möjlighet att förlänga omloppstiden på ungefär {Number(eligibleHa).toLocaleString()} hektar i din skog och tjäna kolkrediter. Anmäl ditt intresse så hör vi av oss och berättar mer.
            </p>
            
        </div>
        <VBCheckbox onChange={((val) => {setAcceptedTerms(val)})} label={<label className="n-fs-6">Genom att klicka på "Skicka"-knappen så godkänner du våra <a className="text-decoration-underline" href="/terms/" target="_blank">villkor.</a> </label>} />
    </div>
    }

    function getModalNoPropContent(){
        return <div>
            <p>Du måste lägga till en / välja en fastighet för att kunna se möjligheter för CO2</p>
        </div>
    }

    function getModalSentContent(){
        return <div>
            <p>Tack för din intresseanmälan, vi återkommer till dig</p>
        </div>
    }


    function getModalNotEligible(){
        return <div>
            <p>Du kan tyvärr inte tjäna kolkrediter just nu, då din skog inte har skog som är avverkningsmogen, om du tycker vi har gjort ett fel mailar du till info@treebula.se och skickar med din skogsbruksplan.</p>
        </div>
    }

    function getModalWrongRegion(eligibleHa){
        return <div>
            <p>Vi har ännu inte lanserat i ditt län men kommer att meddela dig så snart vi expanderar dit.
            Har du några frågor? Kontakta oss gärna på <a href="mailto:info@treebula.se">info@treebula.se</a></p>
            {/*<p>Du har möjlighet att förlänga omloppstiden på ungefär {Number(eligibleHa).toLocaleString()} hektar i din skog och tjäna kolkrediter. Vi har fortfarande inte lanserat i ditt län men kommer göra det väldigt snart. Skriv upp dig här så hör vi av oss så fort vi lanserat i ditt län.</p>
            <VBCheckbox onChange={((val) => {setAcceptedTerms(val)})} label={<label className="n-fs-6">Genom att klicka på "Skicka"-knappen så godkänner du våra <a className="text-decoration-underline" href="/terms/" target="_blank">villkor.</a> </label>} />*/}
        </div>
    }

    function getModalSomethingWentWrong(){
        return <div>
            <p>Något gick fel, du får gärna mejla oss på <a href="mailto:info@treebula.se">info@treebula.se</a> och beskriva vad du vill ha hjälp med </p>
        </div>
    }

    
    
    return <Modal show={props.visible} onHide={() => {props.hide()}} size="lg" contentClassName="bg-white p-2" centered>
        <Modal.Header className="border-0 align-items-start" closeButton>
            <div>
                <h2 className="neutral-charcoal n-fs-4 mb-3">Är du intresserad av att tjäna kolkrediter?</h2>
            </div>
        </Modal.Header>
        <Modal.Body className="py-0">
            {isLoading ? <div className="d-flex justify-content-center align-items-center"><Spinner /> </div>: content}
        </Modal.Body>
        <Modal.Footer className="border-0 justify-content-end">
            {allowSend ? <div className="pt-3">
                <button className="btn btn-secondary btn-sm" onClick={() => {props.hide()}}>Avbryt</button>
                <button disabled={!acceptedTerms} className="btn btn-primary btn-sm" onClick={() => {SendData()}}>Skicka</button>
            </div>
            : <div>
                <button className="btn btn-primary btn-sm" onClick={() => {props.hide();
                    if (propsContext.props.every((itm) => itm.hasOwnProperty("data"))){
                        setArbonicsModalContent()
                    }
                }}>Stäng</button>
            </div>
            }          
        </Modal.Footer> 

    </Modal>
}