import styled from "styled-components";

const FellingClasses = [ 
    { label: "K", value: "K" }, 
    { label: "R", value: "R" },
    { label: "G1", value: "G1" },
    { label: "G2", value: "G2" },
    { label: "S1", value: "S1" },
    { label: "S2", value: "S2" },
    // FBA
    { label: "S3", value: "S3"},
    { label: "K1", value: "K1"},
    { label: "K2", value: "K2"},
    { label: "R1", value: "R1"},
    { label: "R2", value: "R2"},
   
    {label: "IA",  value:"IA"},
]

const FellingClassesLegend = {
    S1: {text: 'Yngre slutavverkningsbar skog', color: '#c9c9c9'},
    S2: {text: 'Mogen avverkningsbar skog som bör slutavverkas', color: '#696969'},
    G1: {text: 'Yngre gallringsskog', color: '#8af389'},
    G2: {text: 'Äldre gallringsskog som uppnått lägsta ålder för slutavverkning', color: '#10950f'},
    R: {text: 'Röjningsskog', color: '#95d0ea'},
    K: {text: 'Kalmark före föryngringsåtgärder', color: '#ff8080'},
    // FBA files have this
    S3: {text: 'Mogen avverkningsbar skog som bör slutavverkas', color: '#696969'}, //S3 made similar to S2 but can be changed
    K1: {text: 'Kalmark före föryngringsåtgärder', color: '#ff8080'}, //K1 and K2 similar to K
    K2: {text: 'Kalmark före föryngringsåtgärder', color: '#ff8080'},
    R1: {text: 'Röjningsskog', color: '#95d0ea'}, //R1 and R2 same to R
    R2: {text: 'Röjningsskog', color: '#95d0ea'},

    IA: {text: 'Ingen åtgärd', color: '#bb0000'}
}

const SiValues = Array.from({ length: 33 }, (_, i) => i+14)

const fireRiskColors = {
    "Mycket liten risk": "#0000FF",
    "Liten risk": "#8A2bE2",
    "Måttlig risk": "#00FF00",
    "Stor risk": "#FFFF00",
    "Mycket stor risk": "#FFA500",
    "Extremt stor risk": "#FF0000",
    "Ingen prognos": "#808080"
}

const beetleRiskColors = {
    "Liten risk": "#008000",
    "Medel risk": "#FFFF00",
    "Hög risk": "#FF0000",
    "Ingen prognos": "#808080"
}

const PropselectorContainer = styled.div`
    width: 90vw;
    max-width: 400px;
    transform: translate(-50%, 0);
    left: 50%;
    top: 10px;
`

const ToolboxContainer = styled.div`
    width: 40px;
    right: 10px;
    top: 90px;
    @media (min-width: 992px) {
        top: 10px;
    }
`

const MapTools = styled.div`
    display: none;
    max-width: 100vw;
    left: 50%;
    transform: translate(-50%, 0);

    &.show {
        display: block;
        bottom: 80px;
        @media (min-width: 576px) {
            bottom: 0;
        }
    }
    
    @media (min-width: 576px) {
        left: calc(50% + 135px);
        max-width: 600px;
    }
`

const setUserLocation = (map) => {  
    if (navigator.geolocation) {
        map.locate({ setView: true, maxZoom: 16 });
  
        map.on('locationfound', (e) => {    
            // Check if latitude and longitude are valid numbers
            if (!isNaN(e.latitude) && !isNaN(e.longitude)) {
                L.marker([e.latitude, e.longitude]).addTo(map);
            } else {
                alert('Ogiltiga latitud- eller longitudvärden');
            }
        });
  
        map.on('locationerror', (e) => alert("Geolokaliseringsfel: " + e.message));
    } else {
        alert("Geolokalisering stöds inte i den här webbläsaren!");
    }
};

export {SiValues, FellingClasses, FellingClassesLegend, fireRiskColors, beetleRiskColors, PropselectorContainer, ToolboxContainer, MapTools, setUserLocation}