import * as Sentry from "@sentry/react";
import React from 'react';

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: "https://ab3db9a4c5d5dbd88981ecbe7ee71e91@o4508850731614208.ingest.de.sentry.io/4508850783584336",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    environment: process.env.SENTRY_ENVIRONMENT || process.env.NODE_ENV || 'production',
    // Tracing
    tracesSampler: (samplingContext) => {
      const { name } = samplingContext;
    
      // Sample all error-related transactions
      if (name?.includes('error') || name?.includes('exception')) {
        return 0.75;
      }
    
      return 0.25; 
    },
    
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    
    // Session Replay
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 0.75,
  });
}

// HOC to wrap components with Sentry user context
const withSentryUserData = (WrappedComponent) => {
  return class extends React.Component {
    componentDidMount() {
      if (Window.user_data && Window.user_data.isLogged) {
        let userTag = "authenticated";
        if (Window.user_data.isSuperUser || Window.user_data.isAdmin || Window.user_data.isExternalAdmin) {
          userTag = "admin";
        }
        Sentry.setTag("user_type", userTag);

        let role = 'user';
        if (Window.user_data.isSuperUser) {
          role = 'super_admin';
        } else if (Window.user_data.isAdmin) {
          role = Window.user_data.isExternalAdmin ? 'external_admin' : 'internal_admin';
        }
       
        Sentry.setUser({
          accountId: Window.user_data.loggedInUserID.toString(),
          permission_role: role,
          adminPermissions: Window.user_data.adminPerms ? Window.user_data.adminPerms.join(',') : ''
        });
      } else {
        Sentry.setTag("user_type", "anonymous");
        Sentry.setUser(null);
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };
};

import VBCarousel from "@virkesborsen/vb-js-commons/lib/components/vbCarousel";
import VBErrorBoundary from "@virkesborsen/vb-js-commons/lib/components/vbErrorBoundaryHandler";

import Login from "./login/login";
import ProfileAdListings from "./profile/ad-listings";
import ComplementInfo from "./login/complementinfo.js";

import PriceMap from "./treebula/price-map";

import Footer from "./vb-django/components/static/Footer";
import Navigation from "./vb-django/components/static/Navigation";
import Error404 from "./vb-django/pages/Error404";
import MinSkog from "./vb-django/pages/MinSkog"
 
// Other pages
import KnowYourCustomerPage from "./vb-django/pages/KnowYourCustomer";
import ProfileBuyer from "./profile/profile-buyer.js";
import FAQPage from "./vb-django/pages/FAQPage";
import ForestCataloguePage from "./vb-django/pages/ForestCatalogue";
import ContactSellerPage from "./vb-django/pages/ContactSeller";
import ProfileEditPage from "./vb-django/pages/ProfileEdit";
import GbbrPoc from "./vb-django/pages/GbbrPoc.js";

// Auctions
import AuctionDetailsPage from "./vb-django/pages/auctions/AuctionDetails.js";
import AuctionsCreatePage from "./vb-django/pages/auctions/AuctionsCreate.js";
import Auctions from "./vb-django/pages/auctions/Auctions";

// Naturehub
import NHListings from "./vb-django/pages/naturehub/nhListings.js";
import NHDetails from "./vb-django/pages/naturehub/nhDetails.js";
import NHCreate from "./vb-django/pages/naturehub/nhCreate.js";

// Typeform
import Typeform from "./vb-django/pages/TypeformPage/index.js";

// Puck
import Editor from "./vb-django/components/editor/Editor";
import PuckPageView from "./vb-django/components/editor/PuckPageView";

// Form Modal
import LoginFormModal from "./vb-django/forms/LoginFormModal.js"

require('es6-promise').polyfill();
require('isomorphic-fetch');
require('./polyfills.js');

if (typeof window !== 'undefined' && process.env.NODE_ENV === 'development') {
  window.gtag = function () {};
}

// Export components wrapped with Sentry user context for logging purposes
export default {
  "VBErrorBoundary": withSentryUserData(VBErrorBoundary),
  "VBCarousel": withSentryUserData(VBCarousel),
  "ProfileAdListings": withSentryUserData(ProfileAdListings),
  "Login": withSentryUserData(Login),
  "ComplementInfo": withSentryUserData(ComplementInfo),
  "PriceMap": withSentryUserData(PriceMap),
  "Footer": withSentryUserData(Footer),
  "Navigation": withSentryUserData(Navigation),
  "Error404": withSentryUserData(Error404),
  "MinSkog": withSentryUserData(MinSkog),

  // Here comes the new stuff
  "KnowYourCustomerPage": withSentryUserData(KnowYourCustomerPage),
  "ProfileBuyer": withSentryUserData(ProfileBuyer),
  "FAQPage": withSentryUserData(FAQPage),
  "ForestCataloguePage": withSentryUserData(ForestCataloguePage),
  "ContactSellerPage": withSentryUserData(ContactSellerPage),
  "ProfileEditPage": withSentryUserData(ProfileEditPage),
  "GbbrPoc": withSentryUserData(GbbrPoc),

  // Nature hub
  "NHListings": withSentryUserData(NHListings),
  "NHDetails": withSentryUserData(NHDetails),
  "NHCreate": withSentryUserData(NHCreate),
  
  // Typeform
  "Typeform": withSentryUserData(Typeform),
  
  // Puck
  "Editor": withSentryUserData(Editor),
  "PuckPageView": withSentryUserData(PuckPageView),
  "AuctionDetailsPage": withSentryUserData(AuctionDetailsPage),
  "AuctionsCreatePage": withSentryUserData(AuctionsCreatePage),
  "Auctions": withSentryUserData(Auctions),

  // Form Modal
  "LoginFormModal": withSentryUserData(LoginFormModal),
}