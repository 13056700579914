import React from "react";
import styled from "styled-components";
import VBCheckbox from "@virkesborsen/vb-js-commons/lib/components/new/vbCheckbox";
import StepButton from "./StepButton";

const TermsStep = ({
  onNext,
  stepNumber,
  initialTerms = false,
  onTermsChange,
  error,
  checkboxRef,
  submitError,
  isLoading = false,
}) => {
  const handleChange = () => {
    onTermsChange({
      target: {
        name: "acceptTerms",
        type: "checkbox",
        checked: !initialTerms,
      },
    });
  };

  return (
    <StepContainer>
      <ContentWrapper>
        <StepNumberWrapper>
          <span>{stepNumber}</span>
          <StepArrow
            width="16px"
            height="16px"
            src={Window.static_path + "img/icons/arrow.svg"}
            alt="Step indicator"
          />
        </StepNumberWrapper>
        <MainContent>
          <Title>Vi värdesätter dina personuppgifter.</Title>
          <Subtitle>
            För att kunna spara dina svar, behöver vi att du accepterar vår
            integritetspolicy och våra användarvillkor. Om du inte accepterar
            kommer du inte att kunna skicka in detta formulär och vi kommer inte
            kunna kontakta dig*
          </Subtitle>
          <CheckboxWrapper ref={checkboxRef}>
            <CheckboxContainer>
              <CheckboxRow>
                <VBCheckbox
                  label=""
                  initial={initialTerms}
                  checkFilter="white-filter"
                  onClick={handleChange}
                  checked={initialTerms}
                />
                <AcceptText>Genom att skicka godkänner jag</AcceptText>
              </CheckboxRow>
              <TermsLinkContainer>
                <TermsText>
                  <TermsLink href="/terms" target="_blank">
                    integritetspolicyn
                  </TermsLink>
                  samt
                  <TermsLink href="/privacy" target="_blank">
                    användarvillkoren
                  </TermsLink>
                </TermsText>
              </TermsLinkContainer>
            </CheckboxContainer>
            {error && <ErrorMessage>{error}</ErrorMessage>}
          </CheckboxWrapper>
          <StepButton
            onClick={onNext}
            disabled={!initialTerms}
            text="Skicka"
            isLoading={isLoading}
          />
          {submitError && (
            <SubmitErrorMessage>{submitError}</SubmitErrorMessage>
          )}
        </MainContent>
      </ContentWrapper>
    </StepContainer>
  );
};

const StepContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0;
  flex-grow: 1;
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
`;

const StepNumberWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  gap: 8px;

  @media (max-width: 576px) {
    display: none;
  }
`;

const StepArrow = styled.img``;

const MainContent = styled.div`
  flex-grow: 1;
`;

const Title = styled.h2`
  font-size: 24px;
  margin-bottom: 0.5rem;
`;

const Subtitle = styled.p`
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.8);
  opacity: 0.75;
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: column;

  @media (max-width: 576px) {
    font-size: 16px;
    margin-bottom: 1rem;
  }
`;

const CheckboxWrapper = styled.div`
  margin-bottom: 1rem;
`;

const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const CheckboxRow = styled.div`
  display: flex;
  align-items: center;
`;

const AcceptText = styled.p`
  margin: 0;
  font-size: 14px;
  font-weight: 400;
`;

const TermsLinkContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  font-size: 14px;
`;

const TermsText = styled.p`
  display: flex;
  align-items: center;
  gap: 4px;
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  flex-wrap: wrap;
`;

const TermsLink = styled.a`
  font-size: 14px;
  font-weight: 500;
  text-decoration: underline;
  color: white;
`;

const ErrorMessage = styled.p`
  color: red;
  font-size: 16px;
  font-weight: 400;
  margin-top: 0.25rem;
  margin-bottom: 0;
`;

const SubmitErrorMessage = styled.p`
  color: red;
  font-size: 16px;
  font-weight: 400;
  margin-top: 1rem;
  text-align: center;
`;

export default TermsStep;
