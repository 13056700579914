import React from "react";

class AdTables extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ad: Window.react_data.ad,
      showSI: false,

      selectedAdsInfoRows: [],
      selectedForestInfoRows: [],
      selectedForestTableRows: [],
      
      adsInfo: {
        //Annonsdetaljer
        "Första anbudsdag": Window.react_data.ad.start_time,
        "Sista anbudsdag": Window.react_data.ad.end_time,
        "Åtgärd": Window.react_data.ad.arrangement_types,
        "Gallringsuttag": Window.react_data.ad.get_thinning,
        "Kontraktstyp": Window.react_data.ad.name_display,
        "Avverkningsperiod från": Window.react_data.ad.felling_start_time,
        "Avverkningsperiod till": Window.react_data.ad.felling_end_time,
        "Leveransår": Window.react_data.ad.delivery_year,
        "Upphämtningsdatumet": Window.react_data.ad.pickup_date,
        "Antal dagar till betalning": Window.react_data.ad.days_to_payment,
        "Ansvarig för avverkningsanmälan": Window.react_data.ad.felling_application_responsible,
        // "Kontraktets signeringssätt": Window.react_data.ad.signing_method == "paper" ? "Papper": Window.react_data.ad.signing_method,
        "Kommun": Window.react_data.ad.municipality,
        "Registrerad för mervärdesskatt": Window.react_data.ad.more_tax,
        "Taxeras som lantbruksenhet": Window.react_data.ad.agricultural_unit,
        "Önskas dellikvid / förskottsbetalning / delbetalning": Window.react_data.ad.part_or_advance_payment_allowed,
        "Detaljer ang. dellikvid / förskottsbetalning / delbetalning": Window.react_data.ad.part_or_advance_payment_description,
        "Medlemskap i skogsägarförening": Window.react_data.ad.get_seller_forest_owner_union_membership_display,
      },
      forestInfo: {
        //Information om skogen
        "Areal": Window.react_data.ad.type.name !== "leveransvirke" ? `${Window.react_data.ad.total_area_ha} ha`: null,
        "Fastighetsbeteckning": Window.react_data.trustedBuyer ?  Window.react_data.ad.description_of_property:null,
        "Uttag": Window.react_data.ad.grot_volume === "grot" ? Window.react_data.ad.grot_volume : null,
        "Beståndets beteckning": Window.react_data.ad.habitat_index,
        "Beståndets ålder": Window.react_data.ad.property_age,
        "Grotuttag medges": Window.react_data.ad.grot_included,
        "Uttag": Window.react_data.ad.total_volume ? `${Window.react_data.ad.total_volume} m³fub`: null,
        "Antal träd": Window.react_data.ad.num_trees,
        "Medelstam (m3fub)": Window.react_data.ad.average_trunk,
        "Skogen står upp": Window.react_data.ad.type.name === "leveransvirke" ? Window.react_data.ad.standing_forest : null,
        "FSC-certifikat": Window.react_data.ad.fsc_certified,
        "PEFC-certifikat": Window.react_data.ad.pefc_certified,
        "Spara fröträd": Window.react_data.ad.save_seed_trees,
        "Hur är fröträd markerade": Window.react_data.ad.seed_tree_marking_description,
        "Återplantering": Window.react_data.ad.replanting,
        "Markberedning": Window.react_data.ad.type.name !== "leveransvirke" ? Window.react_data.ad.ground_preparation : null,
        "Förröjning": Window.react_data.ad.type.name !== "leveransvirke" ? Window.react_data.ad.precleaning_needed : null,
        "Hyggerensing": Window.react_data.ad.ground_clearing || null,
        "Vägbygge eller vägförstärkning": Window.react_data.ad.buyer_builds_or_reinforces_road || null,
        "Kompletterande information gällande skogsvård": Window.react_data.ad.service_comments,
      },
      "otherAdsForOwner": Window.react_data.ad.other_ads_for_owner,

      "type": Window.react_data.ad.type.name,
      "wood_types": Window.react_data.ad.wood_type,
      "section_assortments_wood": Window.react_data.ad.section_assortments_wood,
      "section_assortments": Window.react_data.ad.section_assortments,
    };
  }

  toggleRowSelection(tableName, index) {
    // Create a copy of the selectedRows array for the specific table
    const selectedRowsCopy = [...this.state[`selected${tableName}Rows`]];

    // Check if the row is already selected for the specific table
    const isSelected = selectedRowsCopy.includes(index);

    // Update the selectedRows array based on the current selection for the specific table
    if (isSelected) {
      // If the row is already selected, remove it
      selectedRowsCopy.splice(selectedRowsCopy.indexOf(index), 1);
    } else {
      // If the row is not selected, add it
      selectedRowsCopy.push(index);
    }

    // Update the state for the specific table
    this.setState({
      [`selected${tableName}Rows`]: selectedRowsCopy,
    });
  }

  renderAdsInfo() {
    return (
      //Annonsdetaljer
      <table className="table table-hover text-break fs-7">
        <tbody style={{borderColor: '#C6C9C9'}}>
          {Object.entries(this.state.adsInfo).map(
            ([key, value], index) =>
              // Use conditional rendering
              value !== null && value !== "" && value && (
                <tr key={key} className="n-fs-7" style={{backgroundColor: this.state.selectedAdsInfoRows?.includes(index) ? "#60DFE0" : "inherit", color: this.state.selectedAdsInfoRows?.includes(index) ? "#1C332A" : "inherit", }}
                  onClick={() => this.toggleRowSelection("AdsInfo", index)}>
                  <td className="text-break" style={{ maxWidth: key.length > 20 ?"150px":"128px" }}>{key}:</td>
                  <td className="text-break" >{this.formatValue(value)}</td>
                </tr>
              )
          )}
        </tbody>
      </table>
    );
  }

  formatValue(value) {
    if (value === true) {
      return "Ja";
    } else if (value === false) {
      return "Nej";
    } else if (typeof value === "number") {
      return value.toFixed(2);
    } else {
      return value; // Default case, no specific formatting
    }
  }

  renderForestInfo() {
    return (
      //Information om skogen
      <table className="table table-hover">
        <tbody style={{borderColor: '#C6C9C9'}}>
          {Object.entries(this.state.forestInfo).map(
            ([key, value], index) =>
              // Use conditional rendering to skip rows with empty values
              value !== null && value !== "" && (
                <tr key={key} className="n-fs-7" style={{ backgroundColor: this.state.selectedForestInfoRows?.includes(index) ? "#60DFE0" : "inherit", color: this.state.selectedForestInfoRows?.includes(index) ? "#1C332A" : "inherit", }}
                  onClick={() => this.toggleRowSelection("ForestInfo", index)}>
                  <td>{key}:</td>
                  <td>{key == "Uttag" ? `${this.cleanNumbers(this.state.ad.total_volume)} m³fub` : this.formatValue(value)}</td>
                </tr>
              )
          )}
        </tbody>
      </table>
    );
  }

  renderOtherAdsForOwner() {
    if(!this.state.ad.other_ads_for_owner || this.state.ad.other_ads_for_owner === 0) {
      return null;
    }

    return (
      <div>
        <p className="mb-3 n-fs-5 fw-medium">Andra annonser från denna skogsägare</p>
        <table className="table table-hover">
        <tbody style={{borderColor: '#C6C9C9'}}>
          {this.state.ad.other_ads_for_owner.map((ad) => (
            <tr key={ad.id}>
              <td style={{ }}>
                <a className="d-flex n-fs-6" href={`/auctions/${ad.id}`}> <img src={`${Window.static_path}img/icons/viewPage.svg`} height="28px" width="28px" className="supp-forest-green-filter p-1 pb-2" alt="ViewPage" />{ad.label}</a>
              </td>
            </tr>
          ))}
          </tbody>
        </table>
      </div>
    );
  }

  renderForestTableHeader() {
    //skogsuppgifter
    const renderWoodTypeHeader = (woodName, index) => (
      <th key={index} style={{fontWeight:"normal"}}>{woodName === "default" ? "Löv" : woodName}</th>
    );
    if (this.state.ad.type === "Leveransvirke") {
      return (
        <tr>
          <th></th>
          <th style={{fontWeight:"normal"}}>Volym</th>
          {this.state.ad.wood_types && this.state.ad.wood_types.map(renderWoodTypeHeader)}
        </tr>
      );
    } else {
      return (
        <tr>
          <th style={{fontWeight:"normal"}}>#Avd</th>
          <th style={{fontWeight:"normal"}}>Uttag</th>
          <th style={{fontWeight:"normal"}}>{this.state.showSI ? 'SI' : ''}</th>
          <th style={{fontWeight:"normal"}}>Area</th>
          {this.state.ad.wood_types && this.state.ad.wood_types.map(renderWoodTypeHeader)}
        </tr>
      );
    }
  }

  renderForestTableBody() {
    const renderWoodCell = (woodDict, woodType) => {
      const value = woodDict && woodDict[woodType];
    
      // Check if the wood type is in the list of wood types from the header
      const shouldRenderCell = value !== undefined && this.state.ad.wood_types.includes(woodType);
    
      // return shouldRenderCell ? <td key={woodType}>{`${value.toString().replace(".", ",")} m³fub`}</td> : <td key={woodType}></td>;

      const cellContent = shouldRenderCell ? 
        `${value && this.state.ad.type !== "Leveransvirke" ? this.cleanNumbers(value) : "0"} m³fub` : 
        '';
        
    return (
        <>
            {cellContent && <td key={woodType}>{cellContent}</td>}
            {!cellContent && <td key={woodType}>0 m³fub</td>}
        </>
    );
    };

    const renderTableCell = (value, index) => (
      <td key={index}>{value}</td>
    );
    return (
      this.state.ad.section_assortments && this.state.ad.section_assortments.map((itm, index) => (
      <tr key={index}
      style={{
          backgroundColor: this.state.selectedForestTableRows?.includes(index) ? "#60DFE0" : "inherit",
          color: this.state.selectedForestTableRows?.includes(index) ? "#1C332A" : "inherit",
        }}
        onClick={() => this.toggleRowSelection("ForestTable", index)}
      >
        {this.state.ad.type === "Leveransvirke" ? (
          <>
          <td></td>
          {renderTableCell(`${itm.total_volume } m³fub`)}
          {this.state.ad.wood_types.map((woodType) => renderWoodCell(itm.wood, woodType))}
          </>
        ) : (
          <>
          {renderTableCell(itm.name)}
            {renderTableCell(`${itm.total_volume} m³fub`)}
            {renderTableCell(itm.habitat_index)}
            {renderTableCell(`${Number(itm.Hectares).toFixed(1).toString().replace(".", ",")} ha`)}
            {this.state.ad.wood_types.map((woodType) => renderWoodCell(itm.wood, woodType))}
          </>
        )}
      </tr>
    ))
    );
  }

  cleanNumbers(num) {
    return Math.round(Number(num)).toString().replace(".0", "").replace(".", ",")
  }

  renderWoodTypeSum(wt, value) {
    return (
      <td className="fw-bold">{value} m³fub</td>
    )
  }

  renderSumTableRow() {
    let total_vol = 0
    let total_area = 0.0
    let wood = {}
    this.state.ad.section_assortments.map((itm) => {
      this.state.ad.wood_types.map( (wd) => {
        wood[wd] = (itm.wood[wd] ? itm.wood[wd] : 0) + (wood[wd] ? wood[wd] : 0)
      })
      total_vol = total_vol + (itm.total_volume ? itm.total_volume : 0)
      total_area = total_area + parseFloat(itm.Hectares ? itm.Hectares : 0)

      if (itm.habitat_index && this.state.showSI !== true) {
        this.setState({showSI: true})
      }
    })
    
    return (
      <tr className="border border-top border-1 bg-primary-core-100">
        <td className="fw-bold">
        Totalt:
        </td>
        {this.state.ad.type !== "Leveransvirke" ?
        <>
        <td className="fw-bold">
        {this.cleanNumbers(total_vol)} m³fub
        </td>
        <td className="fw-bold">
        {/* SI */}
        </td>
        <td className="fw-bold">
        {this.cleanNumbers(total_area)} ha
        </td>
        </>
        : <>
        <td className="fw-bold">
        {this.cleanNumbers(total_vol)} m³fub
        </td>
        </>}
        {Object.keys(wood).map((woodType) => this.renderWoodTypeSum(woodType, this.cleanNumbers(wood[woodType])))}        
      </tr>
    )
  }

  renderForestTable() {
    return (
      <table className="table table-striped table-hover">
        <thead className="n-fs-7">{this.renderForestTableHeader()}</thead>
        <tbody className="n-fs-7">
          {this.renderForestTableBody()}
          {this.renderSumTableRow()}
          </tbody>
      </table>
    );
  }

  renderAttachments() {
        return (
          <div>
            <h3 className="mb-3 n-fs-5 fw-medium">Bilagor</h3>

            <table className="table table-hover">
              <tbody style={{borderColor: '#C6C9C9'}}>
                {this.state.ad?.attachments?.map((attachment, index) => (
                  <tr key={index}>
                    <a href={attachment.file_url} className="d-flex n-fs-6" target="_blank">
                      <td>
                      <img src={`${Window.static_path}img/icons/download.svg`} height="24px" width="24px" className="supp-forest-green-filter p-1" alt="Download" />
                        {attachment.original_file_name}
                      </td>
                    </a>
                  </tr>
                ))}
                <tr>
                  <a href={`/auctions/${this.state.ad.id}/kml/`} className="d-flex n-fs-6">
                    <td>
                    <img src={`${Window.static_path}img/icons/download.svg`} height="24px" width="24px" className="supp-forest-green-filter p-1" alt="Download" />
                      {/* in English: Map File */}
                      Kartfil (kml)
                    </td>
                  </a>
                </tr>
                <tr>
                  <a href={`/auctions/${this.state.ad.id}/shapefile/`} className="d-flex n-fs-6">
                    <td>
                    <img src={`${Window.static_path}img/icons/download.svg`} height="24px" width="24px" className="supp-forest-green-filter p-1" alt="Download" />
                      {/* in English: Map File */}
                      Kartfil (zip)
                    </td>
                  </a>
                </tr>
              </tbody>
          </table>
        </div>
        );
  }


  render() {
    const isOtherAdsEmpty = !this.state.otherAdsForOwner || this.state.otherAdsForOwner.length === 0;
    const hasValuesInBody = this.state.ad.section_assortments && this.state.ad.section_assortments.length > 0;
    const shouldRenderInformation = this.state.ad.comments && this.state.ad.comments.length > 0;

    return (
      <div className="primary-dark row flex-md-row d-flex justify-content-center mt-4 mx-1">
      
        {(shouldRenderInformation && Window.react_data.trustedBuyer) && (
        <div className="col-12 col-lg-11 bg-neutral-cloud mb-4 p-3" style={{ borderRadius: "12px"}}>
          <p className="mb-3 n-fs-5 fw-medium">
            Information
          </p>
          <p className="n-fs-6" style={{whiteSpace:"pre-line"}}>{this.state.ad.comments}</p>
        </div>
        )}

        <div className="col-lg-5 col-12 mb-4 p-3 me-lg-4 bg-neutral-cloud" style={{ borderRadius:"12px"}}>
          <p className="mb-3 n-fs-5 fw-medium">
            Annonsdetaljer
          </p>
          {this.renderAdsInfo()}
        </div>

        <div className="col-lg-5 col-12 mb-4 p-3 ms-lg-4 bg-neutral-cloud" style={{ borderRadius:"12px"}}>
          <p className="mb-4 n-fs-5 fw-medium">
            Information om skogen
          </p>
          {this.renderForestInfo()}
        </div>

        {/* Forest table */}
         <div className='col-12 col-lg-11 mb-4 p-4 bg-neutral-cloud' style={{ borderRadius:"16px"}}>
          <p className="mb-4 n-fs-5 fw-medium">
              Skogsuppgifter
          </p>
          <div className="table-responsive">{this.renderForestTable()}</div>
        </div>
        
        {/* Other Ads */}
        <div className={`${isOtherAdsEmpty ? 'd-none' : 'col-lg-4 col-12'}  p-3 mb-4 me-lg-4 bg-neutral-cloud`} style={{ borderRadius:"12px"}}>
        {this.state.otherAdsForOwner && this.renderOtherAdsForOwner()}
        </div>
        
        {/* Attachments */}
       {Window.react_data.trustedBuyer ?  <div className={`col-lg-${isOtherAdsEmpty ? '7' : '6'} ms-lg-${isOtherAdsEmpty ? '0' : '4'} col-12 rounded p-3 mb-4 bg-neutral-cloud`} style={{ borderRadius:"12px", display: hasValuesInBody ? 'block' : 'none' }} id='attachments'>
          {this.renderAttachments()}
        </div>:null}

      </div>
    );
  }
}

export default AdTables;
