import React from 'react'
import PropTypes from "prop-types";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

import { Line } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import annotationPlugin from 'chartjs-plugin-annotation';
import styled from 'styled-components';

ChartJS.register(
    CategoryScale,
    ChartDataLabels,
    annotationPlugin,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const PriceRoot = styled.div`
    canvas {
        min-height: 220px;
        @media (min-width: 1200px) {
            min-height: 320px;
        }
    }
`

function LineChart({ data, options }) {
    return <PriceRoot>
        <Line data={data} options={options} />
    </PriceRoot>
}

LineChart.propTypes = {
    data: PropTypes.shape({}),
};

LineChart.defaultProps = {
    data: undefined,
    options: {
        responsive: true,
        interaction: {
            intersect: false,
            mode: 'index',
        },
        events: ['mousemove', 'mouseout', 'click', 'touchstart', 'touchmove'],
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                enabled: true,
                position: 'nearest'
            },
            datalabels: {
                color: 'transparent'
            },
            autocolors: false
        },
        scales: {
            x: {
                ticks: {
                    color: '#1C332A',
                    fontSize: 10,
                    fontWeight: 'bold',
                    autoSkip: false,
                },
                grid: {
                    drawBorder: false,
                    display: false,
                },
            },
            y: {
                ticks: {
                    color: '#1C332A',
                    fontSize: 60,
                    fontWeight: 'bold',
                    beginAtZero: true,
                    stepSize: 50,
                    grace: "0.005%",
                    callback: function (value, index, ticks) {
                        return value.toLocaleString() + " kr " + '-   ';
                    },
                },
                grid: {
                    drawBorder: false,
                    display: false,
                },
            },
        },
        maintainAspectRatio: false,
    }
}

export default LineChart;