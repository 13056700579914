import React from 'react';
import Cookies from 'js-cookie';
import styled from "styled-components";
import Offcanvas from 'react-bootstrap/Offcanvas';

import { useState, useEffect } from "react";

import Modal from 'react-bootstrap/Modal';
import { ErrorModal } from '../../../modals';

import { getPropStatus, fetchPropertyData, getProp, deleteProperty } from '../../../api/services/property';
import { PropsData, VisaValda } from '../context';


const SectionPopupStyle = styled(Offcanvas)`
    transition: transform 0.3s ease-in-out;
    background-color: #F6F7F8;

    @media (min-width: 576px) {
        width: 270px;
        left: 0;
        overflow-y: auto !important;
        transform: translateX(-100%);

        &.show {
            transform: translateX(0);
            left: 270px !important;
        }
    }

    @media (max-width: 575px) {
        width: 100%;
        max-height: 90vh; /* Prevents full-screen takeover */
        position: fixed !important; /* Ensure it is properly positioned */
        bottom: 0;
        left: 0 !important;
        top: auto !important;
        transform: translateY(100%) !important; /* Initially hidden below the screen */

        overflow-y: auto !important; /* ✅ Ensure scrolling is possible */
        -webkit-overflow-scrolling: touch; /* ✅ Enable smooth scrolling on iOS */

        &.show {
            transform: translateY(0) !important; /* Slides up */
        }
    }
`;

const CustomScrollbar = styled.div`
    max-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;

    ::-webkit-scrollbar {
        width: 10px;
    }

    ::-webkit-scrollbar-thumb {
        background: #023436;
        border-radius: 10px;
    }
`

const SettingsPanel = ({ visible,property, setVisibility}) => {
    const propsContext = React.useContext(PropsData);
    const visaValdaContext = React.useContext(VisaValda);

    const [loading, setLoading] = useState(false)
    const [loadingReset, setLoadingReset] = useState(false)
    const [startPanelShow, setStartPanelShow] = useState(false)
    const [settingsSectionShow, setSettingsSectionShow] = useState(false)

    const [updateDate, setUpdateDate] = useState(null)
    const [selectedProperty, setSelectedProperty] = useState(property)
    const [collapsed, setCollapsed] = React.useState(false);

    // Modals
    const [infoModal, setInfoModal] = React.useState(false);
    const [errorModal, setErrorModal] = React.useState(false);
    const [deleteModal, setDeleteModal] = React.useState(false);

    const imageBaseUrl  = `${Window.static_path}img/icons`

    useEffect(() => {
        if (property){
            setSelectedProperty(property)
            if (Window.react_data?.updated_props_dates){
                const prop = Window.react_data.updated_props_dates.find(item => item.ref === property.ref);
                setUpdateDate({ref:property.ref,date:prop?prop.date:""})
            }
           }
        }, [property]);

    useEffect(() => {
        if (visible){
            setStartPanelShow(true)
        }
    }, [visible]);



    // REST API
    
    const removePropertyFromCookie=(ref)=>{

        let activePropsCookie = Cookies.get('activeProps')
        if(activePropsCookie){
            let propList = activePropsCookie.split(',').filter(prop => prop != ref)
            Cookies.set('activeProps', propList.map(p => p).join(','))
        }

    }

    const fetchPropData = async (p) => {
        const response = await fetchPropertyData(p.ref, p.type)
        const data = response.data
        data && typeof data == 'object' ? p.data = data : p.status = 'warning'

        if(data) {
            if(p.type == 'PROP') {
                const response = await getProp(p.ref)
                const propData = response.data

                if(propData) {
                    p.border = propData.geojson.geometry.coordinates
                    p.area = propData.area                    
                }
            }
            
            if('data' in p) {
                let volume = 0
                data.sections.map(s => volume += s.volume)
                p.volume = volume
            }
            p.loading=false
            setLoadingReset(false)
            setLoading(false)
        }
        
        propsContext.setOneProp(p)
        }

    const checkPropStatus = async (property) => {
        const response = await getPropStatus(property.ref)
        const status = response.data
        
        if(status == 'broken' || status == 'data') { 
            property.status = status
            propsContext.setOneProp(property)
            if(status == 'data') {
                fetchPropData(property)
            }
        } else {
            setTimeout(() => {
                checkPropStatus(property); 
            }, 2000);
        }
        }

    const regenerateProperty = (selectedProperty) => {
        let data = {
            lmID: selectedProperty.ref,
            designation: selectedProperty.designation
        }
        setLoadingReset(true)

        fetch('/map-api/user-regenerate-prop/', {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': Cookies.get('csrftoken')
            },
        }).then(res => res.json()).then((json) => {
            if (json.error) {
                if (json.error == "Could not find property/user combination or regenerated to soon."){
                    setInfoModal({title: "Kunde inte återskapa fastighet", message: <>Det gick inte att återskapa fastigheten. Vänta en stund och försök igen. 
              Om problemet kvarstår.Kontakta oss på <a className='fw-bold' href='mailto:info@treebula.se'>info@treebula.se</a> eller <a className='fw-bold' href='tel:00468339944'>08-33 99 44</a> så hjälper våra rådgivare dig att lösa detta så snabbt som möjligt.</>})
                }
                else{
                    setErrorModal(true)
                }
                setLoadingReset(false)
            } else {
                let props = propsContext.props
                let selectedProp
                props.map((p) => {
                    if (p.ref == selectedProperty.ref) {
                        p.active = false
                        p.checked=false
                        p.loading=true
                        p.locked=true
                        selectedProp = p
                        
                    }
                })
                propsContext.setProps(props)
                visaValdaContext.setVisaValda(true)
                checkPropStatus(selectedProp)
                removePropertyFromCookie(selectedProp.ref)
                setStartPanelShow(false); 
                setSettingsSectionShow(false); 
                setVisibility();
                setLoadingReset(false);
                setUpdateDate({ref:selectedProp.ref, date:new Date(json.date).toISOString().split("T")[0]});
                

            }
        });
    }

    const updateProperty = (selectedProperty) => {
        let sections = selectedProperty.data.sections
      
        setLoading(true)

        fetch('/map-api/user-retrieve-updated-sections/', {
            method: 'POST',
            body: JSON.stringify({sections:sections}),
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': Cookies.get('csrftoken')
            },
        }).then(res => res.json()).then((json) => {
            if (json.error){
                setErrorModal(true)
            }
            else{
                let props = propsContext.props
                json.forEach(i => {
                    const prop = props.find(p => p.ref === i.ref);
                    if (prop) {
                        prop.data = i.new_data;
                        setUpdateDate({ref:i.ref, date:new Date(i.updated_date).toISOString().split("T")[0]});
                    }
                });
                propsContext.setProps(props)
                setLoading(false)}       
        });
    }

    // Settings panel views
    const startPanel = () => {
        return <div>
            <div className='d-flex justify-content-between'>
                <p className='n-fs-7 mb-0'>Inställningar</p>
                <p className='n-fs-7 mb-0' style={{ cursor: "pointer" }} onClick={() => {setStartPanelShow(false); setSettingsSectionShow(false); setVisibility() }}>Stäng</p>
            </div>
            <hr className='my-3' style={{ borderColor: "#D7D8DA" }}></hr>
            <div className='rounded-4 p-3 bg-white d-flex justify-content-between align-items-center'>
                <div><p className='n-fs-7 mb-1'>Fastighet</p>
                <p className='n-fs-7 neutral-charcoal-300 mb-0'>{selectedProperty.designation}</p></div>
                <div role='button' onClick={() => setCollapsed(!collapsed)} ><div className={`${collapsed ? 'rotate-50' : ''}`} style={{transition: 'transform .2s ease-in-out'}}><img src={`${Window.static_path}img/icons/dropdown-arrow.svg`} style={{ width: "16px", height: "16px" }}/></div></div>
            </div>
            <hr className='my-3' style={{ borderColor: "#D7D8DA" }}></hr>      
            {!collapsed && <> <div className='rounded-4 p-3 bg-white d-flex justify-content-between' role='button' onClick={()=>{setStartPanelShow(false);setSettingsSectionShow(true)}}>
                <div className='d-flex justify-content-start'><img src={`${Window.static_path}img/icons/refresh.svg`} style={{ width: "20px", height: "20px" }}/><p className='n-fs-7 mb-0 ms-3'>Uppdatera eller återställ</p></div>
                <img src={`${imageBaseUrl}/caret.svg`} style={{ width: "20px", height: "20px" }}/>
            </div>
            <div className='rounded-4 p-3 mt-3 bg-white d-flex justify-content-start' role='button' onClick={() => {setDeleteModal(selectedProperty)}}>
                <p className='mb-0'><img src={`${imageBaseUrl}/trash.svg`} className='supp-redwood-filter me-3' style={{ width: "20px", height: "20px" }}/><span className='n-fs-7 supp-redwood-500'>Radera fastigheten</span></p>
            </div></>}
        </div>
    }
    
    const settingsPanel=()=>{

       const updateSbp=()=>{
            if (loading== false){
                updateProperty(selectedProperty)
            }
            }

        const reset=()=>{
            if (loadingReset == false){
                regenerateProperty(selectedProperty);
                
            }
        }
        let updatedDate = updateDate?.ref === selectedProperty.ref ? updateDate.date : "";
       

        const settingOptions = [
            {icon:`${imageBaseUrl}/sync.svg`,title:"Uppdatera",updateDate:`Senast uppdaterad: ${updatedDate}`,updateText:"Uppdaterar och ändrar endast data i avgränsningar till den senaste versionen."},
            {icon:`${imageBaseUrl}/update.svg`,title:"Återställ & regenerera",updateText:"Återställer fastigheten och raderar alla ändringar."}]
        return <div>
            <div className='d-flex justify-content-between'>
                <p className='n-fs-7 mb-0' style={{ cursor: "pointer" }} onClick={() => { setStartPanelShow(true); setSettingsSectionShow(false) }}>Tillbaka</p>
                <p className='n-fs-7 mb-0'>Uppdatera eller återställ</p>
                <p className='n-fs-7 mb-0' style={{ cursor: "pointer" }} onClick={() => { setStartPanelShow(false); setSettingsSectionShow(false);setVisibility() }}>Stäng</p>
            </div>
            <hr className='my-3' style={{ borderColor: "#D7D8DA" }}></hr>
            <div className='rounded-4 p-3 mt-3 bg-white d-flex justify-content-between align-items-center'>
                <div><p className='n-fs-7 mb-1'>Fastighet</p>
                <p className='n-fs-7 mb-0 neutral-charcoal-300'>{selectedProperty.designation}</p></div>
                <div role='button' onClick={() => setCollapsed(!collapsed)} ><div className={`${collapsed ? 'rotate-50' : ''}`} style={{transition: 'transform .2s ease-in-out'}}><img src={`${Window.static_path}img/icons/dropdown-arrow.svg`} style={{ width: "16px", height: "16px" }}/></div></div>
            </div>
            <hr className='mt-3 mb-0' style={{ borderColor: "#D7D8DA" }}></hr>
            <CustomScrollbar>
                {!collapsed && settingOptions.map((setting, index) => (
                    <div key={index} className='rounded-4 p-3 mt-3 bg-white'>
                        <div className='d-flex flex-row align-items-start'> <img className='me-2' src={setting.icon} style={{ width: "20px", height: "20px" }} /><div><div className='d-flex justify-content-between'><div>
                            <p className='n-fs-7 mb-0'>{setting.title}<br />{setting?.updateDate ? <span className='n-fs-8 neutral-charcoal-300'>{setting.updateDate}</span> : null}</p></div>
                            {setting.title !== "Återställ & regenerera" ? <p className={`n-fs-7 bg-neutral-stone-100 py-1 px-2 rounded-3 ${loading === false ? "" : "disabled-grey"}`} style={{ cursor: "pointer" }} onClick={() => { updateSbp() }} >{loading === false ? "Uppdatera" : "Uppdaterar"}</p> : <p className='n-fs-7 bg-neutral-stone-100 py-1 px-2 rounded-3 danger-700 mb-0' style={{ cursor: "pointer" }} onClick={() => { reset() }}>{loadingReset === false ? "Återställ" : "Återställer.."}</p>}  </div>
                            <hr className='my-2' style={{ borderColor: "#F5F5F5" }}></hr>
                            <p className='mt-2 n-fs-7'>{setting.updateText}</p></div></div>
                    </div>))}
            </CustomScrollbar>
        </div>
    
    }

   


    return (<div> 
        <SectionPopupStyle className='p-4 rounded-top' show={visible} onHide={() => setVisibility()} placement='start' backdrop={false} enforceFocus={false}>
            {startPanelShow && startPanel()}
            {settingsSectionShow && settingsPanel()}

        </SectionPopupStyle>
        <Modal show={infoModal} onHide={() => setInfoModal(false)} size="lg" contentClassName="bg-neutral-cloud p-2" centered>
            {infoModal.title && <Modal.Header className="align-items-start border-0" closeButton>
                <h2 className="neutral-charcoal mb-0 n-h4">{infoModal.title}</h2>
            </Modal.Header>}
            {infoModal.message && <Modal.Body className="py-0">
                <p className='neutral-charcoal'>{infoModal.message}</p>
            </Modal.Body>}
            <Modal.Footer className="justify-content-end border-0">
                <button type="button" className="n-btn btn-forest-green" onClick={() => setInfoModal(false)}>Stäng</button>
            </Modal.Footer>
        </Modal>

        <Modal show={deleteModal} onHide={() => setDeleteModal(false)} contentClassName="bg-neutral-cloud-200 p-2" centered>
            <Modal.Header className="align-items-start border-0" closeButton>
                <h4 className="mb-0">Radera fastighet</h4>
            </Modal.Header>
            <Modal.Body>
                <p className='n-fs-5 neutral-charcoal mb-0'>Är du säker på att du vill radera {deleteModal.designation}?</p>
            </Modal.Body>
            <Modal.Footer className="justify-content-between border-0">
                <button type="button" className="n-btn btn-forest-green" onClick={() => setDeleteModal(false)}>Avbryt</button>
                <button type="button" className="n-btn btn-core" onClick={async () => {
                    let prop = deleteModal
                    prop.loading = true
                    propsContext.setOneProp(prop)
                    setDeleteModal(false)
                    setVisibility()

                    const deletePropResp = await deleteProperty(prop.ref, prop.type)
                    if(deletePropResp.ok) {
                        removePropertyFromCookie(prop.ref)
    
                        let props = propsContext.props.filter(p => p.ref != prop.ref)
                        propsContext.setProps(props)
    
                        if (prop.active) {
                            visaValdaContext.setVisaValda(true)
                        }
                    }
                }}>Radera</button>
            </Modal.Footer>
        </Modal>

        {/* ERROR Modal */}
        <ErrorModal show={errorModal} title={errorModal?.title} message={errorModal?.message} onToggle={() => setErrorModal(false)} />
    </div>
    );
};

export default SettingsPanel;
