import React from 'react';
import styled from "styled-components";
import Joyride from 'react-joyride';

import PropSelector from './comps/PropSelector';
import LineChart from './charts/LineChart';
import PieChart from './charts/PieChart';
import BarChart from './charts/BarChart';

import VBCheckbox from '@virkesborsen/vb-js-commons/lib/components/new/vbCheckbox';
import VBInput from '@virkesborsen/vb-js-commons/lib/components/new/vbInput';
import VBRadioGroup from '@virkesborsen/vb-js-commons/lib/components/new/vbRadioGroup';
import VBLoadingScreen from '@virkesborsen/vb-js-commons/lib/components/vbLoadingScreen';

import {Table,Spinner} from 'react-bootstrap';

import { PropsData, VisaValda } from './context';
import { updateSectionsProcentage, getSBPPdf } from '../../api/services/property';

import { ErrorModal, InfoModal } from '../../modals';
import DepartmentSection from './comps/DepartmentSection';


import ArbonicsModal from './comps/ArbonicsModal';
import HoverLabel from './comps/HoverLabel';
import Cookies from 'js-cookie';

const PropselectorContainer = styled.div`
    width: 90vw;
    max-width: 500px;
    top: 10px;
`

const Span = styled.span`
    border: 1px solid #ccc;
    float: left;
    width: 12px;
    height: 12px;
    margin: 2px;
    background-color: ${props => props.bgColor}; 
`

const infoSteps = [      
    {
        target: '#edit_volume',
        content: 'Här kan du redigera volym på alla avdelningar',
        disableBeacon: true,
    },
    {
        target: '#price_chart',
        content: 'Du kan klicka på linjerna för att få mer information',
        disableBeacon: true,
    },
    {
        target: '#solar-energy',
        content: 'Här kan du se hur många hektar som är lämplig för solenergiproduktion',
        disableBeacon: true,
    },
    {
        target: '#edit_bonitet',
        content: 'Här kan du redigera bonitet på alla avdelningar',  
        disableBeacon: true, 
    },
    {
        target: '#age_chart',
        content: 'Du kan klicka på staplarna för att få mer information',
        disableBeacon: true,
    }
]

export default function Overblick() {
    const propsContext = React.useContext(PropsData);
    const visaValdaContext = React.useContext(VisaValda)

    const [loading, setLoading] = React.useState(true);

    const [edit, setEdit] = React.useState({});
    const [pdfData, setPdfData] = React.useState({includePrice: false});

    const [totalArea, setTotalArea] = React.useState(false);
    const [totalVolume, setTotalVolume] = React.useState(false);
    const [avgGrowth, setAvgGrowth] = React.useState(false);
    const [TGLdata, setTGLdata] = React.useState(false);

    const [valuationChartData, setValuationChartData] = React.useState(false);
    const [fellingClassData, setFellingClassData] = React.useState(false);
    const [volumeAge, setVolumeAge] = React.useState(false);
    const [areaAge, setAreaAge] = React.useState(false);

    const [totalSolarHa, setTotalSolarHa] = React.useState(false);
    const [totalSolarEnergy, setTotalSolarEnergy] = React.useState(false);

    //const [totalCO2, setTotalCO2] = React.useState(false);
    const [totalEco, setTotalEco] = React.useState(false);
    const [totalArbonics, setTotalArbonics] = React.useState(false);
    const [arbonicsModalVisible, setArbonicsModalVisible] = React.useState(false);

    const [totalPropValue, setTotalPropValue] = React.useState({totalValue: 0, change_perc: 0.0, activeProps: 0});

    const [ageGraphView, setAgeGraphView] = React.useState('area');

    const [joyride, setJoyride] = React.useState(false);

    // Modals
    const [errorModal, setErrorModal] = React.useState(false);
    const [infoModal, setInfoModal] = React.useState(false);

    const waitForData = async () => {
        const activeProps = propsContext.props.filter(p => p.active)
        if(activeProps.filter(p => p.data).length != activeProps.length || activeProps.filter(p => p.border).length != activeProps.filter(p => p.type == 'PROP').length) {
            setTimeout(() => waitForData(), 100)
        } else {
            setPriceLineData()
            setTotalValues()
            setLoading(false)
        }
    }

    React.useEffect(() => {
        if(visaValdaContext.visaValda) {
            setPriceLineData()
            setTotalValues()
            visaValdaContext.setVisaValda(false)
        }
    }, [visaValdaContext.visaValda]);
    

    React.useEffect(() => {
        waitForData()
    }, []);
    
    React.useEffect(() => {
        if (!totalSolarEnergy) return;
        const handleHashChange = () => window.location.hash.slice(1).toLocaleLowerCase() == "co2" && document.getElementById('solar-energy-production')?.scrollIntoView({ behavior: "smooth", block: "center" });              

        window.addEventListener("hashchange", handleHashChange);

        handleHashChange();

        return () => window.removeEventListener("hashchange", handleHashChange);
    }, [totalSolarEnergy]);

    const changeVolume = () => {
        let prop = propsContext.props.find(p => p.active == true)

        if (edit.volume == totalVolume) {
            setEdit({...edit, volume: false})
        } else if (edit.volume == 0 || totalVolume == 0) {
            setEdit({...edit, volume: false})
        } else {
            let prevValue = totalVolume
            let editValue = edit.volume
            setTotalVolume(false)
            setEdit({...edit, volume: false})
            updateSectionsProcentage(prop.ref, 'volume', editValue / prevValue, editValue,prop.type).then(res => {
                if(res.ok) {
                    const new_data = res.data
                    setTotalVolume(editValue)

                    try {
                        let kr_m3fub = propsContext.props.find(p => p.active)?.data.prop_value.price_m3sk
                        setTotalPropValue({totalValue: editValue * kr_m3fub, change_perc:totalPropValue.change_perc, activeProps: totalPropValue.activeProps})
                    } catch {
                        console.log("failed to update total prop value after volume update")
                    }
                    
                    
                    prop.data = new_data
                    propsContext.setOneProp(prop)
                } else {
                    setTotalVolume(prevValue)
                    setErrorModal(true)
                }
            })
        }
    }

    const changeGrowth = () => {
        let prop = propsContext.props.find(p => p.active == true)

        if (edit.growth == avgGrowth) {
            setEdit({...edit, growth: false})
        } else {
            let prevValue = avgGrowth
            let editValue = edit.growth
            setAvgGrowth(false)
            setEdit({...edit, growth: false})
            updateSectionsProcentage(prop.ref, 'growth_rate', editValue / prevValue, editValue,prop.type).then(res => {
                if(res.ok) {
                    const new_data = res.data
                    setAvgGrowth(editValue)
                    prop.data = new_data
                    propsContext.setOneProp(prop)
                } else {
                    setAvgGrowth(prevValue)
                    setErrorModal(true)
                }
            })
        }
    }

    const setPriceLineData = () => {
        const activeProps = propsContext.props.filter(p => p.active)

        let curr_year = new Date().getFullYear();
        let data = {}
        let color = ''
        let bar = []
        
        activeProps.map(prop => {
            color = prop.data.valuation_chart_data.line[0].color
            bar = prop.data.valuation_chart_data.bar

            for (let i=0; i < prop.data.valuation_chart_data.years.length; i++) {
                let year = prop.data.valuation_chart_data.years[i];
                
                //find all values for year, should only be multiple if multiple props are selected
                prop.data.valuation_chart_data.line.forEach((itm) => {
                    if (itm.data.length > 0) {
                        if(data[year]){
                            data[year].push(itm.data[i])
                        } else{
                            data[year] = [itm.data[i], ]
                        }
                    }
                })

            }
        })
        
        

        let data_list = []
        let year_list = []
        for (let year in data) {
            let avg = Math.round(data[year].reduce((a,b) => a+b, 0) / data[year].length)
            data_list.push(avg)
            year_list.push(year)
        }

        setValuationChartData({bar: bar, line: [{ color: color, data: data_list }], years: year_list, current_value: data_list[data_list.length -1]})
    }

    const setTotalValues = () => {
        const activeProps = propsContext.props.filter(p => p.active)

        let fellingClasses = []
        let allGrowthRate = []
        let TGL = {tall: 0, gran: 0, lov: 0}

        let volumeAge = new Array(11).fill(0);
        let areaAge = new Array(11).fill(0);
        
        activeProps.map(prop => {
            prop.data.sections.map(sect => {
                // TGL
                TGL.tall += Math.round(sect.tall * sect.volume / 100)
                TGL.gran += Math.round(sect.gran * sect.volume / 100)
                TGL.lov += Math.round(sect.lov * sect.volume / 100)

                if(fellingClasses.find(c => c.class == sect.felling_class)) {
                    fellingClasses.find(c => c.class == sect.felling_class).area += parseInt(sect.area)
                    fellingClasses.find(c => c.class == sect.felling_class).volume += sect.volume
                } else {
                    fellingClasses.push({class: sect.felling_class, area: parseInt(sect.area), volume: sect.volume})
                }

                if(parseFloat(sect.growth_rate) > 0) {
                    allGrowthRate.push(parseFloat(sect.growth_rate));
                }
                
                if(sect.age > 100) {
                    volumeAge[10] += sect.volume
                    areaAge[10] += parseInt(sect.area)
                } else {
                    volumeAge[Math.floor(sect.age / 10)] += sect.volume
                    areaAge[Math.floor(sect.age / 10)] += parseInt(sect.area)
                }
            })
        })

        TGL.total = TGL.tall + TGL.gran + TGL.lov
        TGL.tall = (TGL.tall / TGL.total) * 100
        TGL.gran = (TGL.gran / TGL.total) * 100
        TGL.lov = (TGL.lov / TGL.total) * 100

        setFellingClassData(fellingClasses)
        setAvgGrowth((allGrowthRate.reduce((total, rate) => total + rate, 0) / allGrowthRate.length).toFixed(2))
        setTGLdata(TGL)
        setVolumeAge(volumeAge)
        setAreaAge(areaAge)

        let total = activeProps.reduce((result, prop) => {
            // General
            result.volume += prop.volume
            result.area += prop.data.area

            // Svefa
            result.propValue.totalValue += parseInt(prop.data.prop_value.price_m3sk) * parseInt(prop.volume)
            result.propValue.change_perc += parseFloat(prop.data.prop_value.change_perc)
            result.propValue.activeProps += 1
            

            // Solar
            result.solar_ha += parseFloat(prop.data.solar_ha)
            result.solar_energy.approx_ton_paper += prop.data.solar_energy.approx_ton_paper
            result.solar_energy.approx_villas += prop.data.solar_energy.approx_villas
            result.solar_energy.arrende_per_ha += prop.data.solar_energy.arrende_per_ha
            result.solar_energy.mil_with_truck += prop.data.solar_energy.mil_with_truck
            result.solar_energy.solar_energy += prop.data.solar_energy.solar_energy

            //result.co2.annual_co2_growth += prop.data.co2_data.annual_co2_growth
            //result.co2.carbon_permits += prop.data.co2_data.carbon_permits
            //result.co2.climate_neutral_felling += prop.data.co2_data.climate_neutral_felling
            //result.co2.co2 += prop.data.co2_data.co2

            result.eco.ha += prop.data.eco_credits.ha
            result.eco.credits += prop.data.eco_credits.credits
            result.eco.sek += prop.data.eco_credits.sek_per_credit

            result.arbonics.ha += Number(prop.data.arbonics.ha)
            result.arbonics.price += Number(prop.data.arbonics.price)
            result.arbonics.eligible = result.arbonics.eligible > Number(prop.data.arbonics.eligible) ? result.arbonics.eligible : Number(prop.data.arbonics.eligible)

            return result
        }, {volume: 0, area: 0, solar_ha: 0, TGL: {tall: 0, gran: 0, lov: 0}, solar_energy: {approx_ton_paper: 0, approx_villas: 0, arrende_per_ha: 0, mil_with_truck: 0, solar_energy: 0}, eco: {ha: 0, credits: 0, sek: 0}, arbonics: {ha:0, price: 0, eligible:-1}, propValue: {totalValue: 0, change_perc: 0, activeProps: 0}})
        // , co2: {annual_co2_growth: 0, carbon_permits: 0, climate_neutral_felling: 0, co2: 0}

        setTotalArea(total.area)
        setTotalVolume(total.volume)

        setTotalSolarEnergy(total.solar_energy)
        setTotalSolarHa(total.solar_ha)

        //setTotalCO2(total.co2)
        setTotalEco(total.eco)
        setTotalArbonics(total.arbonics)

        setTotalPropValue({totalValue: total.propValue.totalValue, change_perc: total.propValue.activeProps > 0 ? total.propValue.change_perc / total.propValue.activeProps : total.propValue.change_perc, activeProps: total.propValue.activeProps})
    }


    const SBPPdf = async () => {
        setInfoModal(false)
        let ageChartImg = await html2canvas(pdfData.ageChart).then(canvas => canvas.toDataURL('image/png'));
        let priceChartImg = await html2canvas(pdfData.priceChart).then(canvas => canvas.toDataURL('image/png'));
        let treeChartImg = await html2canvas(pdfData.treeChart).then(canvas => canvas.toDataURL('image/png'));

        await getSBPPdf({alder_chart: ageChartImg, price_chart: pdfData.includePrice ? {price_chart: priceChartImg, current_value: valuationChartData.current_value} : null, tree_chart: treeChartImg}, propsContext.props.filter(p => p.active)).then(resp => {
            const downloadLink = document.createElement("a");
            downloadLink.href = resp.data.file;
            let prop = propsContext.props.find(p => p.active)
            downloadLink.download = `${prop.designation.replace(/[:\s]/g,"_")}.pdf`;
            downloadLink.click();
        })

        pdfData.ageChart.className = pdfData.oldAgeChartClass
        pdfData.priceChart.className = pdfData.oldPriceChartClass
        pdfData.treeChart.className = pdfData.oldTreeChartClass

        pdfData.ageChart.style.setProperty('width', '100%');
        pdfData.priceChart.style.setProperty('width', '100%');
        pdfData.treeChart.style.setProperty('width', '100%');

        setLoading(false);
    }

    const downloadSBPPdf = async () => {
        setLoading(true)
        let ac = document.getElementById('age_chart');
        let tc = document.getElementById('tree_chart');
        let pc = document.getElementById('price_chart');
        
        ac.style.setProperty('width', '700px', 'important');
        pc.style.setProperty('width', '700px', 'important');
        tc.style.setProperty('width', '450px', 'important');

        setPdfData({...pdfData, ageChart: ac, treeChart: tc, priceChart: pc, oldAgeChartClass: ac.className, oldTreeChartClass: tc.className, oldPriceChartClass: pc.className});

        ac.className = 'bg-white neutral-charcoal h-100'
        pc.className = 'bg-white neutral-charcoal h-100'
        tc.className = 'bg-white neutral-charcoal'

        setInfoModal(true);
        
    }
    
    return <div className='position-relative p-3' style={{minHeight: '100vh'}}>
        <VBLoadingScreen zIndex={1035} show_loading={loading} className='position-absolute' />

        <PropselectorContainer className='sticky-top z-1040'>
            <PropSelector />
        </PropselectorContainer>

        <div className='row mb-3'>
            <div className='col-12 d-flex' style={{marginTop: '40px'}}>
                <h1 className='d-inline-flex me-3'>Överblick</h1>
                {propsContext.props.filter(p => p.active).length > 0 && propsContext.props.filter(p => p.active).length < 2 && window.location.pathname.includes('sudo') && <div className='d-inline-flex align-self-center'><><button className="n-btn btn-forest-green btn-sm rounded py-1 px-2 me-1" onClick={(e) => downloadSBPPdf(e)}>Hämta Pdf</button><VBCheckbox label={<label className={`n-fs-8`}>Fastighets-värdering i pdf</label>} initial={false} onClick={() => setPdfData({...pdfData, includePrice: !pdfData.includePrice})}/></></div>}

                {propsContext.props.filter(p => p.active).length > 0 && <div role='button' onClick={() => setJoyride(true)} className='d-flex justify-content-center align-items-center bg-white rounded-4' style={{height: '50px'}}>
                    <img src={`${Window.static_path}img/icons/question_filled.svg`} height='40px' width='40px' />
                </div>}
            </div>
        </div>

        {propsContext.props.filter(p => p.active).length < 1 ? <h2>Välj fastigheter ovanför för att visa data</h2> : 
        <>
            <div className='row mb-3'>
                <div className='col-12 col-md-4 mb-3 mb-md-0  pe-md-0'>
                    <div className='shadow rounded bg-neutral-stone neutral-charcoal h-100 p-2 p-lg-4'>
                        <h5 className="fw-medium">Totalvolym {propsContext.props.filter(p => p.active).length < 2 && <>
                            {totalVolume && !edit.volume && <img id="edit_volume" role="button" onClick={() => setEdit({...edit, volume: totalVolume})} src={Window.static_path + "img/icons/ui-edit.svg"} className="charcoal-filter float-end" width="20px" height="20px" style={{maxHeight: '70vh'}} />}
                            {edit.volume && <img role="button" onClick={() => setEdit({...edit, volume: false})} src={Window.static_path + "img/icons/cross.svg"} className="charcoal-filter float-end" width="20px" height="20px" style={{maxHeight: '70vh'}} />}
                        </>}</h5>
                        {edit.volume ?
                            <>
                                <VBInput type="number" initial={totalVolume} onChange={(val) => setEdit({...edit, volume: val})} />
                                <button className='n-btn btn-forest-green btn-sm rounded mt-2' onClick={() => changeVolume()}>Spara</button>
                            </>
                            : <h3><span className="fw-medium">{totalVolume ? totalVolume.toLocaleString('sv-SE') : <Spinner animation="border" role="status" />}</span> m³sk</h3>}
                    </div>
                </div>

                <div className='col-12 col-md-4 col-lg-3 col-xl-4 mb-3 mb-md-0 px-md-2'>
                    <div className='shadow rounded bg-neutral-stone neutral-charcoal h-100 p-2 p-lg-4'>
                        <h5 className="fw-medium">Areal inkl. vatten</h5>
                        <h3><span className="fw-medium">{totalArea || totalArea == 0 ? totalArea.toLocaleString('sv-SE') : <Spinner animation="border" role="status" />}</span> ha</h3>
                    </div>
                </div>
                <div className='col-12 col-md-4 col-lg-5 col-xl-4 ps-md-0'>
                    <div className='shadow rounded bg-neutral-stone neutral-charcoal h-100 p-2 p-lg-4'>
                        <h5 className="fw-medium mb-2">Estimerat Fastighetsvärde {new Date().getFullYear() - 1}</h5>
                        
                        <h3 className="mb-2">
                            <span className="fw-medium">
                                {totalPropValue.totalValue && totalPropValue.totalValue > 0 ? 
                                <div className="fw-medium d-flex align-items-end">
                                    <p className="fw-medium text-nowrap mb-0">{`${totalPropValue.totalValue.toLocaleString('sv-SE')} kr `}</p>
                                    <span className="fw-normal n-fs-7 text-nowrap ms-2">{`(${totalPropValue.change_perc < 0 ? '':'+'} ${totalPropValue.change_perc.toFixed(1)}% föreg.år)`}</span>
                                </div> 
                                : totalPropValue.totalValue == 0 ? <Spinner animation="border" role="status" /> : 
                                <span className="fw-normal fs-6">Saknas för en vald fastighet</span>}</span></h3>
                        <HoverLabel className="n-fs-7" text="Svefa och Ludvig&Co.">
                            Källor
                        </HoverLabel>
                    </div>
                </div>
            </div>

            <div className='row mb-3'>
                <div className='col-12 col-lg-6'>
                    <div id="price_chart" className='shadow rounded bg-neutral-cloud p-2 p-lg-4'>
                        <h5 className="fw-medium mb-1">Historisk prisutveckling i kr per m³sk</h5>
                        <HoverLabel className="n-fs-7" text="Svefa och Ludvig&Co.">
                            Källor
                        </HoverLabel>
                        {valuationChartData && <LineChart data={{
                            labels: valuationChartData.years,
                            datasets: valuationChartData.line.map((itm) => {
                                return {
                                    label: "Pris",
                                    data: itm.data,
                                    backgroundColor: itm.color,
                                    borderWidth: 4,
                                    borderColor: itm.color,
                                    borderCapStyle: 'butt',
                                    borderDash: [],
                                    borderDashOffset: 0.0,
                                    borderJoinStyle: 'miter',
                                    pointBorderColor: itm.color,
                                    pointBackgroundColor: '#fff',
                                    pointBorderWidth: 5,
                                    pointRadius: 10,
                                    pointHitRadius: 10,
                                    pointHoverRadius: 8,
                                    pointHoverBackgroundColor: '#fff',
                                    pointHoverBorderColor: itm.color,
                                    pointHoverBorderWidth: 5,
                                }
                            })
                        }} />}
                    </div>
                </div>
                <div className="col-12 col-lg-6">
                    <div className='d-flex flex-grow-1 h-100'>
                        <div class="shadow rounded bg-supp-forest-green w-100 p-2 p-lg-4 position-relative">
                            {totalArbonics && totalArbonics.ha >= 1 ? <>
                                <h5 className="fw-medium white mb-3">Kolkrediter</h5>
                                
                                {totalArbonics.eligible == 2 && <p className="white">Du har möjlighet att förlänga omloppstiden på ungefär <span class="h5 fw-medium primary-core">{Number(totalArbonics.ha).toLocaleString()}</span> hektar i din skog och tjäna kolkrediter. Anmäl ditt intresse så hör vi av oss och berättar mer.</p>}
                                {totalArbonics.eligible == 1 && <p className="white">Du har möjlighet att förlänga omloppstiden på ungefär <span class="h5 fw-medium primary-core">{Number(totalArbonics.ha).toLocaleString()}</span> hektar i din skog och tjäna kolkrediter. Vi har fortfarande inte lanserat i ditt län men kommer göra det väldigt snart. Skriv upp dig här så hör vi av oss så fort vi lanserat i ditt län.</p>}
                                
                                <div className="bg-supp-forest-green-300 rounded-3 p-3 position-relative">
                                    <div className="d-flex align-items-center pb-3">
                                        <h2 className="white mb-0 me-1">{Number(totalArbonics.price).toLocaleString()}</h2>
                                        <span className="h5 white ps-1" style={{marginBottom:"-10px"}}>SEK</span>
                                    </div>
                                    <p className="white mb-0" >Estimerat värde</p>
                                </div>
                                <p>
                                    
                                </p>
                                {/* {Cookies.get("arbonicsModalSent") ? 
                                null */}
                                <button onClick={() => {mixpanel.track("show_arbonics_modal"); setArbonicsModalVisible(true)}} className="btn py-1 btn-outline bg-white border-white hover-border-supp-forest-green-300 btn-sm mb-3 bottom-0 d-flex align-items-center" style={{position: screen.width >= 992 ? "absolute": "initial"}}>Anmäl ditt intresse här <img className="ms-1" style={{width:"20px"}} src={`${Window.static_path}img/icons/caret.svg`} /></button>
                            </> : <>
                                <h5 className="fw-medium primary-core">Potential för Kolrediter</h5>
                                <p className="fw-medium white"><img src={`${Window.static_path}img/icons/co2_euro.svg`} height="30px" className="primary-core-filter me-1" />Din fastighet har tyvärr inte möjlighet att tjäna kolkrediter just nu, om du tycker vi har gjort ett fel mailar du till <a className="white" href="mailto:info@treebula.se">info@treebula.se</a> och skickar med din skogsbruksplan.</p>
                            </>}
                        </div>
                    </div>
                </div>
            </div>

            <div className='row mb-3'>
                <div className='col-12 col-xl-6 position-relative mb-3 mb-md-0'>
                    <div className='shadow rounded bg-neutral-cloud h-100 p-2 p-lg-4'>
                        <h5 className='fw-medium'>Huggningsklasser</h5>
                        <p className='subtitle'>Uppskattad fördelning av olika ägoslag</p>


                        {fellingClassData && <Table striped>
                            <thead>
                                <tr className='bg-neutral-cloud-600'>
                                    <th className='fw-medium' scope="col">Huggningsklass</th>
                                    <th className='fw-medium' scope="col">Total areal (ha)</th>
                                    <th className='fw-medium' scope='col'>Total volym (m³sk)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {fellingClassData.map(fellingClass => {
                                    return <tr>
                                        <td>{fellingClass.class}</td>
                                        <td>{fellingClass.area}</td>
                                        <td>{fellingClass.volume}</td>
                                    </tr>
                                })}
                            </tbody>
                        </Table>}
                    </div>
                </div>

                <div id="solar-energy-production" className='col-12 col-xl-6'>
                    <div id='solar-energy' className='shadow rounded bg-neutral-stone p-2 p-lg-4'>
                        {totalSolarEnergy && totalSolarHa >= 5 ? <>
                            <h5 className="fw-medium">Solenergiproduktion</h5>
                            <p>Du har <span className="h5 fw-medium tertiary-main">{Math.round(totalSolarHa).toLocaleString('sv-SE')}</span> hektar lämplig för storskalig solenergiproduktion.</p>
                            <p className="mb-1">Estimerat arrendevärde:</p>
                            <h2 className="tertiary-main fw-medium">{Math.round(totalSolarHa * totalSolarEnergy.arrende_per_ha).toLocaleString('sv-SE')} <span className="h5">kr/år</span></h2>
                            <p className="mb-1">Uppskattad energiproduktion:</p>
                            <h2 className="tertiary-main fw-medium">{Math.round(totalSolarHa * totalSolarEnergy.solar_energy).toLocaleString('sv-SE')} <span className="h5">MWh/år</span></h2>

                            <p className="mb-1">Det motsvarar:</p>
                            <div className="d-flex align-items-center">
                                <img src={`${Window.static_path}img/icons/lightning-charge.svg`} height="30px" className="tertiary-main-filter me-1" />
                                <p className="fw-medium mb-0">{Math.round(totalSolarHa * totalSolarEnergy.mil_with_truck).toLocaleString('sv-SE')} mil med eldriven lastbil.</p>
                            </div>

                            <div className="d-flex align-items-center my-1">
                                <img src={`${Window.static_path}img/icons/lightning-charge.svg`} height="30px" className="tertiary-main-filter me-1" />
                                <p className="fw-medium mb-0">Tillverkning av {Math.round(totalSolarHa * totalSolarEnergy.approx_ton_paper).toLocaleString('sv-SE')} ton kontorspapper.</p>
                            </div>

                            <div className="d-flex align-items-center">
                                <img src={`${Window.static_path}img/icons/lightning-charge.svg`} height="30px" className="tertiary-main-filter me-1" />
                                <p className="fw-medium mb-0">Årsförbrukningen i {Math.round(totalSolarHa * totalSolarEnergy.approx_villas).toLocaleString('sv-SE')} villor.</p>
                            </div>
                            
                            <p className="subtitle mt-2 mb-0">Om du vill ha en mer nogrann bedömning eller har andra frågor, kontakta vår partner <a className="hover-primary-core fw-medium" onClick={() => {mixpanel.track("Clicked Recap mailto"); window.location.href="mailto:christer.boman@recap.se?subject=Jag vill veta mer om storskalig solenergiproduktion";}} href="#">Recap Energy (recapenergy.com)</a></p>
                        </> : <>
                            <h5 className="fw-medium">Potential för storskalig solenergiproduktion</h5>
                            <p className="fw-medium"><img src={`${Window.static_path}img/icons/lightning-charge.svg`} height="30px" className="supp-pine-filter me-1" />Nej</p>
                        </>}
                    </div>
                </div>
            </div>

            <div className='row mb-3'>
                <div className='col-12 col-xl-6 d-flex flex-column'>
                    <div className='mb-3'>
                        <div className='shadow rounded bg-neutral-stone neutral-charcoal p-2 p-lg-4'>
                            <h5 className="fw-medium">Medelbonitet {propsContext.props.filter(p => p.active).length < 2 && <>
                                {avgGrowth && !edit.growth && <img id='edit_bonitet' role="button" onClick={() => setEdit({...edit, growth: avgGrowth})} src={Window.static_path + "img/icons/ui-edit.svg"} className="charcoal-filter float-end" width="20px" height="20px" style={{maxHeight: '70vh'}} />}
                                {edit.growth && <img role="button" onClick={() => setEdit({...edit, growth: false})} src={Window.static_path + "img/icons/cross.svg"} className="charcoal-filter float-end" width="20px" height="20px" style={{maxHeight: '70vh'}} />}
                            </>}</h5>
                            {edit.growth ?
                                <>
                                    <VBInput type="number" initial={avgGrowth} onChange={(val) => setEdit({...edit, growth: val})} />
                                    <button className='n-btn btn-forest-green btn-sm rounded mt-2' onClick={() => changeGrowth()}>Spara</button>
                                </>
                                : <h3><span className="fw-medium">{avgGrowth ? Math.round(avgGrowth) : <Spinner animation="border" role="status" />}</span> m³sk/ha/år</h3>}
                        </div>
                    </div>
                    
                    <div className='d-flex flex-grow-1 mb-3 mb-md-0'>
                        {TGLdata && <div id="tree_chart" className='shadow rounded bg-neutral-cloud w-100 neutral-charcoal p-2 p-lg-4'>
                            <div className='d-flex justify-content-between'>
                                <div>
                                    <h5 className='fw-medium'>Trädslagsfördelning</h5>
                                    <p>Estimerad trädslagsfördelning</p>
                                </div>
                            </div>
                            
                            
                            <PieChart data={{
                                datasets: [
                                    {
                                        label: 'Trädslagsfördelning',
                                        data: [(Math.trunc(TGLdata.tall)), (Math.trunc(TGLdata.gran)), (Math.trunc(TGLdata.lov))],
                                        backgroundColor: [
                                            '#00ABAC',
                                            '#A2CCB7',
                                            '#EAEEED',
                                        ],
                                        borderColor: [
                                            '#00ABAC',
                                            '#A2CCB7',
                                            '#EAEEED',
                                        ],
                                        borderWidth: 1,
                                    },
                                ],
                            }} />

                            <ul className='d-flex flex-column' style={{ listStyle: "none" }}>
                                <li className='caption fw-medium'><Span bgColor="#00ABAC"></Span> &nbsp; Tall: {Math.trunc(TGLdata.tall)} %</li>
                                <li className='caption fw-medium'><Span bgColor="#C4D0CD"></Span> &nbsp; Gran: {Math.trunc(TGLdata.gran)} %</li>
                                <li className='caption fw-medium'><Span bgColor="#EAEEED"></Span> &nbsp; Löv: {Math.trunc(TGLdata.lov)} %</li>
                            </ul>
                        </div>}
                    </div>
                </div>

                <div className='col-12 col-xl-6'>
                    <div className='mb-3'>
                        <div className='shadow rounded bg-neutral-stone neutral-charcoal h-100 p-2 p-lg-4'>
                            <h5 className="fw-medium">Har du virke eller GROT att sälja? </h5>

                            <p>Vi hjälper dig ta in anbud!</p>
                            <p>✓ Kostnadsfritt – Vi tar betalt från skogsbolagen.</p>
                            <p>✓ Fri prövningsrätt och inga förbindelser.</p>
                            <p>✓ Nå ut till en bredare köparbas för det bästa priset.</p>
                            <p>✓ Dedikerat stöd och rådgivning under hela processen.</p>
                            <h5 className='fw-medium'><a href="/salj-virke/" className='supp-forest-green'>Klicka här för en prisförfrågan.</a></h5> 
                        </div>
                    </div>

                    
                </div>
            </div>

            <div className='row mb-3'>
                <div className='col-12'>
                    <div className='position-relative h-100'>
                        <div id='age_chart' className='shadow rounded bg-neutral-cloud neutral-charcoal h-100 p-2 p-lg-4'>                           
                            <div className='d-flex justify-content-between'>
                                <h5 className='fw-medium'>Åldersfördelning</h5>

                                <div className='caption'>
                                    <VBRadioGroup
                                        options={[{ value: 'area', label: "Areal (ha)" }, { value: 'volume', label: "Volym (m³sk)" }]}
                                        initial={ageGraphView}
                                        onChange={(val) => setAgeGraphView(val)} />
                                </div>
                            </div>

                            <div className='d-flex justify-content-between'>
                                <p>Estimerad åldersfördelning</p>

                                <div>
                                    <p className='caption fw-medium mb-0'>Y-axeln: {ageGraphView == 'area' ? 'Areal (ha)' : ageGraphView == 'volume' ? 'Volym (m³sk)' : ''}</p>
                                    <p className='caption fw-medium mb-0'>X-axeln: Ålder</p>
                                </div>
                            </div>
                            {areaAge && ageGraphView == 'area' ? <BarChart 
                                data={{
                                    labels: ["0-10", "10-20", "20-30", "30-40", "40-50", "50-60", "60-70", "70-80", "80-90", "90-100", "100+"],
                                    datasets: [
                                        {
                                            label: "Areal",
                                            data: areaAge,
                                            backgroundColor: '#A2CCB7',
                                            borderSkipped: false,
                                            borderWidth: 1,
                                            borderRadius: 50,
                                            categoryPercentage: 0.4,
                                            barPercentage: 1.0
                                        },
                                    ],
                                }}
                                y={{
                                    ticks: {
                                        color: '#1C332A',
                                        fontSize: 60,
                                        fontWeight: 'bold',
                                        beginAtZero: true,
                                        min: 0,
                                        max: 1000,
                                        stepSize: 10,
                                        grace: "0.005%",
                                        callback: function (value, index, ticks) {
                                            return value + " " + '-';
                                        },
                                    },
                                    grid: {
                                        drawBorder: false,
                                        display: false,
                                    },
                                }} /> : volumeAge && ageGraphView == 'volume' ? <BarChart 
                                data={{
                                    labels: ["0-10", "10-20", "20-30", "30-40", "40-50", "50-60", "60-70", "70-80", "80-90", "90-100", "100+"],
                                    datasets: [
                                        {
                                            label: "Volym",
                                            data: volumeAge,
                                            backgroundColor: '#037971',
                                            borderSkipped: false,
                                            borderWidth: 1,
                                            borderRadius: 50,
                                            categoryPercentage: 0.4,
                                            barPercentage: 1.0
                                        },
                                    ],
                                }} 
                                y={{
                                    ticks: {
                                        color: '#1C332A',
                                        fontSize: 60,
                                        fontWeight: 'bold',
                                        beginAtZero: true,
                                        min: 0,
                                        max: 3000,
                                        stepSize: 100,
                                        grace: "0.005%",
                                        callback: function (value, index, ticks) {
                                            return value + " " + '-';
                                        },
                                    },
                                    grid: {
                                        drawBorder: false,
                                        display: false,
                                    },
                                }}/>
                            : null}
                            </div>
                        </div>
                    </div>
                </div>

            <div className="row">
                <div className="col-12 mb-5">
                    <DepartmentSection propsContext={propsContext.props.filter(p => p.active)}
                    table={[
                        { label: 'Avd #', accessor: 'localID' },
                        { label: 'Area', accessor: 'area' },
                        { label: 'Ålder', render: (section) => `${Math.floor(section.age / 10) * 10}-${Math.floor(section.age / 10) * 10 + 10}` },
                        { label: 'HKL', accessor: 'felling_class' },
                        { label: 'SI', render: (section) => (section.si > 0 ? `${section.major_tree}${section.si}` : null) },
                        { label: 'Volym', accessor: 'volume' },    
                        { label: 'T | G | L', render: (section) => `${section.tall} | ${section.gran} | ${section.lov}` },
                        { label: 'Bonitet', render: (section) => Math.round(section.growth_rate) }
                    ]}
                    tableAddedColumn="Fastighet" 
                    sortOptions="localID"
                    accordionTitle={[
                        { label: '#', accessor: 'localID' },
                        { label: 'Area:', accessor: 'area' },
                        { label: 'HKL:', accessor: 'felling_class' },
                        { label: 'Volym:', accessor: 'volume' },
                    ]} 
                    accordionData={[
                        {label: "Skogsdetaljer:",options:[{option:"Alder:",render:(section)=>(<strong>{`${Math.floor(section.age / 10) * 10}-${Math.floor(section.age / 10) * 10 + 10}`} år</strong>)},
                            {option:"SI:",render:(section)=>(<strong>{section.si > 0 ? `${section.major_tree}${section.si}` : null}</strong>)}]},
                        {label:"Trädslag:",options:[{option:"",render:(section)=>(<>Tall: <strong>{section.tall}%</strong> |Gran: <strong>{section.gran}%</strong> |Löv: <strong>{section.lov}%</strong></>)}]},
                        {label:"Produktivitet:", options:[{option:"Bonitet:",render:(section)=>(<strong>{Math.round(section.growth_rate)}</strong>)}]}
                    ]}
                    mainTitle="Avdelningsbeskrivning"
                    mainSubTitle="Sammanfattning av samtliga avdelningar"/>
                </div>
            </div>

        </>}

        {/* ERROR Modal */}
        <ErrorModal show={errorModal} title={errorModal?.title} message={errorModal?.message} onToggle={() => setErrorModal(false)} />
        {/* SBP PDF Modal */}
        <InfoModal show={infoModal} title={'Skogsbruksplan PDF'} message={`Du genererar pdf för ${propsContext.props.filter(p => p.active)[0]?.designation}. Uppskattad genereringstid: ${Math.max(8, Math.round(propsContext.props.filter(p => p.active)[0]?.data?.sections?.length * 0.13))} sek.`} onToggle={() => SBPPdf()} />

        <Joyride
            run={joyride}
            continuous={true}
            steps={infoSteps}
            scrollDuration={0}
            scrollOffset={100}
            showSkipButton={true}
            showProgress={true}
            hideCloseButton={true}
            locale={{
                back: 'Tillbaka',
                close: 'Stäng',
                last: 'Kom igång',
                next: 'Nästa',
                skip: 'Hoppa över'
            }}
            styles={{
                options: {
                    textColor: '#2a2e33',
                    primaryColor:'#023436'
                },
            }}
            callback={data => {
                if(data.status != 'running') {
                    setJoyride(false)
                    document.getElementById('mainContainer').scrollTop = 0
                }
            }} />

        <ArbonicsModal visible={arbonicsModalVisible} hide={() => {setArbonicsModalVisible(false)}} />
    </div>
}