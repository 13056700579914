import React from "react";
import styled from "styled-components";
import { Button } from "react-bootstrap";

const StepButton = ({
  onClick,
  disabled,
  text = "OK",
  isLoggedIn,
  className = "",
  showIntroText = false,
  isLoading = false,
}) => {
  return (
    <Container>
      {showIntroText && (
        <SmallText>
          {isLoggedIn ? (
            <>
              <span>En snabb fråga</span>
              <span>- det tar bara ett ögonblick</span>
            </>
          ) : (
            <>
              <span>Svara på 5 enkla frågor</span>
              <span> - det tar bara 45 sekunder</span>
            </>
          )}
        </SmallText>
      )}
      <StepButtonContainer className={`${className} d-flex align-items-center`}>
        <StyledButton
          size="sm"
          className="px-4 py-2 rounded-pill supp-forest-green bg-primary-core next-button"
          disabled={disabled || isLoading}
          onClick={onClick}
        >
          {isLoading ? (
            <div className="d-flex align-items-center justify-content-center">
              <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
              Skickar...
            </div>
          ) : (
            text
          )}
        </StyledButton>
        {!isLoading && (
          <span className="ms-3 desktop-info" style={styles.enterText}>
            tryck Enter ↵
          </span>
        )}
      </StepButtonContainer>
    </Container>
  );
};

const styles = {
  enterText: {
    fontSize: "14px",
  },
  clockIcon: {
    filter: `map-get($vb-colors, white-500)`
  },
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  padding-top: 16px;
`;

const StepButtonContainer = styled.div`
  @media (max-width: 576px) {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 20px;
    background-color: #023436;
    z-index: 10;
    display: flex;
    justify-content: center;
    margin: 0;

    .desktop-info {
      display: none;
    }

    .next-button {
      width: 100%;
    }
  }

  @media (min-width: 576px) {
    &.center {
      justify-content: center;
    }
  }
`;

const SmallText = styled.small`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  color: #DFFF5D;
  font-weight: 600;
`;

const StyledButton = styled(Button)`
  &.next-button {
    border-color: #dfff5d;
    color: #000;
    font-weight: 500;
    transition: transform 0.2s;
    border: none;
    outline: none;
  }
  &:hover {
    background-color: #CBE855 !important;
    border-color: #CBE855 !important;
  }
`;

export default StepButton;
