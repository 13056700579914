import React from 'react';
import Cookies from 'js-cookie';

import Navigation from '../components/static/Navigation';
import Footer from '../components/static/Footer';

import VBInput from "@virkesborsen/vb-js-commons/lib/components/new/vbInput";
import VBDropdown from "@virkesborsen/vb-js-commons/lib/components/new/vbDropdown";
import VBLoadingScreen from "@virkesborsen/vb-js-commons/lib/components/vbLoadingScreen";

import { RegisterModal } from '../modals';
import { validateForm, userExists } from '../api';

import * as Opt from '../components/editor/Helpers';
import ColorSelector from '../components/editor/fields/ColorSelector';

import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend, Filler } from 'chart.js';
import { Line } from 'react-chartjs-2';
import LoginFormModal from '../forms/LoginFormModal';
import { handleInputChange } from '../forms/handlers/formHandlers';

ChartJS.register( CategoryScale, LinearScale, PointElement, LineElement,  Tooltip, Legend, Filler );

class PropertyValue extends React.Component {
    constructor() {
        super();

        const urlParams = new URLSearchParams(window.location.search);

        this.state = {
            muni: urlParams.get('muni') ? parseInt(urlParams.get('muni')) : null,
            area: urlParams.get('area') ? urlParams.get('area') : null,
            email: urlParams.get('email') ? urlParams.get('email') : null,
            mobile_phone_number: urlParams.get('mobile_phone_number') ? urlParams.get('mobile_phone_number') : null,
            didReg: urlParams.get('register') == 'true' ? true : false,
            isReg: null,
            isLogged: Window.user_data.isLogged,
            errors: {},
            loading: false,
            didRegModalShow: false,
            loginFormModal: false,
            userForm: {},
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.checkEmailOrPhonenumber = this.checkEmailOrPhonenumber.bind(this);

        this.emailorPhoneInput = React.createRef();
    }

    componentDidMount() {
        const urlParams = new URLSearchParams(window.location.search);
        let email =urlParams.get("email")
        let phonenumber = urlParams.get("mobile_phone_number")

        let loginCredential
        if (email){
            email= decodeURIComponent(email)
           loginCredential = `email=${encodeURIComponent(email)}`
           this.setState({userForm:{...this.state.userForm,email:email}})
        }
        else if (phonenumber){
            phonenumber = decodeURIComponent(phonenumber)
            loginCredential =`mobile_phone_number=${encodeURIComponent(phonenumber)}`
            this.setState({userForm:{...this.state.userForm,mobile_phone_number:phonenumber}})
        }
 
        if(loginCredential !== null) {
            userExists(loginCredential).then(resp => {
                if (resp.ok) {
                    resp.json().then((json) => {
                        if(urlParams.get('muni') && urlParams.get('area')) {
                            this.setState({ hideEmailInput: json.exists, isReg:json.exists, multiple_mobile_users:json.multiple_mobile_users, userForm:{...this.state.userForm,muni:parseInt(urlParams.get('muni')), area:urlParams.get('area')}},()=>{
                                this.getPriceData()
                            })
                        }
                    })
                }
            })
        }


        if(Window.user_data.isLogged){
            if(urlParams.get('muni') && urlParams.get('area')) {
                this.setState({userForm:{...this.state.userForm, muni:parseInt(urlParams.get('muni')), area:urlParams.get('area')}},()=>{
                    this.getPriceData()
                })
                
            }
        }

    

    }

    getPriceData() {
        const headers = {
            'Content-Type': 'application/json',
            'X-CSRFToken': Cookies.get('csrftoken')
        }
        let muni
        if (Array.isArray(this.state.muni)){
            muni =  (this.state.userForm.muni)[0].toString()
        }
        else{
            muni = this.state.userForm.muni
        }

        let requestData = this.state.isLogged && (!this.state.email || !this.state.mobile_phone_number) ? {
            area: this.state.userForm.area,
            muni: muni
        } : {
            area: this.state.userForm.area,
            muni: muni,
            ...(this.state.userForm.email && { email: this.state.userForm.email }),
            ...(this.state.userForm.mobile_phone_number && { mobile_phone_number: this.state.userForm.mobile_phone_number })
        }

        return fetch("/price-api/prop-price-lite/", {
            method: 'POST',
            headers: headers,
            credentials: 'same-origin',
            body: JSON.stringify(requestData)}).then(resp => {
                if (resp.ok) {
                    resp.json().then((json) => {
                        let lineData = json.data
                        lineData.line.datasets.map(i => {
                            if(i.label == 'Lägsta kubikpris') { i.borderColor = '#2FAF9A'; i.backgroundColor = 'rgb(47, 175, 175, 0.3)' }
                            if(i.label == 'kr/m3sk') { i.borderColor = '#F26157'; i.backgroundColor = 'rgb(242, 97, 87, 0.3)' }
                            if(i.label == 'Högsta kubikpris') { i.borderColor = '#6A58C6'; i.backgroundColor = 'rgb(106, 88, 198, 0.3)' }
                            i.borderWidth = 4
                            i.pointBorderWidth = 3
                            i.fill = true
                        })
                        gtag("event", "did_prop_price_lite", { method: "Site" });
                        this.setState({ data: lineData, loading: false, registerModal: !this.state.didRegModalShow ? this.state.didReg : false })
                    })
                } else {
                    alert('Something went wrong!')
                    this.setState({ loading: false })
                }
        });
    }

    handleSubmit(e) {
        e.preventDefault()

        let checkList = [];
        let data = this.state.userForm;

        if (this.state.isLogged) {
            checkList.push('muni', 'area');
        } else {
            checkList.push('email_phone','muni', 'area');
        }

        // Validate the fields
        let validation = validateForm(data, checkList, this.state.isReg);

        if (Object.keys(validation).length !== 0) {
            this.setState({ errors: validation })
        } else {
            this.setState({ loading: true, errors: {} })

            if (!this.state.isLogged && !this.state.isReg) {
                // Removed municipality from userform for LoginForm since user can select multiple muni in login form
                const {muni, ...rest}=this.state.userForm
                mixpanel.track("login_start_fe", {});
                this.setState({loginFormModal:true,userFormRemovedMuni:rest});         
            } else {
                if(this.state.multiple_mobile_users){
                    this.setState({loginFormModal:true,formStatus:'multiplePhonenumberUsers'});
                }else{
                    let loginCredential = ''
                    if (data.email) {
                        loginCredential = `email=${encodeURIComponent(data.email)}`
                    } else if (data.mobile_phone_number) {
                        loginCredential = `mobile_phone_number=${encodeURIComponent(data.mobile_phone_number)}`
                    }
                    window.location.href = `/${this.props.pageUrl != '' ? this.props.pageUrl.replaceAll('/', '') : 'fastighetsvardering'}/?muni=${data.muni}&area=${data.area}&${loginCredential}`;
            }
                
            }
        }
    } 

    handleLoginCompletion = () => {
        if (this.state.multiple_mobile_users) {
            this.setState({ loginFormModal: false, loading: false });
        } else {
            this.setState(
                {
                    isLogged: true,
                    loading: false,
                    loginFormModal: false,
                    errorModal: false
                },
                () => {
                    const loginCredential = this.getLoginCredential(this.state.userForm);
                    const nextUrl = `/${this.props.pageUrl != '' ? this.props.pageUrl.replaceAll('/', '') : 'fastighetsvardering'}/?muni=${this.state.userForm.muni}&area=${this.state.userForm.area}&${loginCredential}&register=true`;
    
                    window.location.href = nextUrl;
                }
            );
        }
    };
    
    getLoginCredential = (userForm) => {
        if (userForm.email) {
            return `email=${encodeURIComponent(userForm.email)}`;
        } else if (userForm.mobile_phone_number) {
            return `mobile_phone_number=${encodeURIComponent(userForm.mobile_phone_number)}`;
        }
        return '';
    };
    
        
    //validations

     //this is the VBInput for email or mobile number
    handleInputChange = (val) => {
        handleInputChange(val, this.setState.bind(this), this.checkEmailOrPhonenumber, 'userForm');
    }

    checkEmailOrPhonenumber(val) {
        // clear timeout if the user is still typing before timers is over
        if (this.timeout) { clearTimeout(this.timeout) };

            this.timeout = setTimeout(() => {
                userExists(val).then(resp => {
                    if (resp.ok) {
                        resp.json().then((json) => {
                            this.setState({ isReg: json.exists, multiple_mobile_users:json.multiple_mobile_users })
                        })
                    }
                })
        }, 200)
    }

    getNextUrl(){
        return `/${this.props.pageUrl != '' ? this.props.pageUrl.replaceAll('/', '') : 'fastighetsvardering'}/?area=${this.state.area}&muni=${this.state.muni}`;
    }

    render() {
        return <div className="container-fluid p-0">
            <div className="bg-white">

                <VBLoadingScreen show_loading={this.state.loading} />
                <LoginFormModal
                    visible={this.state.loginFormModal}
                    onHide={() => this.setState({loginFormModal: false, loading: false})}
                    data={this.state.userFormRemovedMuni}
                    next_url={this.getNextUrl()}
                    completed={this.handleLoginCompletion}
                    formStatus={this.state.formStatus || 'login'}
                />

                <div className={`container bg-${this.props.backgroundColor} ${this.props.textColor}`}>
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-8 pb-2">
                            <form className='mb-5' onSubmit={(e) => {this.handleSubmit(e)}}>
                                <div className="row">
                                    <div className="col-12 col-xl-6">
                                        <VBDropdown
                                            label={<label className='fw-medium pb-1 mt-3'>Kommun</label>}
                                            initial={this.state.muni}
                                            placeholder="Välj kommun"
                                            options={Window.react_data.lists.munis}
                                            error={this.state.errors.muni}
                                            onFocus={() => this.setState({ errors: {...this.state.errors, muni: ''}})}
                                            onChange={(val) => { this.setState({ userForm: {...this.state.userForm, muni: parseInt(val.value)} }) }} />
                                    </div>

                                    <div className="col-12 col-xl-6">
                                        <VBInput
                                            label={<label className='fw-medium pb-1 mt-3'>Skogsareal</label>}
                                            initial={this.state.area}
                                            placeholder="Välj areal"
                                            type="number"
                                            error={this.state.errors.area}
                                            onFocus={() => this.setState({ errors: {...this.state.errors, area: ''}})}
                                            onChange={(val) => { this.setState({userForm:{ ...this.state.userForm, area: val }}); }} />
                                    </div>
                                </div>

                                {!this.state.isLogged && !this.state.hideEmailInput &&  
                                    <VBInput 
                                        ref={ this.emailorPhoneInput}
                                        label={<label className='n-fs-7 mt-3 neutral-pebble'>E-postadress / Mobilnummer</label>}
                                        placeholder={"E-postadress / Mobilnummer"}
                                        error={this.state.errors.email_phone}
                                        onFocus={() => {this.setState({errors:{...this.state.errors, email_phone:''}})}}
                                        onChange={(val) => this.handleInputChange(val)}
                                    />
                                }
                                
                                <div className='mt-3 col-12 col-md-5'>
                                <button type="submit" className="n-btn btn-forest-green w-100">Visa fastighetsvärdering</button>
                                </div>
                            </form>

                            {this.state.data && <div className="row mt-5">
                                <div className="col-12">
                                    <h3 className={'n-md-h4 ' + `${this.props.textColor}`}>Fastighetsvärde:</h3>
                                </div>

                                <div className="col-4 d-flex flex-column justify-content-end">
                                    <p className="text-center mb-0">{Math.round(this.state.data.bar.find(d => d.id === 'Lågt').value).toLocaleString('sv-SE')} kr</p>
                                    <div className="bg-supp-spruce rounded-5 w-75 mx-auto" style={{height: `${Math.round(300 * (1 / this.state.data.bar.find(d => d.id === 'Högt').value * this.state.data.bar.find(d => d.id === 'Lågt').value))}px`}}></div>
                                    <p className="text-center mt-1">Lågt</p>
                                </div>

                                <div className="col-4 d-flex flex-column justify-content-end">
                                    <p className="text-center mb-0">{Math.round(this.state.data.bar.find(d => d.id === 'Medel').value).toLocaleString('sv-SE')} kr</p>
                                    <div className="bg-supp-plum rounded-5 w-75 mx-auto" style={{height: `${Math.round(300 * (1 / this.state.data.bar.find(d => d.id === 'Högt').value * this.state.data.bar.find(d => d.id === 'Medel').value))}px`}}></div>
                                    <p className="text-center mt-1">Medel</p>
                                </div>

                                <div className="col-4">
                                    <p className="text-center mb-0">{Math.round(this.state.data.bar.find(d => d.id === 'Högt').value).toLocaleString('sv-SE')} kr</p>
                                    <div className="bg-supp-scarlet rounded-5 w-75 mx-auto" style={{height: "300px"}}></div>
                                    <p className="text-center mt-1">Högt</p>
                                </div>

                                <div className="col-12 mt-3">
                                    <h3 className={'mb-3 mt-4 n-md-h4 ' + `${this.props.textColor}`}>Historiska fastighetspriser (kr/m³sk) :</h3>

                                    <Line data={this.state.data.line} options={{ responsive: true, plugins: {datalabels: { display: false}} }} style={{minHeight: "300px"}} />
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>

            <RegisterModal show={this.state.registerModal} onToggle={() => this.setState({registerModal: !this.state.registerModal, didRegModalShow: true})} passwordSend={true} />
        </div>
    }
}

PropertyValue.defaultProps = {
    textColor: "neutral-charcoal",
    backgroundColor: "white",
    pageUrl: "",
}

PropertyValue.fields = {
    pageUrl: {
        type: "text",
    },
    backgroundColor: {
        type: "custom",
        render: (props) => <ColorSelector {...props} />
    },
    textColor: {
        type: "select",
        options: Opt.colorVariables
    },

}

export default PropertyValue;
