import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import StepButton from "./StepButton";
import styled from "styled-components";

const WelcomeStep = ({ onNext, isLoggedIn }) => {
  return (
    <div className="min-vh-100 d-flex flex-column">
      <Container
        fluid
        className="flex-grow-1 d-flex align-items-sm-center text-center"
      >
        <Row>
          <Title>Kom i kontakt med din rådgivare kostnadsfritt</Title>
          <Subtitle className="opacity-75">
            Klicka på start och fyll i formuläret så återkommer vi till dig
            direkt —<strong className="px-1">kostnadsfritt och utan förbindelser</strong>
          </Subtitle>

          <div className="d-flex align-items-center justify-content-center">
            <StepButton
              onClick={onNext}
              disabled={false}
              className="center"
              text="Starta"
              showIntroText={true}
              isLoggedIn={isLoggedIn}
            />
          </div>
        </Row>
      </Container>
    </div>
  );
};

const Title = styled.h1`
  font-size: 36px;
  margin-bottom: 1.5rem;

  @media (max-width: 576px) {
    padding-top: 48px;
    margin-bottom: 0;
    font-size: 28px;
    text-align: center;
  }
`;

const Subtitle = styled.p`
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 1.5rem;

  @media (max-width: 576px) {
    font-size: 16px;
    margin-bottom: 0;
    text-align: center;
  }
`;

export default WelcomeStep;
