import React from 'react';
import styled from "styled-components";

import MainNav from '../components/minskog/comps/MainNav';
import Karta from '../components/minskog/Karta';
import Overblick from '../components/minskog/Overblick';
import Risker from '../components/minskog/Risker';

import { PropsData, Navigation, VisaValda } from '../components/minskog/context';

const ContentView = styled.div`
    width: 100%;
    height: calc(100% - 80px);

    @media (min-width:992px) {
        width: calc(100% - 270px);
        height: 100%;
    }
`

export default function MinSkog() {
    const kartaRef = React.useRef();
    const riskerRef = React.useRef();
    
    const urlSearchParams = new URLSearchParams(window.location.search);
    const [props, setProps] = React.useState(Window.react_data.owned_props);
    const [view, setView] = React.useState(urlSearchParams.get('view') ? {comp: urlSearchParams.get('view')} : history.state && history.state != null ? history.state : {comp: 'karta'});
    const [visaValda, setVisaValda] = React.useState(false);

    React.useEffect(() => {
        window.addEventListener('popstate', () => handleHistory());

        if(urlSearchParams && urlSearchParams.get('view')) {
            const url = new URL(window.location.href)
            const view = {comp: urlSearchParams.get('view')}
            url.searchParams.delete('view')
            history.pushState(view, '', url.toString());
        }
        
        return () => {
            window.removeEventListener('popstate', () => handleHistory());
        };
    }, [])

    const handleHistory = () => {
        setView(history.state ? history.state : {comp: 'karta'})

        if(history.state) {
            if(history.state.active) {
                const newActiveProps = props.map(p => history.state.active.includes(p.ref) ? {...p, active: true} : {...p, active: false})
                setProps(newActiveProps)
                setVisaValda(true)
            }
    
            if(history.state.comp == 'karta') {
                kartaRef.current.handleHistory(history.state.section)
            }
    
            if(history.state.comp == 'risker') {
                riskerRef.current.handleHistory()
            }
        }
    }

    const setOneProp = (p) => {
        if(props.find(prop => prop.ref == p.ref)) {
            let newList = props.map(prop => prop.ref === p.ref ? p : prop)
            setProps(newList)
        } else {
            props.push(p)
            setProps(props)
        }
    }
    
    return <PropsData.Provider value={{props, setProps, setOneProp}}>
        <Navigation.Provider value={{view, setView}}>
        <VisaValda.Provider value={{visaValda, setVisaValda}}>
            <div className='container-fluid vh-100'>
                <div className='row h-100'>
                    <MainNav />

                    <ContentView id='mainContainer' className='col-12 overflow-auto p-0'>
                        {view?.comp == 'karta' && <Karta ref={kartaRef} />}
                        {view?.comp == 'overblick' && <Overblick />}
                        {view?.comp == 'risker' && <Risker ref={riskerRef} />}
                    </ContentView>
                </div>
            </div>
        </VisaValda.Provider>
        </Navigation.Provider>
    </PropsData.Provider>
}