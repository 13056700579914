import Cookies from 'js-cookie';

export async function apiFetch(url, options={}) {
    const errorFallback = {
        error: 'Unkown error.',
        message: 'Oops! Något gick fel. Ring 08-33 99 44 så hjälper vi dig vidare.'
    }

    try {
        const response = await fetch(url, {
            ...options,
            headers: {
                'X-CSRFToken': Cookies.get('csrftoken'), 
                ...(options.headers || {'Content-Type': 'application/json'}),
            },
        });

        const contentType = response.headers.get('Content-Type')
        let payload = {}

        if (contentType && contentType.includes('application/json')) {
            try {
                payload = await response.json()
            } catch (e) {
                console.error("Error parsing JSON: ", e)
            }
        } else {
            try {
                payload = await response.text()
            } catch (e) {
                console.error("Error parsing TEXT: ", e)
            }
        }
        
        if (!response.ok) {
            return {
                ok: response.ok,
                status: response.status,
                error: payload.error || errorFallback.error,
                message: payload.message || errorFallback.message
            }
        }
  
        return {
            ok: response.ok,
            status: response.status,
            data: payload,
        }
    } catch (error) {
        console.error('Network error: ', error)
        return {
            ok: false,
            status: 503,
            error: 'Network error',
            message: errorFallback.message
        };
    }
}