import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Step1 from '../../components/AdFlow/Step1';
import Step2 from '../../components/AdFlow/Step2';
import Step3 from '../../components/AdFlow/Step3';
import Cookies from 'js-cookie';
import VBLoadingScreen from '@virkesborsen/vb-js-commons/lib/components/vbLoadingScreen';
import Alert from 'react-bootstrap/Alert';
import VBStepper from '@virkesborsen/vb-js-commons/lib/components/new/vbStepper';

class AuctionsCreatePage extends React.Component {
  constructor(props) {
    super(props);
    let currentUser = Window.user_data.loggedInUserID;
    let adIdCookie = `ad_id_${currentUser}`;
    let ad_id = Cookies.get(adIdCookie)

    this.state = {
      step: 1,
      ad_id: ad_id,
      adData: null,
      changeQueue: [],
      currentRequest: null,
      loading:false,
      draftSaved: false,
      textChanged: '',
      errorModal: false,
      errorModalText: ''
    }

    this.mapHandler = React.createRef();
    this.handleBack = this.handleBack.bind(this);
  }

  handleServerError(response_status, text500, text400) {
    if (response_status >= 500) {
      this.setState({errorModal: true, errorModalText: text500})
    } else if (response_status >= 400) {
      this.setState({errorModal: true, errorModalText: text400})
    }
  }

  redirectAfterServerError = () => {
    window.location.href = '/'
  }

  componentDidMount() {
    let headers = {
      'Content-Type': 'application/json',
      'X-CSRFToken': Cookies.get('csrftoken')
    };
    fetch("/auctions-api/auctions/" + this.state.ad_id.toString() + "/", {
      method: 'GET',
      credentials: 'same-origin',
      headers: headers
    })
    .then((resp) => {
      if (resp.ok) {
        return resp.json()
      } else {
        this.handleServerError(resp.status, 'Försök igen senare eller kontakta Kundservice.',  'Den här funktionen är inte tillgänglig.');
      }
    })
    .then(json => {
       this.setState({
      adData: { ...{ adLayers: [], markers: [] }, ...json } 
       });

    })
  }

  getAdFiles(){
    let headers = {
      'Content-Type': 'application/json',
      'X-CSRFToken': Cookies.get('csrftoken')
    };
    fetch("/auctions-api/auctions/" + this.state.ad_id.toString() + "/", {
      method: 'GET',
      credentials: 'same-origin',
      headers: headers
    })
    .then((resp) => {
      if (resp.ok) {
        return resp.json()
      } else {
        this.handleServerError(resp.status, 'Försök igen senare eller kontakta Kundservice.',  'Den här funktionen är inte tillgänglig.');
      }
    })
    .then(json => {

       this.setState({
      // adData: {...{attachments:json.attachments}, ...this.state.adData }
      adData: { ...this.state.adData, attachments: json.attachments }
       });

    })
  }

  updateAdData(changed) {
    
      // Create a copy of changed to allow for delete of adLayers without affecting the bigger context
      let data = { ...changed };
      if (changed.hasOwnProperty("adLayers")) {
        data["custom_area"] = this.adLayersToGeoJson(data["adLayers"]);
        let sections = [];
        data["adLayers"].forEach((adLayer) => {
          if(adLayer.data){
            const tgl = {
              "Tall": adLayer.data.tall / 100,
              "Gran": adLayer.data.gran / 100,
              "default": adLayer.data.lov / 100,
            };
        
            if (adLayer.data.al !== null && adLayer.data.al !== '' && !isNaN(adLayer.data.al)) tgl["Al"] = adLayer.data.al / 100;
            if (adLayer.data.asp !== null && adLayer.data.asp !== '' && !isNaN(adLayer.data.asp)) tgl["Asp"] = adLayer.data.asp / 100;
            if (adLayer.data.björk !== null && adLayer.data.björk !== '' && !isNaN(adLayer.data.björk)) tgl["Björk"] = adLayer.data.björk / 100;
            if (adLayer.data.bok !== null && adLayer.data.bok !== '' && !isNaN(adLayer.data.bok)) tgl["Bok"] = adLayer.data.bok / 100;
            if (adLayer.data.ek !== null && adLayer.data.ek !== '' && !isNaN(adLayer.data.ek)) tgl["Ek"] = adLayer.data.ek / 100;

        
            sections.push({
              "Name": (adLayer.layer._leaflet_id).toString(),
              "totalVolume": this.state.adData.arrangement == "gallring" ? Math.trunc(adLayer.data.volume * 0.3) : Math.trunc(adLayer.data.volume),
              "Hectares": adLayer.data.area.toFixed(2),
              "HabitatIndex": this.state.adData.habitat_index,
              "TGL": tgl
            });
          }
        })
        let forestWithSections = {
          "sections": sections
        };
        data["forestWithSections"] = forestWithSections;
        delete data["adLayers"];
      }
  
      let headers = {
        'Content-Type': 'application/json',
        'X-CSRFToken': Cookies.get('csrftoken')
      };
  
      let req = fetch("/auctions-api/auctions/" + this.state.ad_id.toString() + "/", {
        method: 'PATCH',
        credentials: 'same-origin',
        body: JSON.stringify(data),
        headers: headers
      }).then((resp) => {
        if (resp.ok) {
          return resp.json()
        } else {
          this.handleServerError(resp.status, 'Annonsen är inte tillgänglig.',  'Den här funktionen är inte tillgänglig.');
        }
      }).then(json => {
        this.setState(prevState => ({
          adData: { ...prevState.adData, ...json},
          currentRequest: null
        }), () => {
          const isStep1Valid = this.isStep1Valid();
          if(this.state.changeQueue.length > 0){
            let next_changed = this.state.changeQueue.pop()
            this.updateAdData(next_changed)
          }
        })
      });
  
      this.setState({currentRequest: req});
  
  }
  
  isStep1Valid() {
   
    const isValid =
      this.state.adData &&
      this.state.adData.type !== null &&
      this.state.adData.geoCollection !== null && this.state.adData.geoCollection.geometries.length !== 0 &&
      this.state.adData.start_time !== null &&
      this.state.adData.end_time !== null &&
      this.state.adData.felling_start_time !== null &&
      this.state.adData.felling_end_time !== null &&
      (this.state.adData.arrangement !== "gallring"?
      this.state.adData.felling_application_responsible !== null:true);
  
    return isValid;
  }

  isStep2Valid(){
    const isValid= this.state.adData && this.state.adData.forestWithSections &&
    this.state.adData.forestWithSections.sections &&
    this.state.adData.forestWithSections.sections.length !== 0;

    return isValid;
  }
  

  adLayersToGeoJson(adLayers) {
    let group = new L.FeatureGroup();
    adLayers.forEach((itm, i) => {
      itm.layer.addTo(group)
    })

    return group.toGeoJSON()
  }

  changeAdData(changed,callback=null) {
    if (Object.keys(changed).includes('comments')) {
      this.setState({textChanged: changed.comments})
    } else {
      if (this.state.currentRequest) {
        this.state.changeQueue.push(changed)
      } else{
        this.updateAdData(changed)
      }
      this.setState({ adData: { ...this.state.adData, ...changed } , changeQueue: this.state.changeQueue},()=>{
        if(callback){
          callback()
        }
      })
    }
    
  }

  handleFinish = () => {
    this.setState({loading:true})
    this.updateAdData({comments: this.state.textChanged, state:20})
    setTimeout(() => {
      // Cookies.remove('ad_id')
     let intervalId= setInterval(() => {
        if (this.state.changeQueue.length === 0 && this.state.currentRequest == null) {

          window.location.href = "/auctions/" + this.state.adData.id + "/review";
          clearInterval(intervalId)
        }
      }, 500)
    }, 1500)
    
  }



  componentDidUpdate(prevState) {
    if(prevState.adData !== this.state.adData) {
      this.isStep1Valid
    }
  }

  handleButtonClick = (e) => {
    e.preventDefault()
    const { step } = this.state;

     if(step === 1) {
      if(this.isStep1Valid())   {
        this.setState((prevState) => ({
          step: prevState.step + 1,
          
        }))
      } else {
        alert('Vänligen fyll i alla fält.')
      }
      } else if (step === 2) {
          this.setState({
            step: 3,
          })
      }
  };

  handleBack = () => {
    this.setState((prevState) => ({
      step: prevState.step - 1,
    }));
  };

  saveDraft = () => {
    this.changeAdData({visible_draft: true});
    this.setState({draftSaved: true});

    setTimeout(() => {
      this.setState({draftSaved: false});
    }, 5000);
  };

  render() {
    const { step, adData, draftSaved } = this.state;

    const isStep1Complete = this.isStep1Valid();
    const isStep2Valid = this.isStep2Valid();
    const isStep3Valid = true;


    const isNextButtonDisabled = 
      (step === 1 && (!adData || !isStep1Complete)) ||
      (step === 2 && !isStep2Valid) ||
      (step === 3 && !isStep3Valid)

    return (
      <div className="container-fluid n-fs-6 p-0">
        <VBLoadingScreen
          show_loading={this.state.loading}
          disable_reload_timeout={true}
          loading_text={null} />
        <div className="bg-white">
        </div>

        <section className='pt-0 pt-md-0 pb-0'>

          <div className="container bg-white pt-3 pb-3">
          {/* stepper here */}
          <div className='my-4'>
          <VBStepper currentStep={step}/>
          </div>

            {step === 1 && this.state.adData ? (
              <>
                <Step1
                  changeAdData={this.changeAdData.bind(this)}
                  handleServerError={this.handleServerError.bind(this)}
                  adData={this.state.adData}
                />
                <div className='d-flex justify-content-end mt-4 col-12'>
                <div className='col-md-4 col-lg-2 col-6 me-2 mb-2 mb-lg-0'>
                  <button
                    className='n-btn btn-ghost btn-charcoal m-1 w-100'
                    onClick={this.saveDraft}
                  >
                    Spara utkast
                  </button>
                  </div>
                  <div className='col-md-4 col-lg-2 col-6'>
                <button
                  className={`n-btn btn-forest-green m-1 w-100 ${isStep1Complete ? '' : 'disabled'}`}
                  onClick={this.handleButtonClick}
                  disabled={!isStep1Complete}
                  style={{ color: isStep1Complete ? '#FFFFFF' : '#9d9fa1', backgroundColor: isStep1Complete ? '#023436' : '#F5F5F5'}}
                >
                  Nästa
                </button>
                </div>
                </div>
              </>
            ) : step === 2 ? (
              <>
              <Step2
                changeAdData={this.changeAdData.bind(this)}
                adData={this.state.adData}
              />

              <div className='d-flex flex-wrap justify-content-end mt-4 col-12'>
              <div className='col-md-4 col-lg-2 col-5 me-auto mb-2'>
              <button
                className='n-btn btn-outline btn-charcoal w-100'
                onClick={this.handleBack}
              >
                Föregående
              </button>
              </div>
              <div className='col-md-4 col-lg-2 col-6'>
              <button
                className='n-btn btn-ghost btn-charcoal w-100'
                onClick={this.saveDraft}
              >
                Spara utkast
              </button>
              </div>
              <div className='ms-2 col-md-4 col-lg-2 col-12'>
              <button
                className={`n-btn btn-forest-green m-1 w-100 ${isStep2Valid ? '' : 'disabled'}`}
                onClick={this.handleButtonClick}
                disabled={!isStep2Valid}
                style={{ color: isStep2Valid ? '#FFFFFF' : '#9d9fa1', backgroundColor: isStep2Valid ? '#023436' : '#F5F5F5'}}
              >
                Nästa
              </button>
              </div>
              </div>
            
            </>
            ) : step === 3 ? (
              <>
              <Step3
                changeAdData={this.changeAdData.bind(this)}
                adData={this.state.adData}
                updateFiles={() => {this.getAdFiles()}}
              />
              <div className='d-flex flex-wrap justify-content-end mt-4 col-12'>
              <div className='ol-md-4 col-lg-2 col-5 me-auto mb-2'>
              <button
                className='n-btn btn-outline btn-charcoal'
                onClick={this.handleBack}>
                Föregående
              </button>
              </div>
              <div className='col-md-4 col-lg-2 col-6'>
              <button
                className='n-btn btn-ghost btn-charcoal w-100'
                onClick={this.saveDraft}>
                Spara utkast
              </button>
              </div>
              <div className='ms-2 col-md-4 col-lg-2 col-12'>
              <button
                className='n-btn btn-forest-green w-100'
                onClick={this.handleFinish}>
                Slutför
              </button>
              </div>
            </div>
              </>
            ) : null}
          </div>

        </section>
        
        <div className='' style={{ width: '90%', position: 'fixed', top: '20px', left: '50%', transform: 'translateX(-50%)', zIndex: '1000' }}>
          {draftSaved && (
            <Alert variant="success" onClose={() => this.setState({draftSaved: false})} dismissible>
              <Alert.Heading>Ditt utkast har sparats!</Alert.Heading>
            </Alert>
          )}
        </div>
        <Modal show={this.state.errorModal} onHide={() => this.setState({ errorModal: false }, this.redirectAfterServerError())} contentClassName="bg-neutral-cloud-200 p-2" centered>
                    <Modal.Header className="align-items-start border-0"  closeButton>
                        <h3 className="neutral-charcoal mb-0">Oops! Något gick fel.</h3>
                    </Modal.Header>
                    <Modal.Body>
                        <p className='neutral-charcoal'>{this.state.errorModalText}</p>
                        <p className='neutral-charcoal'>Ring <a href="tel:08339944" className="fw-bold neutral-charcoal">08-33 99 44</a> så hjälper vi dig vidare.</p>
                        <button type="button" className="n-btn float-end" style={{background:"#FF5B6C", border:"None"}} onClick={() => this.setState({ errorModal: false }, this.redirectAfterServerError())}>FORTSÄTT</button>
                    </Modal.Body>
                </Modal>
      </div>
    )
  }
}

export default AuctionsCreatePage;
