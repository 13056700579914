import React from 'react';
import Cookies from 'js-cookie';

import VBInput from "@virkesborsen/vb-js-commons/lib/components/new/vbInput";
import VBPhoneInput from '@virkesborsen/vb-js-commons/lib/components/new/vbPhoneInput';
import VBDropdown from "@virkesborsen/vb-js-commons/lib/components/new/vbDropdown";
import VBCheckbox from '@virkesborsen/vb-js-commons/lib/components/new/vbCheckbox';
import { ErrorModal } from '../modals';

import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';

import { validateForm, userExists, createHubspotTicket, leadsRegisterUser } from '../api';

class LeadGeneration extends React.Component {
    constructor() {
        super();
        this.state = {
            isLogged: Window.user_data.isLogged,
            data: {arrangement: 'slutavverkning'},
            info: {},
            infoData: {show: false},
            errors: {},
            showModal: false,
            errorModal: false
        }
    }

    action() {
        if(this.props.mode == 'price') {
            this.setState({infoData: {...this.state.infoData, show: true}})
            this.fetchInfoData()
        } else {
            this.setState({hideForm: true})
            this.handleContactMe()
        }
    }

    setInfoData(setErrors) {
        let prms_email = userExists(`email=${encodeURIComponent(this.state.data.email)}`).then(resp => resp.json())
        let prms_phone = userExists(`mobile_phone_number=${encodeURIComponent(this.state.data.mobile_phone_number)}`).then(resp => resp.json())

        let isReg = false;
        Promise.all([prms_email, prms_phone]).then((jsons) => {
            isReg = jsons.reduce((acum, curr) => acum ? acum : curr.exists, false);

            let validation = validateForm(this.state.data, ['muni', 'email', 'mobile_phone_number', 'checkbox'], isReg)

            if(this.state.isLogged || this.state.skipRegister) {
                if(!validation.muni) {
                    this.action()
                } else {
                    this.setState({ errors: {...this.state.errors, muni: 'Du måste välja en kommun' }})
                }
            } else {
                if(Object.keys(validation).length !== 0) {
                    if(setErrors) {
                        mixpanel.track("registration_attempt_fe", {form: 'lead_generation_form', errors: validation})
                        this.setState({ errors: {...this.state.errors, ...validation }})
                    }
                } else {
                    if(!isReg) {
                        this.setState({loadingReg: true})

                        leadsRegisterUser({
                            email: this.state.data.email,
                            muni: [this.state.data.muni],
                            mobile_phone_number: this.state.data.mobile_phone_number,
                            trackingContext: 'leads',
                        }).then(response => {
                            switch (response.status) {
                                case 'SUCCESS':
                                    this.setState({isLogged: true});
                                    document.getElementsByTagName('body')[0].classList.toggle("nav-update");
                                    this.action();
                                    break;
                                case 'PHONE_EXISTS':
                                    this.action();
                                    this.setState({skipRegister: true});
                                    break;
                                case 'VALIDATION_ERROR':
                                    this.setState({ errors: response.error });
                                    break;
                                case 'ERROR':
                                default:
                                    this.setState({ errorModal: true });
                                    break;
                            }
                            this.setState({loadingReg: false});
                        });
                    } else {
                        this.action()
                    }
                }
            }
        })
    }

    fetchPriceData = async () => {
        const headers = {
            'Content-Type': 'application/json',
            'X-CSRFToken': Cookies.get('csrftoken')
        }

        const prices = [
            fetch(`/price-api/avg-price?municipality=${this.state.data.muni}&tree=Tall`, {method: 'GET', credentials: 'same-origin', headers: headers}).then(resp => resp.json()),
            fetch(`/price-api/avg-price?municipality=${this.state.data.muni}&tree=Gran`, {method: 'GET', credentials: 'same-origin', headers: headers}).then(resp => resp.json())
        ]
  
        const [tall, gran] = await Promise.all(prices)

        return Math.round((tall.timber_medium + gran.timber_medium) / 2)
    }

    fetchInfoData = () => {
        const headers = {
            'Content-Type': 'application/json',
            'X-CSRFToken': Cookies.get('csrftoken')
        }

        fetch(`/salja-virke-api/buyers/?municipality=${this.state.data.muni}`, {
            method: 'GET',
            credentials: 'same-origin',
            headers: headers
        }).then(resp => resp.json()).then(data => this.setState({infoData: {...this.state.infoData, buyers: data.count}}));

        fetch(`/salja-virke-api/percentage/?municipality=${this.state.data.muni}&arrangement=${this.state.data.arrangement}`, {
            method: 'GET',
            credentials: 'same-origin',
            headers: headers
        }).then(resp => resp.json()).then(data => {this.setState({infoData: {...this.state.infoData, procent: data.percentage}})})

        this.fetchPriceData().then(price => this.setState({infoData: {...this.state.infoData, price: price}}))
    }

    handleContactMe() {
        mixpanel.track('leadform_becontacted_fe');
        createHubspotTicket({arrangement: this.state.data.arrangement, email: this.state.data.email, mobile_phone_number: this.state.data.mobile_phone_number}).then(resp => {
            if(resp.ok) {
                resp.json().then(() => {
                    this.setState({requestedContact: true})
                })
            } else {
                this.setState({errorModal: true})
            }
        })
    }

    handleAdCreate() {
        mixpanel.track('leadform_createad_fe');
        if(this.state.isLogged) {
            if(this.state.data.arrangement == 'grot') {
                window.location = '/biobransle/'
            } else {
                window.location = '/auctions/create/?ad_arrangement='+this.state.data.arrangement
            }
        } else {
            if(this.state.data.arrangement == 'grot') {
                window.location = '/logga-in/?next=/biobransle/?email='+encodeURIComponent(this.state.data.email)+'&email='+encodeURIComponent(this.state.data.email)
            } else {
                window.location = '/logga-in/?next=/auctions/create/?ad_arrangement='+this.state.data.arrangement+'&email='+encodeURIComponent(this.state.data.email)
            }
        }
    }

    handleSBP() {
        mixpanel.track('leadform_createsbp_fe');
        if(this.state.isLogged) {
            window.location = '/min-skog/'
        } else {
            window.location = '/logga-in/?next=/min-skog/&email='+encodeURIComponent(this.state.data.email)
        }
    }

    loadForm() {
        return <>
            <div className='d-flex mb-3'>
                <div className='bg-supp-forest-green rounded-3 p-2 me-2' style={{height: '48px'}}>
                    {this.props.mode == 'price' ? <img src={`${Window.static_path}img/icons/saving-pig.svg`} className='primary-core-filter' width="25px" height="25px"  /> : <img src={`${Window.static_path}img/icons/headset.svg`} className='primary-core-filter' width="25px" height="25px"  />}
                </div>

                {this.props.mode == 'price' ? <div>
                    <p className='fw-bold mb-0'>{this.props.title ? this.props.title : 'Se ditt pris'}</p>
                    <p className='neutral-pebble subtitle mb-0'>{this.props.caption ? this.props.caption : 'Kostnadsfritt och enkelt.'}</p>
                </div> : <div>
                    <p className='fw-bold mb-0'>{this.props.title ? this.props.title : 'Rådgivare'}</p>
                    <p className='neutral-pebble subtitle mb-0'>{this.props.caption ? this.props.caption : 'Din expert för vägledning och support.'}</p>
                </div>}
            </div>

            {!this.state.hideForm && <>
                <p className='mb-1'>Välj åtgärd</p>
                <div className='d-flex flex-wrap'>
                    {Window.react_data.lists.arrangements.filter(i => i.value !== 'vindfallen').map(arrangement => (
                        <div type='button' className={`${this.state.data.arrangement == arrangement.value ? 'bg-supp-forest-green white' : 'bg-neutral-cloud-200'} rounded-2 px-2 py-1 mb-1 me-2`} onClick={() => this.setState({ data: {...this.state.data, arrangement: arrangement.value }})}>
                            <span className='text-nowrap'>{arrangement.label}</span>
                        </div>
                    ))}
                </div>
                
                <VBDropdown
                    inputStyle={{minHeight: 0}} 
                    inputClass='border-neutral-cloud'
                    label={<label className="subtitle mt-3">Kommun:</label>}
                    initial={this.state.data?.muni}
                    error={this.state.errors.muni}
                    onFocus={() => this.setState({ errors: { ...this.state.errors, muni: '' } })}
                    options={Window.react_data.lists.munis}
                    onChange={(val) => {
                        !this.state.startedForm && mixpanel.track('leadform_started_fe');
                        this.setState({ startedForm: true, infoData: {show: this.state.infoData.show}, data: {...this.state.data, muni: parseInt(val.value) }}, () => this.props.mode == 'price' && this.setInfoData(false))
                        }} />

                {!this.state.isLogged && this.state.data.muni && !this.state.infoData.show && <>
                    <p className='subtitle mb-1 mt-3'>E-postadress* <img type='button' src={`${this.state.info.email ? `${Window.static_path}img/icons/info-filled.svg` : `${Window.static_path}img/icons/info-open.svg`}`} width="20px" height="20px" onClick={() => this.setState({info: {...this.state.info, email: !this.state.info.email }})} /></p>
                    {this.state.info.email && <p className='neutral-pebble subtitle mb-1'>För att vi ska kunna hålla dig uppdaterad om hur värdet förändras över tid behöver du skapa ett konto.</p>}
                    <VBInput
                        inputStyle={{minHeight: 0}}
                        inputClass='border-neutral-cloud'
                        type="email"
                        placeholder="namn@mail.se"
                        error={this.state.errors.email}
                        onFocus={() => this.setState({ errors: { ...this.state.errors, email: '' } })}
                        onChange={(val) => this.setState({ data: { ...this.state.data, email: val } })} />

                    <p className='subtitle mb-1 mt-3'>Mobilnummer* <img type='button' src={`${this.state.info.mobile_phone_number ? `${Window.static_path}img/icons/info-filled.svg` : `${Window.static_path}img/icons/info-open.svg`}`} width="20px" height="20px" onClick={() => this.setState({info: {...this.state.info, mobile_phone_number: !this.state.info.mobile_phone_number }})} /></p>
                    {this.state.info.mobile_phone_number && <p className='neutral-pebble subtitle mb-1'>Vi kan komma att kontakta dig vid behov av ytterligare information eller rådgivning.</p>}
                    <VBPhoneInput
                        inputStyle={{minHeight: 0}} 
                        inputClass='border-neutral-cloud'
                        placeholder="07X XXX XX XX"
                        error={this.state.errors.mobile_phone_number}
                        onFocus={() => this.setState({ errors: { ...this.state.errors, mobile_phone_number: '' } })}
                        onChange={(val) => this.setState({ data: { ...this.state.data, mobile_phone_number: val } })} />

                    <div className='d-flex flex-column mt-3'>
                        {this.state.errors?.checkbox &&
                            <p className='text-danger n-fs-7 mb-1'>{this.state.errors.checkbox}</p>
                        }
                        <div className='d-flex align-items-center'>
                            <VBCheckbox
                                label=""
                                onClick={() => { this.setState({ data: { ...this.state.data, checkbox: !this.state.data.checkbox }, errors: { ...this.state.errors, checkbox: '' } }) }} />

                            <p className='mb-0 n-fs-7'>Genom att klicka på någon av knapparna ovan godkänner du våra <a href="/terms/" className='text-decoration-underline'>användarvillkor</a> och <a href="/privacy/" className='text-decoration-underline'>integritetspolicy.</a> Jag förstår att jag skapar ett konto för att fortsätta.</p>
                        </div>
                    </div>
                </>}
            </>}

            {this.props.mode == 'price' && <>
                <p className='mt-2 mb-1'>Priser i din kommun</p>
                {this.state.infoData.show ? <>
                    <div className='bg-supp-forest-green white rounded-3 p-3'>
                        <p>Ditt pris <img className='white-filter' src={`${Window.static_path}img/icons/info-open.svg`} width="20px" height="20px" /></p>
                        <div className='d-flex justify-content-between caption w-100'>
                            <div>
                                <p className='mb-2'>Upp till</p>
                                <p className='mb-1'><span className='fw-bold primary-core fs-5'>{this.state.infoData.buyers ? this.state.infoData.buyers : <Spinner size="sm" animation="border" />}</span> st</p>
                                <p>Köpare</p>
                            </div>

                            <div>
                                <p className='mb-2'>Upp till</p>
                                <p className='mb-1'><span className='fw-bold primary-core fs-5'>{this.state.infoData.price ? this.state.infoData.price : <Spinner size="sm" animation="border" />}</span> kr/m3fub</p>
                                <p>Grundpris</p>
                            </div>

                            <div>
                                <p className='mb-2'>Upp till</p>
                                <p className='mb-1'><span className='fw-bold primary-core fs-5'>{this.state.infoData.procent ? this.state.infoData.procent : <Spinner size="sm" animation="border" />}</span> %</p>
                                <p>Högre priser</p>
                            </div>
                        </div>
                    </div>
                </> : <>
                    <div className='d-flex bg-neutral-cloud-200 rounded-3 p-2'>
                        <div className='me-1'>
                            <img src={`${Window.static_path}img/icons/info-filled.svg`} className='neutral-pebble-filter' height='25px' width='25px' />
                        </div>
                        <div className='subtitle mt-1'>När du har fyllt i dina uppgifter ovan så ser du ditt resultat här.</div>
                    </div>
                </>}
            </>}

            {this.state.infoData.show ? <>
                <div className='bg-neutral-cloud-200 rounded-3 p-2 mt-2'>
                    <p className='mb-1'>Nästa steg</p>
                    <p className='neutral-pebble caption mb-2'>Det är många som letar efter virke just nu, skapa en kostnadsfri annons utav din skog, utan förbindelser.</p>

                    {(this.props.showContactButton && !this.state.requestedContact) && <button className='d-flex justify-content-between n-btn btn-forest-green rounded-3 w-100 mb-2' onClick={() => this.handleContactMe()}>Bli kontaktad av rådgivare <img src={`${Window.static_path}img/icons/arrow.svg`} className='white-filter' height="20px" width="20px" /></button>}

                    {this.state.requestedContact && <div className='bg-primary-core rounded-3 subtitle text-center p-2 mb-2'>Tack! Vi återkommer till dig senast nästa arbetsdag.</div>}

                    {this.props.showCreateAdButton && <button className='d-flex justify-content-between n-btn btn-forest-green rounded-3 w-100 mb-2' onClick={() => this.handleAdCreate()}>Skapa annons <img src={`${Window.static_path}img/icons/arrow.svg`} className='white-filter' height="20px" width="20px" /></button>}

                    {this.props.showSBPButton && <button className='d-flex justify-content-between n-btn btn-forest-green rounded-3 w-100' onClick={() => this.handleSBP()}>Skapa digital skogsbruksplan <img src={`${Window.static_path}img/icons/arrow.svg`} className='white-filter' height="20px" width="20px" /></button>}
                </div>
            </> : <>
                {this.props.mode == 'price' ? <>
                    <button className='n-btn btn-forest-green rounded-3 w-100 mt-3 mb-2' onClick={() => !this.state.loadingReg && this.setInfoData(true)}>Se ditt pris {this.state.loadingReg && <Spinner size="sm" animation="border" />}</button>
                </> : 
                this.state.requestedContact ? <>
                    <div className='bg-primary-core rounded-3 subtitle text-center p-2 mb-2 mt-3'>Tack! Vi återkommer till dig senast nästa arbetsdag.</div>

                    {this.props.showCreateAdButton && <button className='d-flex justify-content-between n-btn btn-forest-green rounded-3 w-100 mb-2' onClick={() => this.handleAdCreate()}>Skapa annons <img src={`${Window.static_path}img/icons/arrow.svg`} className='white-filter' height="20px" width="20px" /></button>}

                    {this.props.showSBPButton && <button className='d-flex justify-content-between n-btn btn-forest-green rounded-3 w-100' onClick={() => this.handleSBP()}>Skapa digital skogsbruksplan <img src={`${Window.static_path}img/icons/arrow.svg`} className='white-filter' height="20px" width="20px" /></button>}
                </> : <>
                    <button className='d-flex justify-content-between n-btn btn-forest-green rounded-3 w-100 mb-2 mt-3' onClick={() => !this.state.loadingReg && this.setInfoData(true)}>Bli kontaktad av rådgivare {this.state.loadingReg ? <Spinner size="sm" animation="border" /> : <img src={`${Window.static_path}img/icons/arrow.svg`} className='white-filter' height="20px" width="20px" />}</button>
                </>}
            </>}
        </>
    }

    render() {
        return <div className='w-100'>
            <div className='d-md-none'>
                <button className='n-btn btn-core rounded-3 w-100' onClick={() => this.setState({showModal: true})}>{this.props.mode == 'price' ? 'Se ditt pris' : 'Bli kontaktad av rådgivare'}</button>
                <p className='text-center caption white w-100 mt-2'>{this.props.mode == 'price' ? 'Kostnadsfritt & utan förbindelse.' : 'Din expert för vägledning och support.'}</p>
            </div>
            
            <div className='d-none d-md-block'>
                <div className='bg-white rounded-4 p-4'>{this.loadForm()}</div>
            </div>

            {/* INFO Modal */}
            <Modal show={this.state.showModal} onHide={() => this.setState({showModal: false})} size="lg" dialogClassName='m-0' contentClassName="p-2" centered>
                <div className='sticky-top'>
                    <div role='button' className='bg-neutral-cloud-200 neutral-pebble rounded-4 subtitle float-end me-2 mt-2 px-2 py-1' onClick={() => this.setState({showModal: false})}>Stäng <img src={`${Window.static_path}img/icons/cross.svg`} className='neutral-pebble-filter' heigth='15px' width='15px' /></div>
                </div>
                
                <Modal.Body className="px-2">
                    {this.loadForm()}
                </Modal.Body>
            </Modal>

            {/* ERROR Modal */}
            <ErrorModal show={this.state.errorModal} onToggle={() => this.setState({errorModal: !this.state.errorModal})} />
        </div>
    }
}

LeadGeneration.defaultProps = {
    mode: 'price',
    showContactButton: true,
    showCreateAdButton: true,
    showSBPButton: true
}

LeadGeneration.fields = {
    mode: {
        type: "select",
        options: [{ label: "With price info", value: 'price' }, { label: "Request for contact", value: 'request_contact' }]
    },
    title: {type: "text"},
    caption: {type: "text"},
    showContactButton: {
        type: "radio",
        options: [{ label: "Yes", value: true }, { label: "No", value: false }]
    },
    showCreateAdButton: {
        type: "radio",
        options: [{ label: "Yes", value: true }, { label: "No", value: false }]
    },
    showSBPButton: {
        type: "radio",
        options: [{ label: "Yes", value: true }, { label: "No", value: false }]
    }
}

export default LeadGeneration;